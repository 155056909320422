import React, { useState, useEffect, useRef } from "react";
import uuid from "react-uuid";
import Header from "../../Component/NewHeader/NewHeader";
import "./PaymentDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCaretDown,
  faCheck,
  faCut,
  faEuroSign,
  faLessThan,
} from "@fortawesome/free-solid-svg-icons";
import HotelImage1 from "../../assets/images/hotel/LeRoseVilla.jpg";
import {
  HotelBookingRules,
  HotelConfirmBooking,
  LoginConfirmation,
  CybersourecPaymentGetway,
  CyberSource
} from "../axios";
import PaymentDetailsSkeleton from "./PaymentDetailsSkeleton";
import moment from "moment";
import DialCode from "./DialCode";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Timer from "./Timer";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";

import { setCustomerLogin, getGeoInfo, getCustomerLogin } from "../axios";
import Modal from "react-modal";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import SignUp from "../SignUp";
import {Helmet} from "react-helmet";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
function PaymentDetails() {
  const [cookies, setCookie] = useCookies(["userDetails"]);

  const [drop, toggledrop] = useState({
    class: "HotelInfo",
    formClass: "form-guest",
    buisnessFormClass: "buisnessShort",
    requestClass: "all-requests",
  });
  const [HotelImage, setHotelImage] = useState();
  const [Payment, setPayment] = useState(null);

  const [GuestCount, setGuestCount] = useState([]);
  const [StartDate, setStartDate] = useState(null);
  const [Enddate, setEnddate] = useState(null);
  const [NoNight, setNoNight] = useState();
  const [Guest, setGuest] = useState([
    {
      title: "Mr",
      FirstName: "",
      LastName: "",
      FirstNameError: false,
      LastNameError: false,
    },
  ]);
  const [EmailId, setEmailId] = useState();
  const [MobileNumber, setMobileNumber] = useState("");
  const [MobileCode, setMobileCode] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [LoginPage, setLoginPage] = useState(false);
  const [customerDetails, setcustomerDetails] = useState(
    localStorage.getItem("userDetails") || "abc"
  );
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [facebookLogin, setfacebookLogin] = useState(false);
  const [facebookResponse, setfacebookResponse] = useState({});
  const [GoogleResponse, setGoogleResponse] = useState({});
  const [country, setcountry] = useState("");
  const [country_code, setcountry_code] = useState("");
  const [currency, setcurrency] = useState("");
  const [ip_address, setip_address] = useState("");
  const [ChangeLoginScreenClass, setChangeLoginScreenClass] = useState(
    "containerLogin"
  );
  const [openModal, setopenModal] = useState(false);
  const [openModalHS, setopenModalHS] = useState(false);
  const [LoginEmail, setLoginEmail] = useState("");
  const [LoginPassword, setLoginPassword] = useState("");
  const [errorPassword, seterrorPassword] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [MobileError, setMobileError] = useState(false);
  const [TransactionUUID, setTransactionUUID] = useState(uuid());

  const [DisablePayNowButton, setDisablePayNowButton] = useState(false);

  let openModalDiv = () => {
    setopenModal(true);
  };

  let closeModalDiv = () => {
    setopenModal(false);
  };

  let openModalDivHS = () => {
    setopenModalHS(true);
  };

  let closeModalDivHS = () => {
    setopenModalHS(false);
  };

  let requestFormHandler = () => {
    if (drop.requestClass === "all-requests") {
      toggledrop({
        ...drop,
        requestClass: "all-requests-short",
      });
    } else {
      toggledrop({
        ...drop,
        requestClass: "all-requests",
      });
    }
  };

  let buisnessFormHandler = () => {
    if (drop.buisnessFormClass === "buisness") {
      toggledrop({
        ...drop,
        buisnessFormClass: "buisnessShort",
      });
    } else {
      toggledrop({
        ...drop,
        buisnessFormClass: "buisness",
      });
    }
  };

  let closeModal = () => {
    setmodalIsOpen(false);
  };

  // let submit = () => {
  //   confirmAlert({
  //     title: "Booking Confirmation", // Title dialog
  //     message: "Please wait. we're processing your request", // Message dialog
  //     childrenElement: () => (
  //       <div style={{ padding: 15 }}>
  //         <img
  //           src="https://supplier.holidays-seychelles.com/public/images/prop.png"
  //           alt=""
  //           className="loaderImage"
  //         />
  //         <div className="progress">
  //           <div className="indeterminate"></div>
  //         </div>
  //       </div>
  //     ), // Custom UI or Component
  //     overlayClassName: "overlay-custom-class-name", // Custom overlay class name
  //   });
  // };

  let FormToggleHandler = () => {
    if (drop.formClass === "form-guest") {
      toggledrop({
        ...drop,
        formClass: "form-guest-short",
      });
    } else {
      toggledrop({
        ...drop,
        formClass: "form-guest",
      });
    }
  };

  let ChangeLoginEmail = (event) => {
    setLoginEmail(event.target.value);
  };

  let ChangeLoginPassword = (event) => {
    setLoginPassword(event.target.value);
  };

  let LoginConfirm = (e) => {
    e.preventDefault();
    seterrorPassword(false);
    let emailNew = LoginEmail;
    let email = "";
    if (emailNew.length > 0) {
      email = emailNew;
    } else {
      email = cookies.userDetails.email;
    }
    let password = LoginPassword;

    LoginConfirmation(email, password).then(
      (data) => {
        //console.log(data);
        if (data == "not_correct") {
          seterrorPassword(true);
        } else {
          setcustomerDetails(data);

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
          window.location.reload(true);
        }
      },
      (error) => { }
    );
  };

  let OnChangeTitle = (event, index) => {
    let cats = [...Guest];

    cats[index]["title"] = event.target.value;

    setGuest(cats);
  };

  let OnChangeFirstName = (event, index) => {
    let cats = [...Guest];

    // cats[index]["FirstName"] = event.target.value;

    // if (event.target.value.length >= 1) {
    //   cats[index]["FirstNameError"] = false;
    // } else {
    //   cats[index]["FirstNameError"] = true;
    // }
    cats[index]["FirstName"] = event.target.value;
    let firstregMatch = /^[a-zA-Z ]*$/.test(event.target.value);
    if (event.target.value.length >= 1 && firstregMatch) {
      cats[index]["FirstNameError"] = false;
    } else {
      cats[index]["FirstNameError"] = true;
    }

    setGuest(cats);
  };

  let OnChangeLastName = (event, index) => {
    let cats = [...Guest];

    // cats[index]["LastName"] = event.target.value;

    // if (event.target.value.length >= 1) {
    //   cats[index]["LastNameError"] = false;
    // } else {
    //   cats[index]["LastNameError"] = true;
    // }
    cats[index]["LastName"] = event.target.value;
    let lastregMatch = /^[a-zA-Z ]*$/.test(event.target.value);
    if (event.target.value.length >= 1 && lastregMatch) {
      cats[index]["LastNameError"] = false;
    } else {
      cats[index]["LastNameError"] = true;
    }

    setGuest(cats);
  };

  let OnChangeEmailId = (event) => {
    const email = validEmailRegex.test(event.target.value);
    if (email == true) {
      setEmailId(event.target.value);
      setEmailError(false);
    } else {
      setEmailId(event.target.value);
      setEmailError(true);
    }
  };
  let OnChangeMobileNumber = (event) => {
    const mobile = event.target.value;
    let mobileregMatch = /^[0-9]*$/.test(event.target.value);
    if (mobile.length > 0 && mobile.length < 15 && mobileregMatch) {
      setMobileNumber(event.target.value);
      setMobileError(false);
    } else {
      setMobileNumber(event.target.value);
      setMobileError(true);
    }
  };
  let OnChangeMobileCode = (event) => {
    //console.log(event);
    setMobileCode(event.target.value);
  };

  let addGuest = (e) => {
    setGuest((Guest) => [
      ...Guest,
      {
        title: "Mr",
        FirstName: "",
        LastName: "",
        FirstNameError: false,
        LastNameError: false,
      },
    ]);
  };

  let renderDropdownMenu = () => {
    return (
      <>
        {Guest.map((val, idx) => {
          const adultCounter = GuestCount.reduce(
            (a, v) => a + v.adultCounter * 1,
            0
          );
          const childCounter = GuestCount.reduce(
            (a, v) => a + v.childCounter * 1,
            0
          );
          const TotalGuest = adultCounter + childCounter;

          if (idx < TotalGuest) {
            return (
              <div className="paymakeFlex" key={idx}>
                <div className="guestDtls__col width70 appendRight10">
                  <p className="font11 capText appendBottom10">Title</p>
                  <div className="frmSelectCont">
                    <select
                      id="title"
                      value={Guest[idx].title}
                      className="frmSelect"
                      onChange={(e) => {
                        OnChangeTitle(e, idx);
                      }}
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                    </select>
                  </div>
                </div>
                <div className="paymakeFlex column flexOne">
                  <div className="paymakeFlex">
                    <div className="guestDtls__col width247 appendRight10">
                      <div className="textFieldCol ">
                        <p className="font11 appendBottom10 guestDtlsTextLbl">
                          <span className="capText">
                            FULL NAME AS PER PASSPORT
                          </span>
                        </p>
                        <input
                          type="text"
                          id="fName"
                          name="fName"
                          className={`frmTextInput ${Guest[idx].FirstNameError ? "Err" : ""
                            }`}
                          value={Guest[idx].FirstName}
                          onChange={(e) => OnChangeFirstName(e, idx)}
                          placeholder="First Name"
                        />
                        {Guest[idx].FirstNameError && (
                          <p className="error"> Please enter your First Name in Alphabet</p>
                        )}
                      </div>
                    </div>
                    <div className="guestDtls__col width247">
                      <div className="textFieldCol ">
                        <p className="font11 appendBottom10 guestDtlsTextLbl">
                          <span className="capText"></span>
                        </p>
                        <input
                          type="text"
                          id="lName"
                          name="lName"
                          className={`frmTextInput ${Guest[idx].LastNameError ? "Err" : ""
                            }`}
                          value={Guest[idx].LastName}
                          onChange={(e) => OnChangeLastName(e, idx)}
                          placeholder="Last Name"
                        />

                        {Guest[idx].LastNameError && (
                          <p className="error"> Please enter your Last Name in Alphabet</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };

  let HotelToggleHandler = () => {
    if (drop.class === "HotelInfo") {
      toggledrop({
        ...drop,
        class: "HotelInfoShort",
      });
    } else {
      toggledrop({
        ...drop,
        class: "HotelInfo",
      });
    }
  };

  let starsCount = (star_category_id) => {
    if (star_category_id == 1) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (star_category_id == 2) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (star_category_id == 3) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (star_category_id == 4) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (star_category_id == 5) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
        </>
      );
    }
  };

  let loginPage = (e) => {
    e.preventDefault();
    setmodalIsOpen(true);
  };

  let ChangeLoginScreen = () => {
    setChangeLoginScreenClass("containerLogin right-panel-active");
  };
  let ChangeLoginReverse = () => {
    setChangeLoginScreenClass("containerLogin");
  };

  let ConfirmBooking = (e) => {
    e.preventDefault();
    let CheckError = false;
    let CheckErrorEmail = false;
    let CheckErrorMobile = false;

    let GuestN = [...Guest];

    for (var i = 0; i < GuestN.length; i++) {
      let firstregMatch = /^[a-zA-Z ]*$/.test(GuestN[i].FirstName);
      if (GuestN[i].FirstName.length >= 1 && firstregMatch) {
        CheckError = true;
        GuestN[i]["FirstNameError"] = false;
      } else {
        CheckError = false;
        GuestN[i]["FirstNameError"] = true;
      }

      let lastregMatch = /^[a-zA-Z ]*$/.test(GuestN[i].LastName);
      if (GuestN[i].LastName.length >= 1 && lastregMatch) {
        CheckError = true;
        GuestN[i]["LastNameError"] = false;
      } else {
        CheckError = false;
        GuestN[i]["LastNameError"] = true;
      }
    }

    setGuest(GuestN);

    const email = validEmailRegex.test(EmailId);
    if (email == true) {
      setEmailError(false);
      CheckErrorEmail = true;
    } else {
      CheckErrorEmail = false;
      setEmailError(true);
    }
    //console.log(MobileNumber);

    let mobileregMatch = /^[0-9]*$/.test(MobileNumber);
    if (MobileNumber.length > 5 && MobileNumber.length < 15 && mobileregMatch) {
      CheckErrorMobile = true;
      setMobileError(false);
    } else {
      CheckErrorMobile = false;
      setMobileError(true);
    }

    if (
      CheckError == true &&
      CheckErrorEmail == true &&
      CheckErrorMobile == true
    ) {

      let text =
      "Please confirm your details and amount of purchase before proceeding forward.";
    if (window.confirm(text) == true) {
      setDisablePayNowButton(true);
      let myJSONString = JSON.stringify(Payment.details);
      let myEscapedJSONString = myJSONString
        .replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f");

      setRedirect(true);
      let customerArr = localStorage.getItem("userDetails");

      let CustomArr = JSON.parse(customerArr);

      let customer_id = CustomArr.uid;
      //  alert(customer_id);

      HotelConfirmBooking(
        Payment,
        Guest,
        GuestCount,
        EmailId,
        MobileNumber,
        MobileCode,
        StartDate,
        Enddate,
        customer_id,
        TransactionUUID
      ).then(
        (data) => {
          //console.log(data);
          const form = document.getElementById("confirmBookingButton");
          form.submit();

          // if (data.ejuniper == "PAG") {
          //   console.log("Booking Confirmed");
          //   setRedirect(false);
          //   const url =
          //     "/BookingPage?checkin=" +
          //     encodeURIComponent(StartDate) +
          //     "&checkout=" +
          //     encodeURIComponent(Enddate) +
          //     "&query=" +
          //     JSON.stringify(GuestCount) +
          //     "&hotelImage=" +
          //     encodeURIComponent(HotelImage) +
          //     "&data=" +
          //     encodeURIComponent(myEscapedJSONString);
          //   window.location.replace(url);
          // } else if (data.hs_portal == "CON") {
          //   setRedirect(false);
          //   const url =
          //     "/BookingPage?checkin=" +
          //     encodeURIComponent(StartDate) +
          //     "&checkout=" +
          //     encodeURIComponent(Enddate) +
          //     "&query=" +
          //     JSON.stringify(GuestCount) +
          //     "&hotelImage=" +
          //     encodeURIComponent(HotelImage) +
          //     "&data=" +
          //     encodeURIComponent(myEscapedJSONString);
          //   window.location.replace(url);
          // } else if (data.hotel_link == "CON") {
          //   setRedirect(false);
          //   const url =
          //     "/BookingPage?checkin=" +
          //     encodeURIComponent(StartDate) +
          //     "&checkout=" +
          //     encodeURIComponent(Enddate) +
          //     "&query=" +
          //     JSON.stringify(GuestCount) +
          //     "&hotelImage=" +
          //     encodeURIComponent(HotelImage) +
          //     "&data=" +
          //     encodeURIComponent(myEscapedJSONString);
          //   window.location.replace(url);
          // }
        },
        (error) => { }
      );
    }
  }
  };

  //Payment Detail Cyber Soure

  // let CybersourcePyment = () => {
  //   let myJSONString = JSON.stringify(Payment.details);
  //   let myEscapedJSONString = myJSONString
  //     .replace(/\\n/g, "\\n")
  //     .replace(/\\'/g, "\\'")
  //     .replace(/\\"/g, '\\"')
  //     .replace(/\\&/g, "\\&")
  //     .replace(/\\r/g, "\\r")
  //     .replace(/\\t/g, "\\t")
  //     .replace(/\\b/g, "\\b")
  //     .replace(/\\f/g, "\\f");

  //   console.log("ranjeet");
  //   let uniqueid = uuid();

  //   //console.log(uniqueid);
  //   let bill_to_forename = "Rohan";
  //   let bill_to_surname = "Tanwar";
  //   let bill_to_email = "rohantanwar094@gmail.com";
  //   let access_key = "cf67928dee4334b7bad437ff5c96dea6";
  //   let profile_id = "98B20037-201B-4F90-B3AC-42607218CB60";
  //   let transaction_uuid = uniqueid;
  //   let locale = "en-us";
  //   let transaction_type = 25;
  //   let reference_number = 625;
  //   let amount = 100;
  //   let currency = "USD";

  //   CybersourecPaymentGetway(
  //     bill_to_forename,
  //     bill_to_surname,
  //     bill_to_email,
  //     access_key,
  //     profile_id,
  //     transaction_uuid,
  //     locale,
  //     transaction_type,
  //     reference_number,
  //     amount,
  //     currency
  //   ).then(
  //     (data) => {
  //       console.log(data);

  //       // setRedirect(false);
  //       //   const url =
  //       //     "/BookingPage?checkin=" +
  //       //     encodeURIComponent(StartDate) +
  //       //     "&checkout=" +
  //       //     encodeURIComponent(Enddate) +
  //       //     "&query=" +
  //       //     JSON.stringify(GuestCount) +
  //       //     "&hotelImage=" +
  //       //     encodeURIComponent(HotelImage) +
  //       //     "&data=" +
  //       //     encodeURIComponent(myEscapedJSONString);
  //       //   window.location.replace(url);
  //     },
  //     (error) => { }
  //   );
  // };
  let LoginFaceBook = (e) => {
    e.preventDefault();
    setfacebookLogin(true);
  };

  let responseFacebook = (response) => {
    //console.log(response);
    if (response.accessToken) {
      setfacebookResponse(response);
      let Name = response.first_name;

      let first_name = response.first_name;
      let last_name = response.last_name;
      let email = response.email;
      let profile_image = response.picture.data.url;
      let birthday = moment(response.birthday).format("Y-MM-DD");
      let gender = response.gender;
      let login_source = 1;

      let mobile_number = "";
      let country1 = country;
      let country_code1 = country_code;
      let currency1 = currency;
      let ip_address1 = ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      setCookie("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country1,
        country_code1,
        currency1,
        ip_address1,
        password,
        verified
      ).then(
        (data) => {
          //console.log(data);
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => { }
      );
      setmodalIsOpen(false);
      // window.location.reload(true);
    }
  };

  let responseGoogle = (response) => {
    //console.log(response);
    if (response.accessToken) {
      setGoogleResponse(response);

      let first_name = response.profileObj.givenName;
      let last_name = response.profileObj.familyName;
      let email = response.profileObj.email;
      let profile_image = response.profileObj.imageUrl;
      let birthday = "";
      let gender = "";
      let login_source = 2;

      let mobile_number = "";
      let country1 = country;
      let country_code1 = country_code;
      let currency1 = currency;
      let ip_address1 = ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      setCookie("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country1,
        country_code1,
        currency1,
        ip_address1,
        password,
        verified
      ).then(
        (data) => {
          //console.log(data);
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => { }
      );
      setmodalIsOpen(false);
      //window.location.reload(true);
    }
  };

  let getDate = (data) => {
    // console.log(data);
    let DataNew = "";

    if (data[0].cancellation_penalty_id == 1) {
      const date = new Date(StartDate);
      date.setDate(date.getDate() - data[0].day_before_arrival);
      //console.log(date);
      DataNew =
        "Free Cancellation before " + moment(date).format("Do MMMM YYYY");
    } else if (data[0].cancellation_penalty_id == 3) {
      DataNew =
        "Minimum " +
        data[0].cancellation_value +
        "% cancellation charges applicable";
    }

    return DataNew;
  };

  let getDateModal = (data) => {
    // console.log(data);
    let DataNew = "";

    if (data[0].cancellation_penalty_id == 1) {
      const date = new Date(StartDate);
      date.setDate(date.getDate() - data[0].day_before_arrival);
      //console.log(date);
      DataNew =
        "Free Cancellation(100% refund) if you cancel this booking before " +
        moment(date).format("Do MMMM YYYY");
    } else if (data[0].cancellation_penalty_id == 3) {
      DataNew =
        "Minimum " +
        data[0].cancellation_value +
        "% cancellation charges applicable";
    }

    return DataNew;
  };

  let getDays = (data) => {
    let DataNew = "";
    data.map((i, j) => {
      if (i.penalty == "No Penalty") {
        const date = new Date(StartDate);
        date.setDate(date.getDate() - i.day_before_arrival);
        //console.log(date);
        DataNew = moment(date).format("D MMMM ");
      }
    });

    return DataNew;
  };

  let CancellationModal = (id, data) => {
    let DataNew = "";
    let Value = "";
    return (
      <>
        {" "}
        {data.map((i, index) => {
          const date = new Date(StartDate);
          date.setDate(date.getDate() - i.day_before_arrival);
          //console.log(date);
          DataNew = moment(date).format("Do MMMM YYYY");
          if (i.penalty == "No Penalty") {
            Value = 0;
          } else if (i.penalty == "Full Amount") {
            Value = 100;
          } else if (i.penalty == "Percentage") {
            Value = i.cancellation_value;
          } else if (i.penalty == "Nights") {
            Value = i.cancellation_value;
          } else if (i.penalty == "Fixed Amount") {
            Value = i.cancellation_value;
          }

          if (i.day_before_arrival == -1) {
            DataNew = "No Show";
          }
          if (id == i.rate_plan_id) {
            return (
              <div className="cncpolicyTbl__row">
                <div className="cncpolicyTbl__col">
                  {index == 0 ? "Before" : "After"} &#x202A;{DataNew}&#x202C;
                </div>
                <div className="cncpolicyTbl__col">
                  Charge {Value}% of booking amount
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };

  //console.log(DisablePayNowButton);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hotelid = urlParams.get("hotelid");
    const query = urlParams.get("query");
    const hotelImage = urlParams.get("hotelImage");
    const ratePlanCode = urlParams.get("ratePlanCode");
    const propertySource = urlParams.get("propertySource");
    const cart = urlParams.get("cart");

    let userData = localStorage.getItem("userDetails");
    if (propertySource == 1) {
      let query = urlParams.get("query");
      let querySearch1 = JSON.parse(query);
      setGuestCount(querySearch1.guestCombination);
    } else {
      let query = urlParams.get("cart");
      let querySearch2 = JSON.parse(query);
      setGuestCount(querySearch2);
    }

    let querySearch = JSON.parse(query);

    setStartDate(querySearch.startDate);
    setEnddate(querySearch.endDate);
    let date1 = new Date(querySearch.startDate);
    let date2 = new Date(querySearch.endDate);

    let timeDiff = Math.abs(date1.getTime() - date2.getTime());
    let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

    setNoNight(numberOfNights);
    setHotelImage(hotelImage);

    HotelBookingRules(hotelid, query, ratePlanCode, cart).then(
      (result) => {
        result.map((item, key) => {
          //console.log(item);
          setPayment(item);
        });
      },
      (error) => { }
    );

    getGeoInfo().then(
      (data) => {
        //console.log(data);
        setcountry(data.country_name);
        setcountry_code(data.country_calling_code);
        setcurrency(data.currency);
        setip_address(data.ip);
      },
      (error) => { }
    );

    if (userData != null) {
      let newData = JSON.parse(userData);
      getCustomerLogin(newData.email).then(
        (data) => {
         // console.log(data);
          setcustomerDetails(data);
          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
        },
        (error) => { }
      );
    }
  }, []);

  const getAmountHotelLink = (Payment, data) => {
    Payment.map((key, index) => (
      Object.keys(key).map((key1, index) => {
        const item = key[key1];
        let Offer = item.specialOffer;
        //console.log(Offer);
        if (Offer != "no_offer") {
          let result = [];
          if (Offer.combineoffer == "nocombine") {
            if (Offer.specialdata.spl_offer_type_id == 1) {
              const dis =
                data * parseInt(Offer.specialdata.pay_stay_length) -
                data * parseInt(Offer.specialdata.pay_stay_value);
              //console.log(dis);
              const disA =
                (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                100;
              //console.log(disA);
              result.push(disA);
            } else {
              if (Offer.specialdata.discount_in == 2) {
                const disA = Offer.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data - Offer.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          } else {
            if (Offer.specialdata.spl_offer_type_id == 1) {
              const dis =
                data * parseInt(Offer.specialdata.pay_stay_length) -
                data * parseInt(Offer.specialdata.pay_stay_value);
              const disA =
                (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                100;
              result.push(disA);
            } else {
              if (Offer.specialdata.discount_in == 2) {
                const disA = Offer.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data - Offer.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          }

          const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

          const balanceAmount =
            data - (Math.round(Amount * data) / 100).toFixed(2);

          return (
            <>
              <div className="total-amount">
                <div>
                  <h6 style={{ color: "black" }}>
                    {GuestCount.reduce((a, v) => a + v.roomCounter * 1, 0)} Room
                    x {NoNight} Nights
                  </h6>
                  <p>Base Price</p>
                </div>
                <div>
                  <p
                    class="makeFlex hrtlCenter appendBottom10 directionRtl"
                    style={{ textaAlign: "end", marginTop: 0 }}
                  >
                    <span class="redText font12">
                      <span>Save </span>
                      <span class="latoBold" id="hlistpg_hotel_saved_price">
                        €{((Amount * data) / 100).toFixed(2)}
                      </span>
                    </span>
                    <span class="spriteNew  relative appendLeft5">
                      <span
                        class="latoBold font11 whiteText discountText"
                        id="hlistpg_hotel_saved_price_percentage"
                        style={{
                          lineHeight: "15px",
                          paddingRight: "5px",
                        }}
                      >
                        {Amount}%
                      </span>
                    </span>
                  </p>
                  <p
                    className="grayText font16 lineThrough appendBottom5"
                    id="hlistpg_hotel_cut_price"
                  >
                    €{data}
                  </p>
                  <p
                    className="latoBlack font26 blackText appendBottom5"
                    id="hlistpg_hotel_shown_price"
                  >
                    €{balanceAmount.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="taxes">
                <h6 style={{ color: "black" }}>Taxes & Fees</h6>
                <h6 style={{ color: "black" }}>--</h6>
              </div>
              <div className="total-amount">
                <h6 style={{ color: "black" }}>Total Amount to be paid</h6>
                <h6 style={{ color: "black" }}>
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    style={{ fontSize: "14px" }}
                  ></FontAwesomeIcon>{" "}
                  {balanceAmount.toFixed(2)}
                </h6>
              </div>
            </>
          );
        } else {
          return (
            <p
              className="latoBlack font26 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              €{data}
            </p>
          );
        }
      })
    ));
  };

  const getAmountHotelLinkNew = (Payment, data) => {
    Payment.map((key, index) => {
       Object.keys(key).map((key1, index) => {
        const item = key[key1];
        let Offer = item?.specialOffer;
        //console.log(Offer);
        if (Offer.length !=0 && Offer != "no_offer") {
          let result = [];

          if (Offer.combineoffer == "nocombine") {
            if (Offer.specialdata.spl_offer_type_id == 1) {
              const dis =
                data * parseInt(Offer.specialdata.pay_stay_length) -
                data * parseInt(Offer.specialdata.pay_stay_value);
              //console.log(dis);
              const disA =
                (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                100;
              //console.log(disA);
              result.push(disA);
            } else {
              if (Offer.specialdata.discount_in == 2) {
                const disA = Offer.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data - Offer.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          } else {
            if (Offer.specialdata.spl_offer_type_id == 1) {
              const dis =
                data * parseInt(Offer.specialdata.pay_stay_length) -
                data * parseInt(Offer.specialdata.pay_stay_value);
              const disA =
                (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                100;
              result.push(disA);
            } else {
              if (Offer.specialdata.discount_in == 2) {
                const disA = Offer.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data - Offer.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          }

          const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

          const balanceAmount =
            data - (Math.round(Amount * data) / 100).toFixed(2);
           // console.log(balanceAmount);
          return balanceAmount;
        } else {
          return data;
        }
      });
    });
  };

  // console.log(MobileCode);
  // const timer = new Date();
  // timer.setSeconds(timer.getSeconds() + 600);
  // if (redirect) {
  //     return submit();
  // }

  return (
    <div>
       <Helmet>
        <title>Review Booking @ Holidays Seychelles</title>
      </Helmet>
      <h1 style={{}}>Review Booking @ Holidays Seychelles</h1>
      <Header />

      {Payment == null ? (
        <PaymentDetailsSkeleton />
      ) : (
        <div className="sticky-scroll topSection appendBottom40">
          <div className="review">
            <div className="landingContainer">
              <h4>Review Your Booking</h4>
            </div>
          </div>
          <div className="Details">
            <div className="landingContainer_details">
              <div className="subDetails">
                <div className={drop.class}>
                  {/* <div className="upper-Scroll">
                    <h6 style={{ color: "black" }}>HOTEL INFORMATION</h6>

                   
                    <button
                      onClick={HotelToggleHandler}
                      style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                    </button>
                  </div> */}
                  <div className="Detail-Hotel">
                    <div className="Hotel-Name">
                      <div className="nameContainer">
                        <div className="paymakeFlex appendBottom5">
                          <h3 className="latoBlack font16 blackText">
                            {Payment.details.property_name}
                          </h3>
                        </div>
                        <span
                            className="sRating"
                            style={{
                              display: "inline-flex",
                              marginTop: "5px",
                              marginLeft: "10px",
                            }}
                          >
                            {starsCount(Payment.details.star_category_id)}
                          </span>
                        <p>{Payment.details.address_line_1}</p>
                      </div>
                      <div className="imageContainer">
                        <img src={HotelImage} />
                      </div>
                    </div>

                    <div class="chkCont">
                      <div class="chkCont__col">
                        <div class="paymakeFlex column">
                          <span class="font10 grayText appendBottom3">
                            CHECK IN
                          </span>
                          <span class="latoBlack font16 blackText appendBottom3">
                            {moment(StartDate).format("D MMMM Y")}
                          </span>
                          <span class="font10 grayText 444">
                            {moment(StartDate).format("dddd")},{" "}
                            {Payment.details.check_in.split(":")[0] < 12
                              ? Payment.details.check_in + " AM"
                              : Payment.details.check_in.split(":")[0] - 12 == 0
                                ? "12" +
                                ":" +
                                Payment.details.check_in.split(":")[1] +
                                " PM"
                                : Payment.details.check_in.split(":")[0] +
                                ":" +
                                Payment.details.check_in.split(":")[1] +
                                " PM"}
                          </span>
                        </div>
                        <div class="chkCont__night">
                          <span>{NoNight} Nights</span>
                        </div>
                        <div class="paymakeFlex column">
                          <span class="font10 grayText appendBottom3">
                            CHECK OUT
                          </span>
                          <span class="latoBlack font16 blackText appendBottom3">
                            {moment(Enddate).format("D MMMM Y")}
                          </span>
                          <span class="font10 grayText">
                            {moment(Enddate).format("dddd")},{" "}
                            {Payment.details.check_out.split(":")[0] < 12
                              ? Payment.details.check_out + " AM"
                              : Payment.details.check_out.split(":")[0] - 12 ==
                                0
                                ? "12" +
                                ":" +
                                Payment.details.check_out.split(":")[1] +
                                " PM"
                                : Payment.details.check_out.split(":")[0] +
                                ":" +
                                Payment.details.check_out.split(":")[1] +
                                " PM"}
                          </span>
                        </div>
                      </div>
                      <div class="chkCont__col">
                        <p class="font16 blackText">
                          <span class="latoBlack">
                            {GuestCount.reduce(
                              (a, v) => a + v.adultCounter * 1,
                              0
                            )}
                          </span>
                          &nbsp;Adults,&nbsp;
                          <span class="latoBlack">
                            {GuestCount.reduce(
                              (a, v) => a + v.childCounter * 1,
                              0
                            )}
                          </span>
                          &nbsp;Children&nbsp;|{" "}
                          <span>
                            {GuestCount.reduce((a, v) => a + v.roomCounter, 0)}{" "}
                            Room
                          </span>
                        </p>
                      </div>
                    </div>

                    {Payment.ejuniper != "no_ejuniper" ? (
                      <>
                        {Payment.ejuniper.map((item, key) => (
                          <>
                            <div className="RoomInfo">
                              <div className="Room-Name">
                                <h5 className="latoBlack font16 blackText">
                                  {
                                    item.PriceInformation.HotelRooms.HotelRoom
                                      .Name
                                  }
                                </h5>
                                <p>
                                  {
                                    item.PriceInformation.HotelRooms.HotelRoom
                                      .RoomOccupancy.attributes.Adults
                                  }{" "}
                                  Adult,{" "}
                                  {
                                    item.PriceInformation.HotelRooms.HotelRoom
                                      .RoomOccupancy.attributes.Children
                                  }{" "}
                                  Child
                                </p>
                              </div>
                              <div className="Inclusions">
                                <h5  className="latoBlack font16 blackText">
                                  Price Includes
                                </h5>
                                <ul>
                                  <li>
                                    <FontAwesomeIcon icon={faCheck} />{" "}
                                    {item.PriceInformation.Board}
                                  </li>
                                  <li>
                                    <span className="latoBold greenText"
                                      // style={{
                                      //   fontSize: "large",
                                      //   color: "rgb(0 115 182)",
                                      // }}
                                    >
                                      Free Cancellation before ‪
                                      {moment(
                                        item.CancellationPolicy.PolicyRules
                                          .Rule[0].attributes.DateTo
                                      ).format("Do MMMM YYYY")}
                                      ‬
                                    </span>{" "}
                                    | Free Cancellation{" "}
                                    <span
                                       className="latoBlack font12 pointer appendLeft10 cancelPolicy"
                                      style={{
                                        color: "#0084ff",
                                        cursor: "pointer",
                                      }}
                                      onClick={openModalDiv}
                                    >
                                      Cancellation policy details
                                    </span>{" "}
                                  </li>
                                </ul>
                                {/* <div className="fcDetails dtHotelReviewNew">
                                  <div className="fcDetails__bar">
                                    <div className="fcDetails__bar--complete fcWidth80">
                                      <span className="fcDetails__bar-oval"></span>
                                      <span className="fcDetails__bar-text whiteText">
                                        Free Cancellation till &#8234;
                                        {moment(
                                          item.CancellationPolicy.PolicyRules
                                            .Rule[0].attributes.DateTo
                                        ).format("Do MMMM YYYY")}
                                        ‬&#8236;
                                      </span>
                                      <span className="fcDetails__bar-oval"></span>
                                    </div>
                                  </div>
                                  <div className="fcDetails__bottom">
                                    <div className="fcDetails__bottom--left">
                                      <span className="latoBlack font11 blackText">
                                        NOW
                                      </span>
                                      <span className="barLine latoBlack font11 blackText capText">
                                        {moment(
                                          item.CancellationPolicy.PolicyRules
                                            .Rule[0].attributes.DateTo
                                        ).format("D MMMM ")}
                                      </span>
                                    </div>
                                    <div className="fcDetails__bottom--right">
                                      <span className="latoBlack font11 blackText appendBottom3 capText">
                                        {moment(StartDate).format("D MMMM ")}
                                      </span>
                                      <span className="font10 darkGreyText">
                                        Check-in:
                                      </span>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>

                            <div className="Important-Info">
                              <div
                                style={{
                                  backgroundColor:
                                    "linear-gradient(90deg, #ff7f3f, #ff3e5e)",
                                }}
                                className="Important-Header"
                              >
                                <h6
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  Hotel Remarks
                                </h6>
                              </div>
                              <div className="rules">
                                {/* <h6 style={{ color: "black" }}>HOTEL RULES</h6> */}
                                <ul>
                                  <li style={{ color: "black" }}>
                                    {ReactHtmlParser(
                                      item.OptionalElements.Comments.Comment
                                    )}
                                  </li>
                                  {/* <li style={{ color: "black" }}>PAN Card, Office ID and Non-Govt IDs are not accepted as ID proof(s)</li>
                                                            <li style={{ color: "black" }}>Passport, Aadhar, Driving License and Govt. ID are accepted as ID proof(s)</li>
                                                            <li style={{ color: "black" }}>Hotel staff is trained on hygiene guidelines</li> */}
                                </ul>
                                {/* <p style={{ color: "blue" }}>Read All Hotel Rules</p> */}
                              </div>
                            </div>
                            <Modal
                              isOpen={openModal}
                              onRequestClose={closeModalDiv}
                              overlayClassName="backdropNew"
                            >
                              <div className="modalCont">
                                <span
                                  className="closeButton"
                                  onClick={closeModalDiv}
                                >
                                  Close
                                </span>
                                <h4>Cancellation Policy :</h4>
                                <div className="modalContent">
                                  <div>
                                    <div className="latoRegular font16 appendBottom15">
                                      <b>
                                        Free Cancellation(100% refund) if you
                                        cancel this booking before &#x202A;
                                        {moment(
                                          item.CancellationPolicy.PolicyRules
                                            .Rule[0].attributes.DateTo
                                        ).format("Do MMMM YYYY")}
                                        &#x202C;
                                      </b>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="latoRegular font16 appendBottom15">
                                      <b>
                                        Cancellations post that will be subject
                                        to a fee as follows
                                      </b>
                                    </div>
                                    <div className="cncpolicyTbl">
                                      <div className="cncpolicyTbl__head">
                                        <div className="cncpolicyTbl__col">
                                          DATE
                                        </div>
                                        <div className="cncpolicyTbl__col">
                                          FEE
                                        </div>
                                      </div>
                                      {item.CancellationPolicy.PolicyRules.Rule.map(
                                        (data, index) => (
                                          <div className="cncpolicyTbl__row">
                                            <div className="cncpolicyTbl__col">
                                              {index == 0 ? "Before" : "After"}{" "}
                                              &#x202A;
                                              {index == 0
                                                ? moment(
                                                  data.attributes.DateTo
                                                ).format("Do MMMM YYYY")
                                                : moment(
                                                  data.attributes.DateFrom
                                                ).format("Do MMMM YYYY")}
                                              &#x202C;
                                            </div>
                                            <div className="cncpolicyTbl__col">
                                              {data.attributes.PercentPrice}% of
                                              booking amount
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="latoRegular font16 appendBottom15">
                                      Cancellations are only allowed before the
                                      Check-In Time. All time mentioned above is
                                      in Destination Time.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}

                    {Payment.hs_portalandhl != "no_hlandhs" ? (
                      <>
                        {Payment.hs_portalandhl.map((key, index) => {
                          return (
                            <>
                              {Object.keys(key).map((key1, index) => {
                                const item = key[key1];
                                // console.log(item.room);
                                return (
                                  <>
                                    {item.room.map((i, k) => {
                                      return (
                                        <>
                                          <div className="RoomInfo">
                                            <div className="Room-Name">
                                            <h5  className="latoBlack font16 blackText">
                                                {i.room_name}
                                              </h5>
                                              <p>
                                                {GuestCount[k].adultCounter}{" "}
                                                Adult,{" "}
                                                {GuestCount[k].childCounter}{" "}
                                                Child
                                              </p>
                                            </div>
                                            <div className="Inclusions">
                                            <h5  className="latoBlack font16 blackText">
                                                Price Includes
                                              </h5>
                                              <ul>
                                                {/* <li><FontAwesomeIcon icon={faCheck} />  {item.PriceInformation.Board}</li> */}
                                                <li>
                                                  <span
                                                    className="latoBold greenText"
                                                  >
                                                    {getDate(
                                                      item.roomCancellation[k]
                                                    )}
                                                    ‬‬
                                                  </span>{" "}
                                                  | Free Cancellation{" "}
                                                  <span
                                                     className="latoBlack font12 pointer appendLeft10 cancelPolicy"
                                                     style={{
                                                       color: "#0084ff",
                                                       cursor: "pointer",
                                                     }}
                                                    onClick={openModalDivHS}
                                                  >
                                                    Cancellation policy details
                                                  </span>{" "}
                                                </li>
                                              </ul>
                                              {/* <div className="fcDetails dtHotelReviewNew">
                                                <div className="fcDetails__bar">
                                                  <div className="fcDetails__bar--complete fcWidth80">
                                                    <span className="fcDetails__bar-oval"></span>
                                                    <span className="fcDetails__bar-text whiteText">
                                                      &#8234;
                                                      {getDate(
                                                        item.roomCancellation[k]
                                                      )}
                                                      ‬&#8236;
                                                    </span>
                                                    <span className="fcDetails__bar-oval"></span>
                                                  </div>
                                                </div>
                                                <div className="fcDetails__bottom">
                                                  <div className="fcDetails__bottom--left">
                                                    <span className="latoBlack font11 blackText">
                                                      NOW
                                                    </span>
                                                    <span className="barLine latoBlack font11 blackText capText">
                                                      {getDays(
                                                        item.roomCancellation[k]
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div className="fcDetails__bottom--right">
                                                    <span className="latoBlack font11 blackText appendBottom3 capText">
                                                      {moment(StartDate).format(
                                                        "D MMMM "
                                                      )}
                                                    </span>
                                                    <span className="font10 darkGreyText">
                                                      Check-in:
                                                    </span>
                                                  </div>
                                                </div>
                                              </div> */}
                                            </div>
                                          </div>

                                          <Modal
                                            isOpen={openModalHS}
                                            onRequestClose={closeModalDivHS}
                                            overlayClassName="backdropNew"
                                          >
                                            <div className="modalCont">
                                              <span
                                                className="closeButton"
                                                onClick={closeModalDivHS}
                                              >
                                                Close
                                              </span>
                                              <h4>Cancellation Policy :</h4>
                                              <div className="modalContent">
                                                <div>
                                                  <div className="latoRegular font16 appendBottom15">
                                                    <b>
                                                      {getDateModal(
                                                        item.roomCancellation[k]
                                                      )}
                                                      ‬&#x202C;
                                                    </b>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div className="latoRegular font16 appendBottom15">
                                                    <b>
                                                      Cancellations post that
                                                      will be subject to a fee
                                                      as follows
                                                    </b>
                                                  </div>
                                                  <div className="cncpolicyTbl">
                                                    <div className="cncpolicyTbl__head">
                                                      <div className="cncpolicyTbl__col">
                                                        DATE
                                                      </div>
                                                      <div className="cncpolicyTbl__col">
                                                        FEE
                                                      </div>
                                                    </div>

                                                    {CancellationModal(
                                                      item.roomOptions[k].id,
                                                      item.roomCancellation[k]
                                                    )}
                                                  </div>
                                                </div>
                                                <div>
                                                  <div className="latoRegular font16 appendBottom15">
                                                    Cancellations are only
                                                    allowed before the Check-In
                                                    Time. All time mentioned
                                                    above is in Destination
                                                    Time.
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="GuestInfo">
                  <div className="upper-Scroll">
                    <h6 style={{ color: "black" }}>GUEST DETAILS</h6>
                    <button
                      onClick={FormToggleHandler}
                      style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                    </button>
                  </div>
                  <div className={drop.formClass}>
                    <form>
                      <div className="guestDtls__row">
                        {renderDropdownMenu()}
                      </div>
                      <div className="guestDtls__row">
                        <div className="paymakeFlex">
                          <div className="guestDtls__col width327 appendRight10">
                            <div className="textFieldCol ">
                              <p className="font11 appendBottom10 guestDtlsTextLbl">
                                <span className="capText">Email Address</span>
                                <span className="grayText appendLeft3">
                                  (Any Booking voucher will be sent to this
                                  email ID)
                                </span>
                              </p>
                              <input
                                type="text"
                                id="email"
                                name="email"
                                className={`frmTextInput ${EmailError ? "Err" : ""
                                  }`}
                                value={EmailId}
                                onChange={(e) => {
                                  OnChangeEmailId(e);
                                }}
                                placeholder="Email ID"
                              />
                              {EmailError && (
                                <p className="error">
                                  {" "}
                                  Please enter a valid Email ID!
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="guestDtls__col width327">
                            <p className="font11 capText appendBottom10">
                              Mobile Number
                            </p>
                            <div
                              className="paymakeFlex textLtr"
                              style={{ marginTop: 12 }}
                            >
                              <div className="guestDtls__contact">
                                <label
                                  for="mCode"
                                  className=" frmSelectCont__contact"
                                >
                                  <select
                                    id="mCode"
                                    className="frmSelect frmSelectContact"
                                    value={MobileCode}
                                    onChange={OnChangeMobileCode}
                                  >
                                    {DialCode.map((item, key) => (
                                      <option value={item.dial_code}>
                                        {item.name}&nbsp;({item.dial_code})
                                      </option>
                                    ))}
                                  </select>
                                </label>
                                <span className="selectedCode">
                                  {MobileCode == "" ? country_code : MobileCode}
                                </span>
                              </div>
                              <div className="flexOne">
                                <div className="textFieldCol ">
                                  <input
                                    type="text"
                                    id="mNo"
                                    name="mNo"
                                    className={`frmTextInput noLeftBorder ${MobileError ? "Err" : ""
                                      }`}
                                    placeholder="Mobile Number"
                                    value={MobileNumber}
                                    onChange={(e) => {
                                      OnChangeMobileNumber(e);
                                    }}
                                  />
                                  {MobileError && (
                                    <p className="error">
                                      {" "}
                                      Please enter a valid number!
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="guestDtls__add">
                        <a
                          className="guestDtls__addBtn appendRight5"
                          onClick={addGuest}
                        >
                          + Add Guest
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
                <form
                  id="confirmBookingButton"
                  action={CyberSource}
                  method="post"
                >
                  <input
                    type="hidden"
                    name="bill_to_forename"
                    value={Guest[0].FirstName}
                  />
                  <input
                    type="hidden"
                    name="bill_to_surname"
                    value={Guest[0].LastName}
                  />
                  <input type="hidden" name="currency" value="EUR" />
                  <input
                    type="hidden"
                    name="amount"
                    value={
                      Payment.ejuniper == "no_ejuniper"
                        ? getAmountHotelLinkNew(
                          Payment.hs_portalandhl,
                          Payment.hs_portalandhlAmount
                        )
                        : Payment.ejuniper.map(
                          (item) =>
                            // item.PriceInformation.Prices.Price.TotalFixAmounts
                            //   .Service.attributes.Amount
                            item.TotalAmountWithUplift
                            
                        )
                    }
                  />


                    {/* <input
                    type="hidden"
                    name="amount"
                    value={Payment.ejuniper == "no_ejuniper"? Payment.hs_portalandhl.map((key, index) => {
                       
                            {Object.keys(key).map((key1, index) => {
                              let data = Payment.hs_portalandhlAmount;
                              const item = key[key1];
                              let Offer = item.specialOffer;
                              console.log(Offer);
                              if (Offer != "no_offer") {
                                let result = [];
                                if (Offer.combineoffer == "nocombine") {
                                  if (Offer.specialdata.spl_offer_type_id == 1) {
                                    const dis =
                                      data * parseInt(Offer.specialdata.pay_stay_length) -
                                      data * parseInt(Offer.specialdata.pay_stay_value);
                                    console.log(dis);
                                    const disA =
                                      (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                                      100;
                                    console.log(disA);
                                    result.push(disA);
                                  } else {
                                    if (Offer.specialdata.discount_in == 2) {
                                      const disA = Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    } else {
                                      const disA = data - Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    }
                                  }
                                } else {
                                  if (Offer.specialdata.spl_offer_type_id == 1) {
                                    const dis =
                                      data * parseInt(Offer.specialdata.pay_stay_length) -
                                      data * parseInt(Offer.specialdata.pay_stay_value);
                                    const disA =
                                      (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                                      100;
                                    result.push(disA);
                                  } else {
                                    if (Offer.specialdata.discount_in == 2) {
                                      const disA = Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    } else {
                                      const disA = data - Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    }
                                  }
                                }

                                const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

                                const balanceAmount =
                                  data - (Math.round(Amount * data) / 100).toFixed(2);
                                 console.log(balanceAmount);
                                 return balanceAmount;
                                } else {
                                  console.log(data);
                                  return data;
                                  
                                }
                                
                              
                              }
                            )
                          }
                        
                    }):Payment.ejuniper.map(
                      (item) =>
                        item.PriceInformation.Prices.Price.TotalFixAmounts
                          .Service.attributes.Amount
                    )} /> */}
                   


                  <input type="hidden" name="bill_to_email" value={EmailId} />
                  <input
                    type="hidden"
                    name="transaction_uuid"
                    value={TransactionUUID}
                  />
                  <div style={{ padding: "10px 0px" }}>
                    <p style={{ padding: "10px 0px" }}>
                      By proceeding, I agree to Holidays Seychelles User
                      Agreement, Terms of Service and Cancellation & Hotel
                      Booking Policies
                    </p>
                    <button
                      className="btnContinuePayment primaryBtn capText"
                      onClick={
                        customerDetails == "abc" ? loginPage : ConfirmBooking
                      }
                      disabled={DisablePayNowButton}
                    >
                      Pay Now
                    </button>
                  </div>
                </form>
              </div>
              {Payment.ejuniper != "no_ejuniper" ? (
                <>
                  {" "}
                  {Payment.ejuniper.map((item, key) => (
                    <div className="pricing-details">
                      <div className="price-breakup">
                        <div className="price-header">
                        <h4 className="font16 latoBlack blackText">Price Breakup</h4>
                        </div>
                        <div className="total-amount">
                          <div>
                            <h6 style={{ color: "black" }}>
                              {GuestCount.reduce(
                                (a, v) => a + v.roomCounter * 1,
                                0
                              )}{" "}
                              Room x {NoNight} Nights
                            </h6>
                            <p>Base Price</p>
                          </div>
                          <div>
                            <h6 style={{ color: "grey" }}>
                              <FontAwesomeIcon
                                icon={faEuroSign}
                                style={{ fontSize: "14px" }}
                              ></FontAwesomeIcon>{" "}
                              {
                                // item.PriceInformation.Prices.Price
                                //   .TotalFixAmounts.Service.attributes.Amount
                                item.TotalAmountWithUplift
                              }
                            </h6>
                          </div>
                        </div>
                        <div className="taxes">
                          <h6 style={{ color: "black" }}>
                            Taxes & Fees
                          </h6>
                          <h6 style={{ color: "black" }}>--</h6>
                        </div>
                        <div className="total-amount">
                          <h6 style={{ color: "black" }}>
                            Total Amount to be paid
                          </h6>
                          <h6 style={{ color: "red" }}>
                            <FontAwesomeIcon
                              icon={faEuroSign}
                              style={{ fontSize: "14px" }}
                            ></FontAwesomeIcon>{" "}
                            {
                              // item.PriceInformation.Prices.Price.TotalFixAmounts
                              //   .Service.attributes.Amount
                              item.TotalAmountWithUplift
                            }
                          </h6>
                        </div>
                      </div>
                      {/* <div className="Deal-Codes">
                        <h5 style={{ color: "black" }}>DEAL CODES</h5>
                        <div className="cpnCont ">
                          <div className="cpnInput">
                            <input
                              type="text"
                              placeholder="Have a Coupon Code"
                              value=""
                            />
                            <a className="cpnInput__btn">Apply</a>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}

              {Payment.hs_portalandhl != "no_hlandhs" ? (
                <>
                  <div className="pricing-details">
                    <div className="price-breakup">
                      <div className="price-header">
                      <h4 className="font16 latoBlack blackText">Price Breakup</h4>
                      </div>

                      {Payment.hs_portalandhl.map((key, index) => {
                        return (
                          <>
                            {Object.keys(key).map((key1, index) => {
                              let data = Payment.hs_portalandhlAmount;
                              const item = key[key1];
                              let Offer = item.specialOffer;
                              //console.log(Offer);
                              if (Offer.length !=0 && Offer != "no_offer") {
                                let result = [];
                                if (Offer.combineoffer == "nocombine") {
                                  if (Offer.specialdata.spl_offer_type_id == 1) {
                                    const dis =
                                      data * parseInt(Offer.specialdata.pay_stay_length) -
                                      data * parseInt(Offer.specialdata.pay_stay_value);
                                    //console.log(dis);
                                    const disA =
                                      (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                                      100;
                                    //console.log(disA);
                                    result.push(disA);
                                  } else {
                                    if (Offer.specialdata.discount_in == 2) {
                                      const disA = Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    } else {
                                      const disA = data - Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    }
                                  }
                                } else {
                                  if (Offer.specialdata.spl_offer_type_id == 1) {
                                    const dis =
                                      data * parseInt(Offer.specialdata.pay_stay_length) -
                                      data * parseInt(Offer.specialdata.pay_stay_value);
                                    const disA =
                                      (dis / (data * parseInt(Offer.specialdata.pay_stay_length))) *
                                      100;
                                    result.push(disA);
                                  } else {
                                    if (Offer.specialdata.discount_in == 2) {
                                      const disA = Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    } else {
                                      const disA = data - Offer.specialdata.rate_discount;
                                      result.push(+disA);
                                    }
                                  }
                                }

                                const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

                                const balanceAmount =
                                  data - (Math.round(Amount * data) / 100).toFixed(2);
                                  return (
                                    <>
                                      <div className="total-amount">
                                        <div>
                                          <h6 style={{ color: "black" }}>
                                            {GuestCount.reduce((a, v) => a + v.roomCounter * 1, 0)} Room
                                            x {NoNight} Nights
                                          </h6>
                                          <p>Base Price</p>
                                        </div>
                                        <div>
                                          <p
                                            class="makeFlex hrtlCenter appendBottom10 directionRtl"
                                            style={{ textaAlign: "end", marginTop: 0 }}
                                          >
                                            <span class="redText font12">
                                              <span>Save </span>
                                              <span class="latoBold" id="hlistpg_hotel_saved_price">
                                                €{((Amount * data) / 100).toFixed(2)}
                                              </span>
                                            </span>
                                            <span class="spriteNew  relative appendLeft5">
                                              <span
                                                class="latoBold font11 whiteText discountText"
                                                id="hlistpg_hotel_saved_price_percentage"
                                                style={{
                                                  lineHeight: "15px",
                                                  paddingRight: "5px",
                                                }}
                                              >
                                                {Amount}%
                                              </span>
                                            </span>
                                          </p>
                                          <p
                                            className="grayText font16 lineThrough appendBottom5"
                                            id="hlistpg_hotel_cut_price"
                                          >
                                            €{data}
                                          </p>
                                          <p
                                            className="latoBlack font16 blackText appendBottom5"
                                            id="hlistpg_hotel_shown_price"
                                          >
                                            €{balanceAmount.toFixed(2)}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="taxes">
                                        <h6 style={{ color: "black" }}>Taxes & Fees</h6>
                                        <h6 style={{ color: "black" }}>--</h6>
                                      </div>
                                      <div className="total-amount">
                                        <h6 style={{ color: "black" }}>Total Amount to be paid</h6>
                                        <h6 style={{ color: "black" }}>
                                          <FontAwesomeIcon
                                            icon={faEuroSign}
                                            style={{ fontSize: "14px" }}
                                          ></FontAwesomeIcon>{" "}
                                          {balanceAmount.toFixed(2)}
                                        </h6>
                                      </div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                    <div className="total-amount">
                                      <div>
                                        <h6 style={{ color: "black" }}>
                                          {GuestCount.reduce((a, v) => a + v.roomCounter * 1, 0)} Room
                                          x {NoNight} Nights
                                        </h6>
                                        <p>Base Price</p>
                                      </div>
                                      <div>
                                       
                                        <p
                                          className="latoBlack font16 blackText appendBottom5"
                                          id="hlistpg_hotel_shown_price"
                                        >
                                          €{data.toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="taxes">
                                      <h6 style={{ color: "black" }}>Taxes & Fees</h6>
                                      <h6 style={{ color: "black" }}>--</h6>
                                    </div>
                                    <div className="total-amount">
                                      <h6 style={{ color: "black" }}>Total Amount to be paid</h6>
                                      <h6 style={{ color: "black" }}>
                                        <FontAwesomeIcon
                                          icon={faEuroSign}
                                          style={{ fontSize: "14px" }}
                                        ></FontAwesomeIcon>{" "}
                                        {data.toFixed(2)}
                                      </h6>
                                    </div>
                                  </>
                                  );
                                }
                              })
                            }
                          </>)
                      })}
                      {/* {getAmountHotelLink(
                        Payment.hs_portalandhl,
                        Payment.hs_portalandhlAmount
                      )} */}
                    </div>
                    {/* <div className="Deal-Codes">
                      <h5 style={{ color: "black" }}>DEAL CODES</h5>

                        <div className="cpnCont">
                          <div className="cpnInput">
                            <button
                              className="cpnInput__btn"
                              type="button"
                              onClick={() => rohan()}
                            >
                              Apply Cyber
                            </button>
                          </div>
                        </div>
       
                    </div>  */}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className={ChangeLoginScreenClass}>
          <div className="form-container sign-up-container">
            <form className="FormCon">
              <h1>Create Account</h1>
              <SignUp
                country_code={country_code}
                currency={currency}
                ip_address={ip_address}
                country={country}
              />
            </form>
          </div>
          {facebookLogin && (
            <FacebookLogin
              //appId="426993597895758"
              appId="1194539465165185"
              autoLoad={true}
              fields="first_name, last_name, email, picture, birthday,gender"
              scope="public_profile, email, user_birthday,user_gender"
              returnScopes={true}
              callback={responseFacebook}
            />
          )}

          <div className="form-container sign-in-container">
            <form className="FormCon" action="#">
              <h1>LOG IN</h1>
              {errorPassword && (
                <p className="error"> Email or Password is not correct !</p>
              )}
              <input
                type="email"
                placeholder="Email"
                value={LoginEmail}
                onChange={ChangeLoginEmail}
              />
              <input
                type="password"
                placeholder="Password"
                value={LoginPassword}
                onChange={ChangeLoginPassword}
              />
              <a href="#">Forgot your password?</a>
              <button onClick={LoginConfirm}>LOG IN</button>
              <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                <div
                  className="SocialmakeFlex fbLoginBtn flexOne"
                  onClick={LoginFaceBook}
                >
                  <span className=" popupSprite popupFbIcon"></span>
                  <span
                    className="font14 whiteText latoBold latoBold"
                    style={{ textTransform: "uppercase" }}
                  >
                    Login with Facebook
                  </span>
                </div>
              </div>
              <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                <div className="SocialmakeFlex googleLoginBtn flexOne">
                  <span style={{ width: "100%" }}>
                    {" "}
                    <GoogleLogin
                      clientId="924181911314-6ml7tmqad8dkh9ugosligld4b9kkremq.apps.googleusercontent.com"
                      buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button className="ghost" onClick={ChangeLoginReverse}>
                  LOG IN
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button className="ghost" onClick={ChangeLoginScreen}>
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentDetails;
