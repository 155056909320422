import React, { Component } from "react";
import "./Tour.css";
import NewHeader from "../../Component/NewHeader/NewHeader";
import {
  ActivityZoneData,
  ActivityDetailsData,
  CheckAvailabilityData,
  getTourActivityList,
} from "../axios";
import Autosuggest from "react-autosuggest";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import "react-image-gallery/styles/css/image-gallery.css";
import { Base64 } from "js-base64";
import { LazyLoadImage } from "react-lazy-load-image-component";
//import Ratings from "react-ratings-declarative";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
  faMapMarkedAlt,
  faHotel,
  faPlane,
  faShip,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import ImageGallery from "react-image-gallery";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  faCalendarCheck,
  faCheckCircle,
  faTimesCircle,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";
import Collapsible from "react-collapsible";
import HotelDetailSkeleton from "../HotelDetails/HotelDetailSkeleton";
import ReactHtmlParser from "react-html-parser";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import TawkToWidget from "../../Component/TawkToWidget";
const ListNumber = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7" },
  { id: "8" },
  { id: "9" },
  { id: "10" },
  { id: "11" },
  { id: "12" },
  { id: "13" },
  { id: "14" },
  { id: "15" },
  { id: "16" },
  { id: "17" },
  { id: "18" },
  { id: "19" },
  { id: "20" },
  { id: "21" },
  { id: "22" },
  { id: "23" },
  { id: "24" },
  { id: "25" },
];

const ListNumberNew = [
  { id: "0" },
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7" },
  { id: "8" },
  { id: "9" },
  { id: "10" },
  { id: "11" },
  { id: "12" },
  { id: "13" },
  { id: "14" },
  { id: "15" },
  { id: "16" },
  { id: "17" },
  { id: "18" },
  { id: "19" },
  { id: "20" },
  { id: "21" },
  { id: "22" },
  { id: "23" },
  { id: "24" },
];

const scrollToRef = (ref) =>
  window.scrollTo({
    top: 280,
    left: 100,
    behavior: "smooth",
  });

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class ActivityDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      isOpen: false,
      dropdownVisible: false,
      selected: "Accommodations",
      searchInput: "",
      error: null,
      val: "",
      showResults: false,
      SelectedPlace: null,
      nav: false,
      searchData: "",
      sorting: false,
      sortingSelect: "Price - Low to High",
      sortingId: null,
      value: "",
      suggestionsActivity: [],
      showResultsActivity: false,
      defaultZonevalActivity: "Seychelles",
      activityData: null,
      tour_data: "",
      tour_description: "",
      tour_price: "",
      tour_gallery: [],
      selectActivityDay: moment(new Date()).format("dddd, MMM D, YYYY"),
      selectActivityDayNew: new Date(),
      checkinShowActivity: false,
      checkinShowActivityMain: false,
      cats: [
        {
          adultCounter: 1,
          infantCounter: 0,
          childCounter: 0,
          youthCounter: 0,
          seniorCounter: 0,
          groupCounter: 1,
        },
      ],
      numberTraveler: "Number of travelers",
      errMessage: false,
      tour_inclusion: [],
      tour_exclusion: [],
      tour_meetingpoint: "",
      show_options: false,
      tour_options: null,
      checkName: "Check Availability",
      selectedIndex: null,
      buttonDisabled: "disabled",
      selectedIndexNew: null,
      selectedTime: 1,
      selectedTimeValue: "",
      StartDate: null,
      Enddate: null,
      tour_cancellation_policy: null,
      tour_itinerary: null,
      single_tour_gallery: [],
      extracharges: "",
      modalIsOpen: false,
    //  cartPopupOpen: false,
      slideShow: false,
    };
    this.myDivToFocus = React.createRef();
  }

  // PopuphandleCloseClick = () => {
  //   this.setState({ slideShow: false });
  // };

  
  
 
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const name = urlParams.get("name");
    const tourid = urlParams.get("tourid");
    const dateSelected = urlParams.get("dateselected");
    this.setState({ defaultZonevalActivity: name });
    this.setState({
      selectActivityDay: moment(new Date(dateSelected)).format(
        "dddd, MMM D, YYYY"
      ),
    });
    this.setState({
      selectActivityDayNew: moment(dateSelected).format("Y-MM-DD"),
    });

    let JsonState = {
      tourid: Base64.atob(tourid),
      dateSelected: moment(dateSelected).format("Y-MM-DD"),
    };
    const hotelArr = JSON.stringify(JsonState);
   
    ActivityZoneData().then(
      (data) => {
        this.setState({
          itemsZoneActivity: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    ActivityDetailsData(hotelArr).then(
      (data) => {
     
        data.map((i, j) => {
          if (i.extracharges == "no_extracharge") {
            this.setState({ extracharges: 0 });
          } else {
            this.setState({ extracharges: i.extracharges });
          }

          this.setState({ tour_data: i.tour_data });
          this.setState({ tour_description: i.tour_description });
          this.setState({ tour_price: i.tour_price });
          this.setState({ tour_inclusion: i.tour_inclusion });
          this.setState({ tour_exclusion: i.tour_exclusion });
          this.setState({ tour_meetingpoint: i.tour_meetingpoint });
          this.setState({
            tour_cancellation_policy: i.tour_cancellation_policy,
          });
          this.setState({tour_free_cancellation_policy: i.freeroomCancellation});
          this.setState({ tour_itinerary: i.tour_itinerary });
          this.setState({ single_tour_gallery: i.tour_gallery[0] });

          const newGallery = [];
          i.tour_gallery.map((x, y) => {
            newGallery.push({ original: x, thumbnail: x });
          });

          this.setState({ tour_gallery: newGallery });
          
        
            let adultTraveler = this.state.cats.reduce(
              (a, v) => a + v.adultCounter * 1,
              0
            );
            let infantTraveler = this.state.cats.reduce(
              (a, v) => a + v.infantCounter * 1,
              0
            );
            let childTraveler = this.state.cats.reduce(
              (a, v) => a + v.childCounter * 1,
              0
            );
            let youthTraveler = this.state.cats.reduce(
              (a, v) => a + v.youthCounter * 1,
              0
            );
            let seniorTraveler = this.state.cats.reduce(
              (a, v) => a + v.seniorCounter * 1,
              0
            );
            let GroupTraveler = this.state.cats.reduce(
              (a, v) => a + v.groupCounter * 1,
              0
            );
      
            let newString = "";
            if (i?.tour_price?.price_options?.adult == 1) {
              if (adultTraveler == 1) {
                newString = adultTraveler + " adult";
              } else {
                newString = adultTraveler + " adults";
              }
            }
      
            if (i?.tour_price?.price_options?.child == 1) {
              if (childTraveler > 0) {
                if (childTraveler == 1) {
                  newString = newString + ", " + childTraveler + " child";
                } else {
                  newString = newString + ", " + childTraveler + "children";
                }
              }
            }
      
            if (i?.tour_price?.price_options?.infant == 1) {
              if (infantTraveler > 0) {
                if (infantTraveler == 1) {
                  newString = newString + ", " + infantTraveler + " infant";
                } else {
                  newString = newString + ", " + infantTraveler + " infants";
                }
              }
            }
      
            if (i?.tour_price?.price_options?.youth == 1) {
              if (youthTraveler > 0) {
                if (youthTraveler == 1) {
                  newString = newString + ", " + youthTraveler + " youth";
                } else {
                  newString = newString + ", " + youthTraveler + " youths";
                }
              }
            }
      
            if (i?.tour_price?.price_options?.senior == 1) {
              if (seniorTraveler > 0) {
                if (seniorTraveler == 1) {
                  newString = newString + seniorTraveler + " senior";
                } else {
                  newString = newString + seniorTraveler + " seniors";
                }
              }
            }
      
            if(i?.tour_price?.price_options?.price_plan == 2){
              if (i.tour_price.price_options.max_booking_travelers_free> 0) {
                newString = GroupTraveler + " free travelers";
              }
            }else{
              if (i?.tour_price?.tour_price_data?.group_max > 0) {
                newString = GroupTraveler + " travelers";
              }
            }
            this.setState({ numberTraveler: newString});
          

        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    window.addEventListener("scroll", this.handleScroll);
  }

  SearchListing = () => {
    let JsonActivity = {
      defaultZonevalActivityVal: this.state.defaultZonevalActivity,
      dateSelected:moment(this.state.selectActivityDay).format("Y-MM-DD"),
      activitypage: 1,
      activitysize: 50,
    };

    const activityArr = JSON.stringify(JsonActivity);

 
    getTourActivityList(activityArr).then(
      (data) => {
        let tourdata = data?.todata;
     
        if (data == undefined) {
      
        } else if (data?.todata.length > 1) {
          const url =
           "/activitylisting?defaultZonevalActivityVal=" +
              encodeURIComponent(this.state.defaultZonevalActivity) +
              "&dateSelected=" +
              encodeURIComponent(this.state.selectActivityDay);
          window.location.replace(url);
        } else {
          tourdata.map((item, key) => {
          Object.values(item).map((itemdata, data) => {
          const url =
          "/activityDetails?tourid=" + Base64.btoa(itemdata.tour_data.id) + "&name=" + encodeURIComponent(itemdata.tour_data.tour_name) + "&location=" + encodeURIComponent(itemdata.tour_data.tour_location) + "&dateselected=" + encodeURIComponent(this.state.selectActivityDay)

          window.location.replace(url);
          });
           
          });
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  renderDropdownMenu() {
    return (
      <div className="roomsGuestsListNew" onClick={this.handleBodyClick}>
        {this.state.cats.map((val, idx) => {
          return (
            <div className="roomsGuestsTop" key={idx}>
              {this.state.tour_price.price_options.price_plan == 1 ?( this.state.tour_price.price_options.price_type == 1 ? (
                <div className="addRoomRowhs">
                  <div className="addRoomLeft rowNew" style={{ width: "100%" }}>
                    <p
                      className={`${
                        this.state.errMessage == false
                          ? "darkText font16 appendBottom10"
                          : "darkText font16 appendBottom10 ErrM"
                      }`}
                      style={{ fontSize: 14 }}
                    >
                      You can select up to{" "}
                      {
                        this.state.tour_price.price_options
                          .max_booking_travelers
                      }{" "}
                      travelers in total.
                    </p>
                    <button
                      class="primaryBtn2 appendBottom15"
                      type="button"
                      onClick={this.setApplyTraveler}
                    >
                      Apply
                    </button>
                  </div>
                  <div className="addRoomRight">
                    <div className="addRooomDetails">
                      {this.state.tour_price.price_options.adult == 1 && (
                        <div>
                          <p className="spaceBetween appendBottom10">
                            <span className="latoBold font12 grayText">
                              Adult (age{" "}
                              {this.state.tour_price.price_options.adult_min}-
                              {this.state.tour_price.price_options.adult_max}){" "}
                            </span>
                          </p>
                          <ul
                            className="guestCounter font12 darkText"
                            // style={{ width: 370 }}
                            style={{ width: 'auto' }}
                          >
                            {ListNumber.map((item, index) => {
                              return index <
                                this.state.tour_price.price_options
                                  .max_booking_travelers ? (
                                <li
                                  className={
                                    this.state.cats[idx].adultCounter == item.id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    this.handleInputChange(
                                      item.id,
                                      idx,
                                      "adultCounter"
                                    )
                                  }
                                >
                                  {item.id}
                                </li>
                              ) : (
                                // <li className="click-disabled" key={index}>
                                //   {item.id}
                                // </li>
                                <></>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {this.state.tour_price.price_options.infant == 1 && (
                        <div>
                          <p className="spaceBetween appendBottom10">
                            <span className="latoBold font12 grayText">
                              Infant (age{" "}
                              {this.state.tour_price.price_options.infant_min}-
                              {this.state.tour_price.price_options.infant_max}){" "}
                            </span>
                          </p>
                          <ul
                            className="guestCounter font12 darkText"
                           // style={{ width: 370 }}
                           style={{ width: 'auto' }}
                          >
                            {ListNumberNew.map((item, index) => {
                              return index <
                                this.state.tour_price.price_options
                                  .max_booking_travelers ? (
                                <li
                                  className={
                                    this.state.cats[idx].infantCounter ==
                                    item.id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    this.handleInputChange(
                                      item.id,
                                      idx,
                                      "infantCounter"
                                    )
                                  }
                                >
                                  {item.id}
                                </li>
                              ) : (
                                // <li className="click-disabled" key={index}>
                                //   {item.id}
                                // </li>
                                <></>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {this.state.tour_price.price_options.child == 1 && (
                        <div>
                          <p className="spaceBetween appendBottom10">
                            <span className="latoBold font12 grayText">
                              Child (age{" "}
                              {this.state.tour_price.price_options.child_min}-
                              {this.state.tour_price.price_options.child_max}){" "}
                            </span>
                          </p>
                          <ul
                            className="guestCounter font12 darkText"
                             // style={{ width: 370 }}
                           style={{ width: 'auto' }}
                          >
                            {ListNumberNew.map((item, index) => {
                              return index <
                                this.state.tour_price.price_options
                                  .max_booking_travelers ? (
                                <li
                                  className={
                                    this.state.cats[idx].childCounter == item.id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    this.handleInputChange(
                                      item.id,
                                      idx,
                                      "childCounter"
                                    )
                                  }
                                >
                                  {item.id}
                                </li>
                              ) : (
                                // <li className="click-disabled" key={index}>
                                //   {item.id}
                                // </li>
                                <></>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {this.state.tour_price.price_options.youth == 1 && (
                        <div>
                          <p className="spaceBetween appendBottom10">
                            <span className="latoBold font12 grayText">
                              Youth (age{" "}
                              {this.state.tour_price.price_options.youth_min}-
                              {this.state.tour_price.price_options.youth_max}){" "}
                            </span>
                          </p>
                          <ul
                            className="guestCounter font12 darkText"
                             // style={{ width: 370 }}
                           style={{ width: 'auto' }}
                          >
                            {ListNumberNew.map((item, index) => {
                              return index <
                                this.state.tour_price.price_options
                                  .max_booking_travelers ? (
                                <li
                                  className={
                                    this.state.cats[idx].youthCounter == item.id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    this.handleInputChange(
                                      item.id,
                                      idx,
                                      "youthCounter"
                                    )
                                  }
                                >
                                  {item.id}
                                </li>
                              ) : (
                                // <li className="click-disabled" key={index}>
                                //   {item.id}
                                // </li>
                                <></>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                      {this.state.tour_price.price_options.senior == 1 && (
                        <div>
                          <p className="spaceBetween appendBottom10">
                            <span className="latoBold font12 grayText">
                              Senior (age{" "}
                              {this.state.tour_price.price_options.senior_min}-
                              {this.state.tour_price.price_options.senior_max}){" "}
                            </span>
                          </p>
                          <ul
                            className="guestCounter font12 darkText"
                             // style={{ width: 370 }}
                           style={{ width: 'auto' }}
                          >
                            {ListNumberNew.map((item, index) => {
                              return index <
                                this.state.tour_price.price_options
                                  .max_booking_travelers ? (
                                <li
                                  className={
                                    this.state.cats[idx].seniorCounter ==
                                    item.id
                                      ? "selected"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    this.handleInputChange(
                                      item.id,
                                      idx,
                                      "seniorCounter"
                                    )
                                  }
                                >
                                  {item.id}
                                </li>
                              ) : (
                                // <li className="click-disabled" key={index}>
                                //   {item.id}
                                // </li>
                                <></>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="addRoomRowhs">
                  <div className="addRoomLeft rowNew" style={{ width: "100%" }}>
                    <p
                      className="darkText font16 appendBottom10"
                      style={{ fontSize: 14 }}
                    >
                      You can select up to{" "}
                      {this.state.tour_price.tour_price_data.group_max}{" "}
                      travelers in total.
                    </p>
                    <button
                      class="primaryBtn2 appendBottom15"
                      type="button"
                      onClick={this.setApplyTraveler}
                    >
                      Apply
                    </button>
                  </div>
                  <div className="addRoomRight">
                    <div className="addRooomDetails">
                      <p className="spaceBetween appendBottom10">
                        <span className="latoBold font12 grayText">
                          Number of travelers
                        </span>
                      </p>
                      <ul
                        className="guestCounter font12 darkText"
                         // style={{ width: 370 }}
                         style={{ width: 'auto' }}
                      >
                        {ListNumber.map((item, index) =>
                          index <
                          this.state.tour_price.tour_price_data.group_max ? (
                            <li
                              className={
                                this.state.cats[idx].groupCounter == item.id
                                  ? "selected"
                                  : ""
                              }
                              key={index}
                              onClick={() =>
                                this.handleInputChangeNew(
                                  item.id,
                                  idx,
                                  "groupCounter"
                                )
                              }
                            >
                              {item.id}
                            </li>
                          ) : (
                            // <li className="click-disabled" key={index}>
                            //   {item.id}
                            // </li>
                            <></>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )):( <div className="addRoomRowhs">
              <div className="addRoomLeft rowNew" style={{ width: "100%" }}>
                <p
                  className="darkText font16 appendBottom10"
                  style={{ fontSize: 14 }}
                >
                  You can select up to{" "}
                  {this.state.tour_price.price_options.max_booking_travelers_free}{" "}
                  travelers in total.
                </p>
                <button
                  class="primaryBtn2 appendBottom15"
                  type="button"
                  onClick={this.setApplyTraveler}
                >
                  Apply
                </button>
              </div>
              <div className="addRoomRight">
                <div className="addRooomDetails">
                  <p className="spaceBetween appendBottom10">
                    <span className="latoBold font12 grayText">
                      Number of travelers
                    </span>
                  </p>
                  <ul
                    className="guestCounter font12 darkText"
                     // style={{ width: 370 }}
                     style={{ width: 'auto' }}
                  >
                    {ListNumber.map((item, index) =>
                      index <
                      this.state.tour_price.price_options.max_booking_travelers_free ? (
                        <li
                          className={
                            this.state.cats[idx].groupCounter == item.id
                              ? "selected"
                              : ""
                          }
                          key={index}
                          onClick={() =>
                            this.handleInputChangeNew(
                              item.id,
                              idx,
                              "groupCounter"
                            )
                          }
                        >
                          {item.id}
                        </li>
                      ) : (
                        // <li className="click-disabled" key={index}>
                        //   {item.id}
                        // </li>
                        <></>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>)}
            </div>
          );
        })}
      </div>
    );
  }

  handleInputChange = (e, index, counter) => {
    let cats = [...this.state.cats];
    cats[index][counter] = e;
    this.setState({
      cats: cats,
    });

    const sumTraveler = this.state.cats.reduce(
      (a, v) =>
        a +
        v.adultCounter * 1 +
        v.infantCounter * 1 +
        v.childCounter * 1 +
        v.youthCounter * 1 +
        v.seniorCounter * 1,
      0
    );
    //  alert(sumTraveler);
    const maxTraveler = this.state.tour_price.price_options
      .max_booking_travelers;
    if (sumTraveler > maxTraveler) {
      this.setState({ errMessage: true });
      if (counter == "adultCounter") {
        let cats = [...this.state.cats];
        cats[index][counter] = 1;
        this.setState({
          cats: cats,
        });
      } else {
        let cats = [...this.state.cats];
        cats[index][counter] = 0;
        this.setState({
          cats: cats,
        });
      }
      setTimeout(() => {
        this.setState({ errMessage: false });
      }, 5000);
    }
  };

  handleInputChangeNew = (e, index, counter) => {
    let cats = [...this.state.cats];
    cats[index][counter] = e;
    this.setState({
      cats: cats,
    });
  };

  setApplyTraveler = () => {
    let adultTraveler = this.state.cats.reduce(
      (a, v) => a + v.adultCounter * 1,
      0
    );
    let infantTraveler = this.state.cats.reduce(
      (a, v) => a + v.infantCounter * 1,
      0
    );
    let childTraveler = this.state.cats.reduce(
      (a, v) => a + v.childCounter * 1,
      0
    );
    let youthTraveler = this.state.cats.reduce(
      (a, v) => a + v.youthCounter * 1,
      0
    );
    let seniorTraveler = this.state.cats.reduce(
      (a, v) => a + v.seniorCounter * 1,
      0
    );
    let GroupTraveler = this.state.cats.reduce(
      (a, v) => a + v.groupCounter * 1,
      0
    );

    let newString = "";
    if (this.state.tour_price.price_options.adult == 1) {
      if (adultTraveler == 1) {
        newString = adultTraveler + " adult";
      } else {
        newString = adultTraveler + " adults";
      }
    }

    if (this.state.tour_price.price_options.child == 1) {
      if (childTraveler > 0) {
        if (childTraveler == 1) {
          newString = newString + ", " + childTraveler + " child";
        } else {
          newString = newString + ", " + childTraveler + "children";
        }
      }
    }

    if (this.state.tour_price.price_options.infant == 1) {
      if (infantTraveler > 0) {
        if (infantTraveler == 1) {
          newString = newString + ", " + infantTraveler + " infant";
        } else {
          newString = newString + ", " + infantTraveler + " infants";
        }
      }
    }

    if (this.state.tour_price.price_options.youth == 1) {
      if (youthTraveler > 0) {
        if (youthTraveler == 1) {
          newString = newString + ", " + youthTraveler + " youth";
        } else {
          newString = newString + ", " + youthTraveler + " youths";
        }
      }
    }

    if (this.state.tour_price.price_options.senior == 1) {
      if (seniorTraveler > 0) {
        if (seniorTraveler == 1) {
          newString = newString + ", " + seniorTraveler + " senior";
        } else {
          newString = newString + ", " + seniorTraveler + " seniors";
        }
      }
    }

    if(this.state.tour_price.price_options.price_plan == 2){
      if (this.state.tour_price.price_options.max_booking_travelers_free> 0) {
        newString = GroupTraveler + " free travelers";
      }
    }else{
      if (this.state.tour_price.tour_price_data.group_max > 0) {
        newString = GroupTraveler + " travelers";
      }
    }

    this.setState({ numberTraveler: newString, dropdownVisible: false });

    this.setState({ show_options: true });
    this.setState({ tour_options: null });
    this.setState({ checkName: "Update Search" });

    this.setState({ selectedIndex: null });
    this.setState({ selectedIndexNew: null });
    this.setState({ buttonDisabled: "disabled" });

    let numberTravelers = this.state.cats;
    let checkDate = moment(this.state.selectActivityDayNew).format("Y-MM-DD");
    let tour_id = this.state.tour_data.id;
    let price_type = this.state.tour_price.price_options.price_type;

    CheckAvailabilityData(numberTravelers, checkDate, tour_id, price_type).then(
      (data) => {
        this.setState({ tour_options: data });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    // this.state.tour_price.price_options.adult == 1
  };
  componentWillUnmount() {
    // document.removeEventListener('click', this.globalClickListener)
    window.removeEventListener("click", this.globalClickListener);
    window.removeEventListener("scroll", this.handleScroll);
  }
  globalClickListener = (nativeEvent) => {
    this.setState(
      {
        dropdownVisible: false,
        showResults: false,
        checkinShow: false,
        showResultsActivity: false,
        checkinShowActivity: false,
        checkinShowActivityMain: false,
       
      },
      () => {
        // document.removeEventListener('click', this.globalClickListener)
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };

  checkinandcheckoutActivity = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowActivity: !prevState.checkinShowActivity }),
      () => {
        if (this.state.checkinShowActivity) {
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  checkinandcheckoutActivityMain = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowActivityMain: !prevState.checkinShowActivityMain }),
      () => {
        if (this.state.checkinShowActivityMain) {
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  handleBodyClick = (event) => {
    event.stopPropagation();
  };

  showSorting = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ sorting: !prevState.sorting }),
      () => {
        if (this.state.sorting) {
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  getSuggestionsActivity = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneActivity.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  onSuggestionsFetchRequestedActivity = ({ value }) => {
    this.setState({
      suggestionsActivity: this.getSuggestionsActivity(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequestedActivity = () => {
    this.setState({
      suggestionsActivity: [],
    });
  };

  onSuggestionSelectedActivity = () => {
    this.setState({
      showResultsActivity: false,
    });
  };

  getSuggestionValueActivity = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  renderSuggestionActivity = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImage">
            <img
              src={suggestion.LocationImage}
              className="locationImageContent"
            />
          </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  renderSuggestionsContainerActivity = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  toggleDropdown = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ dropdownVisible: !prevState.dropdownVisible }),
      () => {
        if (this.state.dropdownVisible) {
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );

    scrollToRef(this.myDivToFocus);
  };

  showZonelistdivActivity = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsActivity: !prevState.showResultsActivity }),
      () => {
        if (this.state.showResultsActivity) {
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  getAmount = (data, price) => {
    let result = [];

    if (data.combineoffer == "nocombine") {
      if (data.specialdata.discount_id == 2) {
        const disA = data.specialdata.rate_amount;
        result.push(+disA);
      } else {
        const disA = price - data.specialdata.rate_amount;
        result.push(+disA);
      }
    } else {
      if (data.specialdata.discount_id == 2) {
        const disA = data.specialdata.rate_amount;
        result.push(+disA);
      } else {
        const disA = price - data.specialdata.rate_amount;
        result.push(+disA);
      }
    }

    const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

    const balanceAmount = price - (Math.round(Amount * price) / 100).toFixed(2);

    return (
      <>
       <div style={{display:"flex"}}>
        <p
          className="grayText font16 lineThrough appendBottom5"
          id="hlistpg_hotel_cut_price"
        >
          <span>€</span>
          {price.toFixed(2)}
        </p>
        <p
          className="latoBlack font16 blackText appendBottom5 appendLeft5"
          id="hlistpg_hotel_shown_price"
        >
          <span>€</span>
          {balanceAmount.toFixed(2)}
        </p>
        </div> 
        <p
          class="hrtlCenter appendBottom10 directionRtl"
        >
          <span class="redText font12">
            <span>Save </span>
            <span class="latoBold" id="hlistpg_hotel_saved_price">
              <span>€</span>
              {((Amount * price) / 100).toFixed(2)}
            </span>
          </span>
          <span class="spriteNew discountIcon relative appendLeft5">
            <span
              class="latoBold font11 whiteText discountText"
              id="hlistpg_hotel_saved_price_percentage"
              style={{
                lineHeight: "15px",
                paddingRight: "5px",
              }}
            >
              {parseInt(Amount)}%
            </span>
          </span>
        </p>
      </>
    );
  };

  onChangeActivity = (event, { newValue }) => {
    this.setState({
      value: newValue,
      defaultZonevalActivity: newValue,
    });
  };

  handleActivityDayClick = (day) => {
    this.setState({ selectActivityDayNew: day });
    this.setState({
      selectActivityDay: moment(day).format("dddd, MMM D, YYYY"),
    });
    this.setState({ checkinShowActivity: false });
  };
  handleActivityDayClickMain = (day) => {
    this.setState({ selectActivityDayNew: day });
    this.setState({
      selectActivityDay: moment(day).format("dddd, MMM D, YYYY"),
    });
    this.setState({ checkinShowActivityMain: false });
  };

  handleScroll = () => {
    if (window.pageYOffset > 10) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  CheckAvailability = () => {
    this.setState({ show_options: true });
    this.setState({ tour_options: null });
    this.setState({ checkName: "Update Search" });

    this.setState({ selectedIndex: null });
    this.setState({ selectedIndexNew: null });
    this.setState({ buttonDisabled: "disabled" });
   // if (this.state.numberTraveler == "Number of travelers") {
      let adultTraveler = this.state.cats.reduce(
        (a, v) => a + v.adultCounter * 1,
        0
      );
      let infantTraveler = this.state.cats.reduce(
        (a, v) => a + v.infantCounter * 1,
        0
      );
      let childTraveler = this.state.cats.reduce(
        (a, v) => a + v.childCounter * 1,
        0
      );
      let youthTraveler = this.state.cats.reduce(
        (a, v) => a + v.youthCounter * 1,
        0
      );
      let seniorTraveler = this.state.cats.reduce(
        (a, v) => a + v.seniorCounter * 1,
        0
      );
      let GroupTraveler = this.state.cats.reduce(
        (a, v) => a + v.groupCounter * 1,
        0
      );

      let newString = "";
      if (this.state.tour_price.price_options.adult == 1) {
        if (adultTraveler == 1) {
          newString = adultTraveler + " adult";
        } else {
          newString = adultTraveler + " adults";
        }
      }

      if (this.state.tour_price.price_options.child == 1) {
        if (childTraveler > 0) {
          if (childTraveler == 1) {
            newString = newString + ", " + childTraveler + " child";
          } else {
            newString = newString + ", " + childTraveler + "children";
          }
        }
      }

      if (this.state.tour_price.price_options.infant == 1) {
        if (infantTraveler > 0) {
          if (infantTraveler == 1) {
            newString = newString + ", " + infantTraveler + " infant";
          } else {
            newString = newString + ", " + infantTraveler + " infants";
          }
        }
      }

      if (this.state.tour_price.price_options.youth == 1) {
        if (youthTraveler > 0) {
          if (youthTraveler == 1) {
            newString = newString + ", " + youthTraveler + " youth";
          } else {
            newString = newString + ", " + youthTraveler + " youths";
          }
        }
      }

      if (this.state.tour_price.price_options.senior == 1) {
        if (seniorTraveler > 0) {
          if (seniorTraveler == 1) {
            newString = newString + seniorTraveler + " senior";
          } else {
            newString = newString + seniorTraveler + " seniors";
          }
        }
      }

      if(this.state.tour_price.price_options.price_plan == 2){
        if (this.state.tour_price.price_options.max_booking_travelers_free> 0) {
          newString = GroupTraveler + " free travelers";
        }
      }else{
        if (this.state.tour_price.tour_price_data.group_max > 0) {
          newString = GroupTraveler + " travelers";
        }
      }

      this.setState({ numberTraveler: newString, dropdownVisible: true });
      scrollToRef(this.myDivToFocus);
   // }
    let numberTravelers = this.state.cats;
    let checkDate = moment(this.state.selectActivityDayNew).format("Y-MM-DD");
    let tour_id = this.state.tour_data.id;
    let price_type = this.state.tour_price.price_options.price_type;

    CheckAvailabilityData(numberTravelers, checkDate, tour_id, price_type).then(
      (data) => {
        this.setState({ tour_options: data });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  setTimeSelected = (item, index, j) => {
    this.setState({ selectedIndex: j + "_" + index });
    this.setState({ selectedIndexNew: j });
    this.setState({ selectedTime: index + 1 });
    this.setState({ selectedTimeValue: item.from_time });
    //this.setState({ buttonDisabled: '' });
  };

  render() {
    const menuclassName = `panel-dropdown${
      this.state.dropdownVisible ? " active" : ""
    }`;
    const { value, suggestionsActivity } = this.state;

    const inputPropsActivity = {
      placeholder: "Enter Location or activity name",
      type: "search",
      value,
      onChange: this.onChangeActivity,
    };

    //Cancellation Policy

    let CancellationModal = (data) => {
      let DataNew = "";
      let Value = "";
      let EuroIcon = "";
      let PerIcon = "";
      let ContentData = "";
      return (
        <>
          {" "}
          {data
            .slice(0)
            .reverse()
            .map((i, index) => {
              //const date = new Date();
              const selectActivityDay = this.state.selectActivityDay;
              const date = new Date(selectActivityDay);
              if (i.day_before_arrival == 1 || i.day_before_arrival == 2) {
                date.setDate(date.getDate() - i.day_before_arrival);
                DataNew = moment(date).format("Do MMMM YYYY");
              }
              {
                date.setDate(date.getDate() - (i.day_before_arrival - 2));
                // DataNew = moment(date).format("Do MMMM YYYY");
                DataNew =
                  "If cancelled or modified " +
                  parseInt(i.day_before_arrival - 2) +
                  " days before date of experience (tour, event, attraction, activity).";
              }

              if (i.penalty == "No Penalty") {
                Value = 0;
                EuroIcon = "";
                PerIcon = "";
              } else if (i.penalty == "Full Amount") {
                Value = "";
                EuroIcon = "";
                PerIcon = "Full booking amount will be charged";
              } else if (i.penalty == "Percentage") {
                Value = i.cancellation_value;
                EuroIcon = "";
                PerIcon = "% of booking amount will be charged ";
              } else if (i.penalty == "Nights") {
                Value = i.cancellation_value;
                EuroIcon = EuroIcon = <span>€</span>;
                PerIcon = " amount will be charged.";
              } else if (i.penalty == "Fixed Amount") {
                Value = i.cancellation_value;
                EuroIcon = <span>€</span>;
                PerIcon = " amount will be charged.";
              }

              if (i.day_before_arrival == 1) {
                DataNew = "In case of no show";
                //ContentData = "In case of "
              }

              //   if (id == i.rate_plan_id) {
              return (
                <div className="cncpolicyTbl__row">
                  <div className="cncpolicyTbl__col">
                    {index == 0 ? "" : ""} &#x202A;{DataNew}&#x202C;
                  </div>
                  <div className="cncpolicyTbl__col">
                    {EuroIcon} {Value}
                    {PerIcon}
                  </div>
                </div>
              );
              //   }
            })}
        </>
      );
    };

    let getDateModal = (data) => {
      let DataNew = "";

      const selectActivityDay = this.state.selectActivityDay;

      if (data[0].cancellation_penalty_id == 1) {
        const date = new Date(selectActivityDay);
        // date.setDate(date.getDate() - data[0].day_before_arrival);

        DataNew =
          "Free Cancellation (100% refund) if you cancel this booking on or before start of experience (tour, event, attraction, activity). ";
        // +moment(date).format("Do MMMM YYYY");
      } else if (data[0].cancellation_penalty_id == 3) {
        DataNew =
          "Minimum " +
          data[0].cancellation_value +
          "% cancellation charges applicable";
      }

      return DataNew;
    };

    
    // const PopupCartData = () => {
    //   return (
    //     <>
    //       <div className="slider-container">
    //         <div
    //           className="sliderHeader"
    //           style={{ backgroundColor: "#326391 !important" }}
    //         >
    //           Cart
    //         </div>
    //         <div
    //           className="sliderBody"
    //           style={{ overflowX: "hidden", overflowY: "auto" }}
    //         >
    //           <CartPopup
    //             showcart={this.props.showcart.cardItems.cardData}
    //           ></CartPopup>
    //         </div>
    //         <div className="sliderFooter">
    //           {/* <button className="btn btn-primary"
    //                         onClick={() => this.state.(slideShow: false)}>Cancel</button> */}
    //         </div>
    //       </div>
    //     </>
    //   );
    // };

    return (
      <div className="Detail-container">
        <Helmet>
          <title>
            Book Best Attractions & Things to Do in seychelles- Holidays
            Seychelles
          </title>
          <meta
            name="description"
            content="Holiday Seychelles is the best place for honeymoon. Book best attractions now."
          />
          <meta
            name="keywords"
            content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"
          />
        </Helmet>
        <h1 style={{ color: "#fff" }}>
          Book Best Attractions & Things to Do in seychelles- Holidays
          Seychelles
        </h1>
        <NewHeader page={"inner"} tabName={2} />
        <div
          className={`${
            this.state.nav == true ? "_Hlisting_header_new" : "_Hlisting_header"
          }`}
        >
         <div className="container hsw">
            <div className="hsw_inner">
              <div
                className="hsw_inputBoxActivity  "
                onClick={this.showZonelistdivActivity}
              >
                <label
                  for="city"
                  className="lbl_input latoBold font12 blueText"
                >
                  Location or activity name
                </label>
                <p
                  className="hsw_inputField font16 whiteText"
                  style={{ marginBottom: "3px" }}
                >
                  {this.state.defaultZonevalActivity.length >= 35
                    ? `${this.state.defaultZonevalActivity.substring(0, 35)}...`
                    : this.state.defaultZonevalActivity}
                </p>
                <div className="focused_div">
                  {this.state.showResultsActivity ? (
                    <div
                      className="autocomplete-wrapper"
                      onClick={this.handleBodyClick}
                    >
                      <Autosuggest
                        suggestions={suggestionsActivity}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedActivity
                        }
                        onSuggestionSelected={this.onSuggestionSelectedActivity}
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequestedActivity
                        }
                        getSuggestionValue={this.getSuggestionValueActivity}
                        renderSuggestionsContainer={
                          this.renderSuggestionsContainerActivity
                        }
                        renderSuggestion={this.renderSuggestionActivity}
                        inputProps={inputPropsActivity}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                  className="hsw_inputBoxActivity"
                  onClick={this.checkinandcheckoutActivityMain}
                >
                  <span className="checkinandcheckout">
                    <label
                      for="checkin"
                      className="lbl_input latoBold font12 blueText capText"
                    >
                      Add when you want to go
                    </label>
                  </span>
                  <p className="hsw_inputField font16 whiteText">
                    {this.state.selectActivityDay}
                  </p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowActivityMain && (
                      <DayPicker
                        className="activityDate"
                        onDayClick={this.handleActivityDayClickMain}
                        minDate={new Date()}
                        selectedDays={this.state.selectActivityDayNew}
                        disabledDays={{ before: new Date() }}
                      />
                    )}
                  </div>
                </div>

              <span
                className="primaryBtn hsw_searchButton"
                onClick={this.SearchListing}
              >
                Search
              </span>
            </div>
          </div>
        </div>

        {this.state.tour_data == "" ? (
          <HotelDetailSkeleton />
        ) : (
          <>
            {this.state.tour_price == "" || this.state.tour_price.price_options == "no_priceoption" ? (
              <>
                <div
                  className="Container-Tile-NoHotel"
                  style={{
                    height: " 350px",
                    width: "50%",
                    display: "contents",
                  }}
                >
                  <img
                    src={"images/no_transfer.png"}
                    style={{ margin: "0 auto", height: 250 }}
                  />
                  <h3
                    style={{
                      margin: "0 auto",
                      color: "#000",
                      fontSize: "32px",
                      fontWeight: "700",
                    }}
                  >
                    Sold Out!
                  </h3>
                  <p
                    class="font16 grayText appendTop10 appendBottom20"
                    style={{ margin: "8px auto" }}
                  >
                    {" "}
                    Sorry, all tours in area are sold out. Please change your
                    dates/ search criteria.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div
                  className="container"
                  style={{ marginTop: 40, maxWidth: "1210px" }}
                >
                  <div className="DetailPageTitle">
                    <h4 className="title_dt">
                      {this.state.tour_data.tour_name}
                    </h4>
                  </div>

                  <div
                    className="GalleryHeader"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "65%" }}>
                      <ImageGallery
                        items={this.state.tour_gallery}
                        thumbnailPosition="right"
                        showFullscreenButton={false}
                        showIndex={true}
                        showPlayButton={false}
                      />
                    </div>

                    <div style={{ width: "35%", marginLeft: 10 }}>
                      <div className="check-availability-wrapper">
                        <div className="text-left">
                          <div className="mb-1 from-price-container">
                            <p className="h2 mb-0 text-dark from-price">
                              <span className="font-weight-bold text-lowercase from-price-text">
                                From{" "}
                              </span>
                              <span className="font-weight-normal price-font">
                                {/* <FontAwesomeIcon
                              icon={faEuroSign}
                              color="#000"
                              style={{ fontSize: "20px" }}
                            ></FontAwesomeIcon> */}

                                {this.state.tour_price != "" &&
                                this.state.tour_price.specialOffer !=
                                  "no_offer" ? (
                                  <>
                                    {this.getAmount(
                                      this.state.tour_price.specialOffer,
                                      this.state.tour_price.tour_price
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <p
                                      className="latoBlack font16 blackText appendBottom5"
                                      id="hlistpg_hotel_shown_price"
                                    >
                                      <span>€</span>
                                      {this.state?.tour_price?.tour_price?.toFixed(2)}
                                    </p>
                                  </>
                                )}
                              </span>
                            </p>
                            <p className="blackText appendBottom5">
                            {this.state.tour_price != "" &&
                               this.state.tour_price.tour_price_data.price_plan_id == 2 ?(<>Free</>):(
                              this.state.tour_price.tour_price_data
                                .price_type_id == 1 ? (
                                <>Per Person</>
                              ) : (
                                <>
                                  Per Group (up to{" "}
                                  {this.state.tour_price.tour_price_data !=
                                    null &&
                                    this.state.tour_price.tour_price_data
                                      .group_max}
                                  )
                                </>
                              ))}{" "}
                              {this.state.tour_price != "" &&
                              this.state.tour_price.tour_price_data
                                .flexible_id == 1 ? (
                                <>or Flexible rates</>
                              ) : (
                                <></>
                              )}
                            </p>
                          </div>
                        </div>
                        <h3 className="font-weight-medium product-count-text">
                          Select Date and Travelers
                        </h3>

                        <div
                          className="dayPick"
                          onClick={this.checkinandcheckoutActivity}
                        >
                          <FontAwesomeIcon
                            icon={faCalendarCheck}
                            color="#000"
                            className="dayPickicon"
                          ></FontAwesomeIcon>
                          <p>{this.state.selectActivityDay}</p>
                        </div>
                        <div onClick={this.handleBodyClick}>
                          {this.state.checkinShowActivity && (
                            <DayPicker
                              className="activityDetailsDate"
                              onDayClick={this.handleActivityDayClick}
                              selectedDays={this.state.selectActivityDayNew}
                              disabledDays={{ before: new Date() }}
                            />
                          )}
                        </div>

                        <div className="dayPick" 
                        onClick={this.toggleDropdown}
                        >
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            color="#000"
                            className="dayPickicon"
                          ></FontAwesomeIcon>
                          <p>{this.state.numberTraveler}</p>
                        </div>
                        <div className={menuclassName} ref={this.myDivToFocus}>
                          {this.state.dropdownVisible &&
                            this.renderDropdownMenu()}
                        </div>

                        <div className="apply-button-changes-feature">
                          <button
                            className="btn btn-primary btn-block text-white check-avail-button"
                            onClick={this.CheckAvailability}
                          >
                            {this.state.checkName}
                          </button>
                        </div>
                          { this.state.tour_free_cancellation_policy != null &&
                       this.state.tour_free_cancellation_policy.map(
                        (item, index) => {
                         let DataNew = "";
                          if (item.cancellation_penalty_id == 1) { 
                            const selectActivityDay = this.state.selectActivityDay;
                            const date = new Date(selectActivityDay);
                            if (item.day_before_arrival == 1 || item.day_before_arrival == 2) {
                              date.setDate(date.getDate() - item.day_before_arrival);
                              DataNew = moment(date).format("Do MMMM YYYY");
                            }
                            {
                              date.setDate(date.getDate() - (item.day_before_arrival - 2));
                               DataNew = moment(date).format("Do MMMM YYYY");
                            }
                            let CurrentDate = new Date();
                            let  GivenDate = new Date(date);
                             if(GivenDate > CurrentDate){
                            return ( 
                              <div className="freeCancellationDetails">
                                  Free Cancellation Until {DataNew} (local time)
                              </div>
                            );
                            }
                          } 
                        }
                      )}
                      </div>

                    </div>
                  </div>
                </div>

                {this.state.show_options == true ? (
                  <>
                    {this.state.tour_options == null ? (
                      <div className="tour-grade-loading-wrapper container">
                        <div className="loading-wrapper mb-3">
                          <div className="loading-message px-lg-3 py-3">
                            Loading available options...
                          </div>
                          <Skeleton
                            style={{
                              width: "95%",
                              height: 20,
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                          />
                          <Skeleton
                            style={{
                              width: "90%",
                              height: 20,
                              marginBottom: 10,
                            }}
                          />
                          <Skeleton
                            style={{
                              width: "80%",
                              height: 20,
                              marginBottom: 10,
                            }}
                          />
                          <Skeleton
                            style={{
                              width: "70%",
                              height: 20,
                              marginBottom: 10,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {this.state.tour_options.options != "no_priceoption" ? (
                          <>
                            {this.state.tour_options.options.map((i, j) => {
                              if (i != "no_priceoption") {
                                if (i != null) {
                                  return (
                                    <div className="pax-container d-flex container px-0 flex-column flex-lg-row">
                                      <div className="tg-container-left d-flex flex-column align-items-start p-lg-4 px-3 pt-3 pb-4  long-button ">
                                        {this.state.tour_options.options
                                          .length > 1 && (
                                          <div class="d-flex justify-content-start">
                                            <div class="option-pill py-1 mr-2 my-1 font-weight-lighter align-self-end  text-nowrap rounded text-center">
                                              Option {j + 1}
                                            </div>
                                          </div>
                                        )}

                                        <div className="px-0">
                                          <label
                                            for="rb0"
                                            className="mb-0 w-100 enabled"
                                          >
                                            <div className="tour-grade-title h3 mb-0 font-weight-medium tg-title">
                                              {i.option_name}
                                            </div>
                                          </label>
                                        </div>
                                        <div className="tour-option-details w-100">
                                          <div
                                            className="description-wrapper d-flex py-3 ie-overflow-fix"
                                            data-description-preview-length="300"
                                          >
                                            {/* <div className="tg-description">
                                  <div>
                                    {ReactHtmlParser(
                                      `${i.tour_overview.substring(0, 300)}...`
                                    )}
                                  </div>
                                </div> */}
                                          </div>
                                          <div className="start-time-section">
                                            <div className="tg-start-times">
                                              <div className="start-times pl-sm-2 pl-lg-0 d-flex justify-content-start flex-wrap ie-overflow-fix content-show">
                                                {i.timing == "unavailable" && (
                                                  <div className="start-time rounded  text-center text-nowrap available selected">
                                                    No Availability
                                                  </div>
                                                )}
                                                {i.timing != "unavailable" &&
                                                  i.timing != null &&
                                                  i.timing.map(
                                                    (item, index) => {
                                                      return i.timing != null &&
                                                        i.timing.length > 1 ? (
                                                        <>
                                                          <div
                                                            className={`start-time rounded  text-center text-nowrap available ${
                                                              j + "_" + index ==
                                                                this.state
                                                                  .selectedIndex &&
                                                              "selected"
                                                            }`}
                                                            onClick={() =>
                                                              this.setTimeSelected(
                                                                item,
                                                                index,
                                                                j
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {item.from_time}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div
                                                          className="start-time rounded  text-center text-nowrap available selected"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {item.from_time}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                {i.timing != "unavailable" &&
                                                  i.timing == null && (
                                                    <div className="start-time rounded  text-center text-nowrap available selected">
                                                      Coordinated Times
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="book-section-separator d-none"></div>
                                      <div className="book-section py-4 px-3 flex-grow-1  available  long-button ">
                                        <div className="add-to-cart-section h-100 clearfix">
                                          <div
                                            id="add-to-cart-0"
                                            className="add-to-cart-container d-flex flex-wrap-reverse h-100 rnpl"
                                          >
                                            <div
                                              className="cart-option-buttons"
                                              style={{ marginTop: 10 }}
                                            >
                                              <div
                                                className="add-to-cart-wrapper float-right d-flex flex-column my-1 justify-content-end "
                                                style={{ width: "85%" }}
                                              >
                                                <div
                                                  className="no-time-selected-popover p-0"
                                                  tabindex="0"
                                                >
                                                  {i.timing != null &&
                                                  i.timing.length > 1 ? (
                                                    j ==
                                                    this.state
                                                      .selectedIndexNew ? (
                                                      <a
                                                        className="btn btn-primary add-to-cart w-100"
                                                        style={{
                                                          color: "#fff",
                                                        }}
                                                        href={
                                                          "/paymentWallTour?tourid=" +
                                                          Base64.btoa(i.tour_id) +
                                                          "&dateSelected=" +
                                                          encodeURIComponent(
                                                            this.state.selectActivityDayNew
                                                          ) +
                                                          "&tourImage=" +
                                                          encodeURIComponent(
                                                            this.state.single_tour_gallery
                                                          ) +
                                                          "&number=" +
                                                          Base64.btoa(
                                                            this.state.selectedTime
                                                          ) +
                                                          "&ratePlanId=" +
                                                          Base64.btoa(i.id) +
                                                          "&numberTraveler=" +
                                                          JSON.stringify(this.state.cats) +
                                                          "&tourName=" +
                                                          encodeURIComponent(
                                                            this.state.tour_data.tour_name
                                                          ) +
                                                          "&tourOption=" +
                                                          encodeURIComponent(
                                                            i.option_name
                                                          ) +

                                                          "&tourLocation=" +
                                                          encodeURIComponent(
                                                            this.state.tour_data
                                                              .tour_location
                                                          ) +
                                                          "&time=" +
                                                          encodeURIComponent(
                                                            this.state.selectedTimeValue
                                                          ) +
                                                          "&price_type=" +
                                                          encodeURIComponent(
                                                            this.state.tour_price
                                                              .price_options.price_type
                                                          ) +
                                                          "&tour_operator_id=" +
                                                          encodeURIComponent(
                                                            this.state.tour_data
                                                              .tour_operator_id
                                                          )
                                                        }

                                                      
                                                        
                                                      >
                                                         Book Now
                                                      </a>
                                                    ) : (
                                                      <div
                                                        className="btn btn-primary add-to-cart w-100 disabled"
                                                        data-tip="true"
                                                        data-for={i.id}
                                                        currentitem="false"
                                                      >
                                                        <div className="button-text">
                                                        Book Now
                                                        </div>
                                                        <div className="loading-bubbles primary d-none">
                                                          <span></span>
                                                          <span></span>
                                                          <span></span>
                                                        </div>
                                                        <ReactTooltip
                                                          id={i.id}
                                                          place="top"
                                                          type="dark"
                                                        >
                                                          <span>
                                                            Select a time to
                                                            book{" "}
                                                          </span>
                                                        </ReactTooltip>
                                                      </div>
                                                    )
                                                  ) : (
                                                    <a
                                                      className="btn btn-primary add-to-cart w-100"
                                                      style={{ color: "#fff" }}
                                                      href={
                                                        "/paymentWallTour?tourid=" +
                                                        Base64.btoa(i.tour_id) +
                                                        "&dateSelected=" +
                                                        encodeURIComponent(
                                                          this.state.selectActivityDayNew
                                                        ) +
                                                        "&tourImage=" +
                                                        encodeURIComponent(
                                                          this.state.single_tour_gallery
                                                        ) +
                                                        "&number=" +
                                                        Base64.btoa(
                                                          this.state.selectedTime
                                                        ) +
                                                        "&ratePlanId=" +
                                                        Base64.btoa(i.id) +
                                                        "&numberTraveler=" +
                                                        JSON.stringify(this.state.cats) +
                                                        "&tourName=" +
                                                        encodeURIComponent(
                                                          this.state.tour_data.tour_name
                                                        ) +
                                                        "&tourOption=" +
                                                        encodeURIComponent(
                                                          i.option_name
                                                        ) +
                                                        "&tourLocation=" +
                                                        encodeURIComponent(
                                                          this.state.tour_data.tour_location
                                                        ) +
                                                        "&time=" +
                                                        encodeURIComponent(
                                                          i.timing == null ? null : i.timing[0].from_time
                                                        ) +
                                                        "&price_type=" +
                                                        encodeURIComponent(
                                                          this.state.tour_price
                                                            .price_options.price_type
                                                        ) +
                                                        "&tour_operator_id=" +
                                                        encodeURIComponent(
                                                          this.state.tour_data
                                                            .tour_operator_id
                                                        )
                                                      }

                                                    >
                                                      Book Now
                                                    </a>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            {i.timing != "unavailable" && (
                                              <div className="price-total d-flex flex-row flex-lg-column w-100 align-items-lg-end justify-content-between  ">
                                                <span
                                                  className="font-weight-bold price-display"
                                                  data-automation="price-display"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEuroSign}
                                                    color="#000"
                                                    style={{ fontSize: "13px" }}
                                                  ></FontAwesomeIcon>{" "}
                                                  {i.price[0].map(
                                                    (item, index) => {
                                                      if (
                                                        item.group_price > 0
                                                      ) {
                                                        if (
                                                          item.flexible_id == 0
                                                        ) {
                                                          let GroupTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.groupCounter *
                                                                1,
                                                            0
                                                          );

                                                          if (
                                                            GroupTraveler <=
                                                            item.group_max
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.group_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.group_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);
                                                              const $UpliftAmountGroup =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.group_price;
                                                              const UpliftGroupAmount =
                                                                parseInt(
                                                                  item.group_price
                                                                ) +
                                                                $UpliftAmountGroup;

                                                              const balanceAmount =
                                                                UpliftGroupAmount -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupAmount
                                                                  ) / 100
                                                                ).toFixed(2);

                                                              return balanceAmount;
                                                            } else {
                                                              const $UpliftAmountGroup =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.group_price;
                                                              const UpliftGroupAmount =
                                                                parseInt(
                                                                  item.group_price
                                                                ) +
                                                                $UpliftAmountGroup;
                                                              return UpliftGroupAmount.toFixed(2);
                                                              //return item.group_price;
                                                            }
                                                          }
                                                        } else {
                                                          let GroupTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.groupCounter *
                                                                1,
                                                            0
                                                          );

                                                          if (
                                                            GroupTraveler <=
                                                              item.group_max &&
                                                            GroupTraveler >=
                                                              item.group_min
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.group_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.group_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);
                                                              const $UpliftAmountGroup =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.group_price;
                                                              const UpliftGroupAmount =
                                                                parseInt(
                                                                  item.group_price
                                                                ) +
                                                                $UpliftAmountGroup;

                                                              const balanceAmount =
                                                                UpliftGroupAmount -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupAmount
                                                                  ) / 100
                                                                ).toFixed(2);

                                                              return balanceAmount.toFixed(2);
                                                            } else {
                                                              const $UpliftAmountGroup =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.group_price;
                                                              const UpliftGroupAmount =
                                                                parseInt(
                                                                  item.group_price
                                                                ) +
                                                                $UpliftAmountGroup;
                                                              return UpliftGroupAmount.toFixed(2);
                                                              //return item.group_price;
                                                            }
                                                          }
                                                        }
                                                      } else {
                                                        let total = "";

                                                        if (
                                                          item.flexible_id == 0
                                                        ) {
                                                          let adult_P = 0;
                                                          let adultTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.adultCounter *
                                                                1,
                                                            0
                                                          );
                                                          let infantTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.infantCounter *
                                                                1,
                                                            0
                                                          );
                                                          let childTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.childCounter *
                                                                1,
                                                            0
                                                          );
                                                          let youthTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.youthCounter *
                                                                1,
                                                            0
                                                          );
                                                          let seniorTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.seniorCounter *
                                                                1,
                                                            0
                                                          );

                                                          if (
                                                            item.adult_price > 0
                                                          ) {
                                                            adult_P =
                                                              item.adult_price *
                                                              adultTraveler;
                                                          }
                                                          let infant_P = 0;
                                                          if (
                                                            item.infant_price >
                                                            0
                                                          ) {
                                                            infant_P =
                                                              item.infant_price *
                                                              infantTraveler;
                                                          }
                                                          let child_P = 0;
                                                          if (
                                                            item.child_price > 0
                                                          ) {
                                                            child_P =
                                                              item.child_price *
                                                              childTraveler;
                                                          }
                                                          let youth_P = 0;
                                                          if (
                                                            item.youth_price > 0
                                                          ) {
                                                            youth_P =
                                                              item.youth_price *
                                                              youthTraveler;
                                                          }
                                                          let senior_P = 0;
                                                          if (
                                                            item.senior_price >
                                                            0
                                                          ) {
                                                            senior_P =
                                                              item.senior_price *
                                                              seniorTraveler;
                                                          }

                                                          total =
                                                            adult_P +
                                                            infant_P +
                                                            child_P +
                                                            youth_P +
                                                            senior_P;

                                                          if (
                                                            i.specialOffer !=
                                                            "no_offer"
                                                          ) {
                                                            let result = [];

                                                            i.specialOffer.map(
                                                              (l, j) => {
                                                                if (
                                                                  l.combineoffer ==
                                                                  "nocombine"
                                                                ) {
                                                                  if (
                                                                    l
                                                                      .specialdata
                                                                      .discount_id ==
                                                                    2
                                                                  ) {
                                                                    const disA =
                                                                      l
                                                                        .specialdata
                                                                        .rate_amount;
                                                                    result.push(
                                                                      +disA
                                                                    );
                                                                  } else {
                                                                    const disA =
                                                                      total -
                                                                      l
                                                                        .specialdata
                                                                        .rate_amount;
                                                                    result.push(
                                                                      +disA
                                                                    );
                                                                  }
                                                                } else {
                                                                  if (
                                                                    l
                                                                      .specialdata
                                                                      .discount_id ==
                                                                    2
                                                                  ) {
                                                                    const disA =
                                                                      l
                                                                        .specialdata
                                                                        .rate_amount;
                                                                    result.push(
                                                                      +disA
                                                                    );
                                                                  } else {
                                                                    const disA =
                                                                      total -
                                                                      l
                                                                        .specialdata
                                                                        .rate_amount;
                                                                    result.push(
                                                                      +disA
                                                                    );
                                                                  }
                                                                }
                                                              }
                                                            );

                                                            const Amount = result
                                                              .reduce(
                                                                (a, v) =>
                                                                  a + v * 1,
                                                                0
                                                              )
                                                              .toFixed(2);
                                                            const $UpliftAmountTotal =
                                                              (parseInt(
                                                                i.priceuplift[0]
                                                              ) /
                                                                100) *
                                                              total;
                                                            const UpliftGroupTotal =
                                                              parseInt(total) +
                                                              $UpliftAmountTotal;
                                                            const balanceAmount =
                                                              UpliftGroupTotal -
                                                              (
                                                                Math.round(
                                                                  Amount *
                                                                    UpliftGroupTotal
                                                                ) / 100
                                                              ).toFixed(2);

                                                            return balanceAmount.toFixed(2);
                                                          } else {
                                                            const $UpliftAmountTotal =
                                                              (parseInt(
                                                                i.priceuplift[0]
                                                              ) /
                                                                100) *
                                                              total;
                                                            const UpliftGroupAmount =
                                                              parseInt(total) +
                                                              $UpliftAmountTotal;
                                                            return UpliftGroupAmount.toFixed(2);
                                                            // return total;
                                                          }

                                                          // return total;
                                                        } else {
                                                          if (index == 0) {
                                                            let adultTraveler = this.state.cats.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.adultCounter *
                                                                  1,
                                                              0
                                                            );
                                                            let infantTraveler = this.state.cats.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.infantCounter *
                                                                  1,
                                                              0
                                                            );
                                                            let childTraveler = this.state.cats.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.childCounter *
                                                                  1,
                                                              0
                                                            );
                                                            let youthTraveler = this.state.cats.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.youthCounter *
                                                                  1,
                                                              0
                                                            );
                                                            let seniorTraveler = this.state.cats.reduce(
                                                              (a, v) =>
                                                                a +
                                                                v.seniorCounter *
                                                                  1,
                                                              0
                                                            );

                                                            let adult_P = 0;
                                                            if (
                                                              adultTraveler > 0
                                                            ) {
                                                              let AdultTo = i.price[0].filter(
                                                                (i) =>
                                                                  adultTraveler <=
                                                                    i.adult_max &&
                                                                  adultTraveler >=
                                                                    i.adult_min
                                                              );

                                                              if (
                                                                AdultTo.length >
                                                                0
                                                              ) {
                                                                adult_P =
                                                                  AdultTo[0]
                                                                    .adult_price *
                                                                  adultTraveler;
                                                              }
                                                            }
                                                            let infant_P = 0;
                                                            if (
                                                              infantTraveler > 0
                                                            ) {
                                                              let infantTo = i.price[0].filter(
                                                                (i) =>
                                                                  infantTraveler <=
                                                                    i.infant_max &&
                                                                  infantTraveler >=
                                                                    i.infant_min
                                                              );

                                                              if (
                                                                infantTo.length >
                                                                0
                                                              ) {
                                                                infant_P =
                                                                  infantTo[0]
                                                                    .infant_price *
                                                                  infantTraveler;
                                                              }
                                                            }
                                                            let child_P = 0;
                                                            if (
                                                              childTraveler > 0
                                                            ) {
                                                              let childTo = i.price[0].filter(
                                                                (i) =>
                                                                  childTraveler <=
                                                                    i.child_max &&
                                                                  childTraveler >=
                                                                    i.child_min
                                                              );

                                                              if (
                                                                childTo.length >
                                                                0
                                                              ) {
                                                                child_P =
                                                                  childTo[0]
                                                                    .child_price *
                                                                  childTraveler;
                                                              }
                                                            }
                                                            let youth_P = 0;
                                                            if (
                                                              youthTraveler > 0
                                                            ) {
                                                              let youthTo = i.price[0].filter(
                                                                (i) =>
                                                                  youthTraveler <=
                                                                    i.youth_max &&
                                                                  youthTraveler >=
                                                                    i.youth_min
                                                              );

                                                              if (
                                                                youthTo.length >
                                                                0
                                                              ) {
                                                                youth_P =
                                                                  youthTo[0]
                                                                    .youth_price *
                                                                  youthTraveler;
                                                              }
                                                            }

                                                            let senior_P = 0;
                                                            if (
                                                              seniorTraveler > 0
                                                            ) {
                                                              let seniorTo = i.price[0].filter(
                                                                (i) =>
                                                                  seniorTraveler <=
                                                                    i.senior_max &&
                                                                  seniorTraveler >=
                                                                    i.senior_min
                                                              );

                                                              if (
                                                                seniorTo.length >
                                                                0
                                                              ) {
                                                                senior_P =
                                                                  seniorTo[0]
                                                                    .senior_price *
                                                                  seniorTraveler;
                                                              }
                                                            }

                                                            total =
                                                              adult_P * 1 +
                                                              infant_P * 1 +
                                                              child_P * 1 +
                                                              youth_P * 1 +
                                                              senior_P * 1;

                                                            if (total != 0) {
                                                              if (
                                                                i.specialOffer !=
                                                                "no_offer"
                                                              ) {
                                                                let result = [];

                                                                i.specialOffer.map(
                                                                  (l, j) => {
                                                                    if (
                                                                      l.combineoffer ==
                                                                      "nocombine"
                                                                    ) {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          total -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    } else {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          total -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                );

                                                                const Amount = result
                                                                  .reduce(
                                                                    (a, v) =>
                                                                      a + v * 1,
                                                                    0
                                                                  )
                                                                  .toFixed(2);

                                                                const $UpliftAmountTotal =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  total;
                                                                const UpliftGroupTotal =
                                                                  parseInt(
                                                                    total
                                                                  ) +
                                                                  $UpliftAmountTotal;

                                                                const balanceAmount =
                                                                  UpliftGroupTotal -
                                                                  (
                                                                    Math.round(
                                                                      Amount *
                                                                        UpliftGroupTotal
                                                                    ) / 100
                                                                  ).toFixed(2);

                                                                return balanceAmount.toFixed(2);
                                                              } else {
                                                                const $UpliftAmountTotal =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  total;
                                                                const UpliftGroupTotal =
                                                                  parseInt(
                                                                    total
                                                                  ) +
                                                                  $UpliftAmountTotal;
                                                                return UpliftGroupTotal.toFixed(2);
                                                              }

                                                              //return total;
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  )}
                                                </span>{" "}
                                                <span
                                                  className="price-break-down-cta text-nowrap text-right"
                                                  data-automation="price-break-down-cta"
                                                >
                                                  {i.price[0].map(
                                                    (item, index) => {
                                                      if (
                                                        item.group_price > 0
                                                      ) {
                                                        if (
                                                          item.flexible_id == 0
                                                        ) {
                                                          let GroupTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.groupCounter *
                                                                1,
                                                            0
                                                          );

                                                          if (
                                                            GroupTraveler <=
                                                            item.group_max
                                                          ) {
                                                            return (
                                                              "per group (up to " +
                                                              item.group_max +
                                                              " )"
                                                            );
                                                          }
                                                        } else {
                                                          let GroupTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.groupCounter *
                                                                1,
                                                            0
                                                          );

                                                          if (
                                                            GroupTraveler <=
                                                              item.group_max &&
                                                            GroupTraveler >=
                                                              item.group_min
                                                          ) {
                                                            return (
                                                              "per group (up to " +
                                                              item.group_max +
                                                              " )"
                                                            );
                                                          }
                                                        }
                                                      } else {
                                                        let total = "";
                                                        if (
                                                          item.flexible_id == 0
                                                        ) {
                                                          let adult_P = "";
                                                          let adultTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.adultCounter *
                                                                1,
                                                            0
                                                          );
                                                          let infantTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.infantCounter *
                                                                1,
                                                            0
                                                          );
                                                          let childTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.childCounter *
                                                                1,
                                                            0
                                                          );
                                                          let youthTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.youthCounter *
                                                                1,
                                                            0
                                                          );
                                                          let seniorTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.seniorCounter *
                                                                1,
                                                            0
                                                          );
                                                          let adultprice = "";
                                                          if (
                                                            adultTraveler > 0
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.adult_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.adult_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);

                                                              const $UpliftAmountAdult =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.adult_price;
                                                              const UpliftGroupAdult =
                                                                parseInt(
                                                                  item.adult_price
                                                                ) +
                                                                $UpliftAmountAdult;
                                                              const balanceAmount =
                                                                UpliftGroupAdult -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupAdult
                                                                  ) / 100
                                                                ).toFixed(2);
                                                              adultprice = balanceAmount.toFixed(2);
                                                            } else {
                                                              const $UpliftAmountAdult =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.adult_price;
                                                              const UpliftGroupAdult =
                                                                parseInt(
                                                                  item.adult_price
                                                                ) +
                                                                $UpliftAmountAdult;
                                                              adultprice = UpliftGroupAdult.toFixed(2);
                                                            }

                                                            adultTraveler == 1
                                                              ? (adult_P =
                                                                  adultTraveler +
                                                                  " Adults x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  adultprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    adultprice *
                                                                    adultTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>")
                                                              : (adult_P =
                                                                  adultTraveler +
                                                                  " Adults x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  adultprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    adultprice *
                                                                    adultTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>");
                                                          }

                                                          let infant_P = "";
                                                          let infantprice = "";
                                                          if (
                                                            infantTraveler > 0
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.infant_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.infant_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);

                                                              const $UpliftAmountInfant =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.infant_price;
                                                              const UpliftGroupInfant =
                                                                parseInt(
                                                                  item.infant_price
                                                                ) +
                                                                $UpliftAmountInfant;
                                                              const balanceAmount =
                                                                UpliftGroupInfant -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupInfant
                                                                  ) / 100
                                                                ).toFixed(2);
                                                              infantprice = balanceAmount.toFixed(2);
                                                            } else {
                                                              const $UpliftAmountInfant =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.infant_price;
                                                              const UpliftGroupInfant =
                                                                parseInt(
                                                                  item.infant_price
                                                                ) +
                                                                $UpliftAmountInfant;
                                                              infantprice = UpliftGroupInfant.toFixed(2);
                                                            }
                                                            infantTraveler == 1
                                                              ? (infant_P =
                                                                  infantTraveler +
                                                                  " Infant x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  infantprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    infantprice *
                                                                    infantTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>")
                                                              : (infant_P =
                                                                  infantTraveler +
                                                                  " Infants x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  infantprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    infantprice *
                                                                    infantTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>");
                                                          }
                                                          let child_P = "";
                                                          let childprice = "";
                                                          if (
                                                            childTraveler > 0
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.child_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.child_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);
                                                              const $UpliftAmountChild =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.child_price;
                                                              const UpliftGroupChild =
                                                                parseInt(
                                                                  item.child_price
                                                                ) +
                                                                $UpliftAmountChild;
                                                              const balanceAmount =
                                                                UpliftGroupChild -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupChild
                                                                  ) / 100
                                                                ).toFixed(2);
                                                              childprice = balanceAmount.toFixed(2);
                                                            } else {
                                                              const $UpliftAmountChild =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.child_price;
                                                              const UpliftGroupChild =
                                                                parseInt(
                                                                  item.child_price
                                                                ) +
                                                                $UpliftAmountChild;
                                                              childprice = UpliftGroupChild.toFixed(2);
                                                            }
                                                            childTraveler == 1
                                                              ? (child_P =
                                                                  childTraveler +
                                                                  " Child x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  childprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    childprice *
                                                                    childTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>")
                                                              : (child_P =
                                                                  childTraveler +
                                                                  " Childs x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  childprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    childprice *
                                                                    childTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>");
                                                          }
                                                          let youth_P = "";
                                                          let youthprice = "";
                                                          if (
                                                            youthTraveler > 0
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.youth_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.youth_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);

                                                              const $UpliftAmountYouth =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.youth_price;
                                                              const UpliftGroupYouth =
                                                                parseInt(
                                                                  item.youth_price
                                                                ) +
                                                                $UpliftAmountYouth;
                                                              const balanceAmount =
                                                                UpliftGroupYouth -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupYouth
                                                                  ) / 100
                                                                ).toFixed(2);
                                                              youthprice = balanceAmount.toFixed(2);
                                                            } else {
                                                              const $UpliftAmountYouth =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.youth_price;
                                                              const UpliftGroupYouth =
                                                                parseInt(
                                                                  item.youth_price
                                                                ) +
                                                                $UpliftAmountYouth;
                                                              youthprice = UpliftGroupYouth.toFixed(2);
                                                            }
                                                            youthTraveler == 1
                                                              ? (youth_P =
                                                                  youthTraveler +
                                                                  " Youth x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  youthprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    youthprice *
                                                                    youthTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>")
                                                              : (youth_P =
                                                                  youthTraveler +
                                                                  " Youths x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  youthprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  (
                                                                    youthprice *
                                                                    youthTraveler
                                                                  ).toFixed(2) +
                                                                  "<br/>");
                                                          }
                                                          let senior_P = "";
                                                          let seniorprice = "";
                                                          if (
                                                            seniorTraveler > 0
                                                          ) {
                                                            if (
                                                              i.specialOffer !=
                                                              "no_offer"
                                                            ) {
                                                              let result = [];

                                                              i.specialOffer.map(
                                                                (l, j) => {
                                                                  if (
                                                                    l.combineoffer ==
                                                                    "nocombine"
                                                                  ) {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.senior_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  } else {
                                                                    if (
                                                                      l
                                                                        .specialdata
                                                                        .discount_id ==
                                                                      2
                                                                    ) {
                                                                      const disA =
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    } else {
                                                                      const disA =
                                                                        item.senior_price -
                                                                        l
                                                                          .specialdata
                                                                          .rate_amount;
                                                                      result.push(
                                                                        +disA
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              );

                                                              const Amount = result
                                                                .reduce(
                                                                  (a, v) =>
                                                                    a + v * 1,
                                                                  0
                                                                )
                                                                .toFixed(2);

                                                              const $UpliftAmountSenior =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.senior_price;
                                                              const UpliftGroupSenior =
                                                                parseInt(
                                                                  item.senior_price
                                                                ) +
                                                                $UpliftAmountSenior;
                                                              const balanceAmount =
                                                                UpliftGroupSenior -
                                                                (
                                                                  Math.round(
                                                                    Amount *
                                                                      UpliftGroupSenior
                                                                  ) / 100
                                                                ).toFixed(2);
                                                              seniorprice = balanceAmount.toFixed(2);
                                                            } else {
                                                              const $UpliftAmountSenior =
                                                                (parseInt(
                                                                  i
                                                                    .priceuplift[0]
                                                                ) /
                                                                  100) *
                                                                item.senior_price;
                                                              const UpliftGroupSenior =
                                                                parseInt(
                                                                  item.senior_price
                                                                ) +
                                                                $UpliftAmountSenior;
                                                              seniorprice = UpliftGroupSenior.toFixed(2);
                                                            }
                                                            seniorTraveler == 1
                                                              ? (senior_P =
                                                                  seniorTraveler +
                                                                  " Senior x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  seniorprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  seniorprice *
                                                                    seniorTraveler +
                                                                  "<br/>")
                                                              : (senior_P =
                                                                  seniorTraveler +
                                                                  " Seniors x " +
                                                                  " <img src='images/euro.png' />" +
                                                                  seniorprice +
                                                                  "=" +
                                                                  " <img src='images/euro.png' />" +
                                                                  seniorprice *
                                                                    seniorTraveler +
                                                                  "<br/>");
                                                          }

                                                          total = ReactHtmlParser(
                                                            adult_P +
                                                              infant_P +
                                                              child_P +
                                                              youth_P +
                                                              senior_P
                                                          );

                                                          return total;
                                                        } else {
                                                          let adult_P = "";
                                                          let adultTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.adultCounter *
                                                                1,
                                                            0
                                                          );
                                                          let infantTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.infantCounter *
                                                                1,
                                                            0
                                                          );
                                                          let childTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.childCounter *
                                                                1,
                                                            0
                                                          );
                                                          let youthTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.youthCounter *
                                                                1,
                                                            0
                                                          );
                                                          let seniorTraveler = this.state.cats.reduce(
                                                            (a, v) =>
                                                              a +
                                                              v.seniorCounter *
                                                                1,
                                                            0
                                                          );
                                                          let adultprice = "";
                                                          if (
                                                            adultTraveler <=
                                                              item.adult_max &&
                                                            adultTraveler >=
                                                              item.adult_min
                                                          ) {
                                                            if (
                                                              adultTraveler > 0
                                                            ) {
                                                              if (
                                                                i.specialOffer !=
                                                                "no_offer"
                                                              ) {
                                                                let result = [];

                                                                i.specialOffer.map(
                                                                  (l, j) => {
                                                                    if (
                                                                      l.combineoffer ==
                                                                      "nocombine"
                                                                    ) {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.adult_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    } else {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.adult_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                );

                                                                const Amount = result
                                                                  .reduce(
                                                                    (a, v) =>
                                                                      a + v * 1,
                                                                    0
                                                                  )
                                                                  .toFixed(2);
                                                                const $UpliftAmountAdult =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.adult_price;
                                                                const UpliftGroupAdult =
                                                                  parseInt(
                                                                    item.adult_price
                                                                  ) +
                                                                  $UpliftAmountAdult;
                                                                const balanceAmount =
                                                                  UpliftGroupAdult -
                                                                  (
                                                                    Math.round(
                                                                      Amount *
                                                                        UpliftGroupAdult
                                                                    ) / 100
                                                                  ).toFixed(2);
                                                                adultprice = balanceAmount.toFixed(2);
                                                              } else {
                                                                const $UpliftAmountAdult =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.adult_price;
                                                                const UpliftGroupAdult =
                                                                  parseInt(
                                                                    item.adult_price
                                                                  ) +
                                                                  $UpliftAmountAdult;
                                                                adultprice = UpliftGroupAdult.toFixed(2);
                                                              }
                                                              adultTraveler == 1
                                                                ? (adult_P =
                                                                    adultTraveler +
                                                                    " Adults x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    adultprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      adultprice *
                                                                      adultTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>")
                                                                : (adult_P =
                                                                    adultTraveler +
                                                                    " Adults x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    adultprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      adultprice *
                                                                      adultTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>");
                                                            }
                                                          }
                                                          let infant_P = "";
                                                          let infantprice = "";
                                                          if (
                                                            infantTraveler <=
                                                              item.infant_max &&
                                                            infantTraveler >=
                                                              item.infant_min
                                                          ) {
                                                            if (
                                                              infantTraveler > 0
                                                            ) {
                                                              if (
                                                                i.specialOffer !=
                                                                "no_offer"
                                                              ) {
                                                                let result = [];

                                                                i.specialOffer.map(
                                                                  (l, j) => {
                                                                    if (
                                                                      l.combineoffer ==
                                                                      "nocombine"
                                                                    ) {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.infant_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    } else {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.infant_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                );

                                                                const Amount = result
                                                                  .reduce(
                                                                    (a, v) =>
                                                                      a + v * 1,
                                                                    0
                                                                  )
                                                                  .toFixed(2);

                                                                const $UpliftAmountInfant =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.infant_price;
                                                                const UpliftGroupInfant =
                                                                  parseInt(
                                                                    item.infant_price
                                                                  ) +
                                                                  $UpliftAmountInfant;

                                                                const balanceAmount =
                                                                  UpliftGroupInfant -
                                                                  (
                                                                    Math.round(
                                                                      Amount *
                                                                        UpliftGroupInfant
                                                                    ) / 100
                                                                  ).toFixed(2);
                                                                infantprice = balanceAmount.toFixed(2);
                                                              } else {
                                                                const $UpliftAmountInfant =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.infant_price;
                                                                const UpliftGroupInfant =
                                                                  parseInt(
                                                                    item.infant_price
                                                                  ) +
                                                                  $UpliftAmountInfant;
                                                                infantprice = UpliftGroupInfant.toFixed(2);
                                                              }
                                                              infantTraveler ==
                                                              1
                                                                ? (infant_P =
                                                                    infantTraveler +
                                                                    " Infant x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    infantprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      infantprice *
                                                                      infantTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>")
                                                                : (infant_P =
                                                                    infantTraveler +
                                                                    " Infants x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    infantprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      infantprice *
                                                                      infantTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>");
                                                            }
                                                          }
                                                          let child_P = "";
                                                          let childprice = "";
                                                          if (
                                                            childTraveler <=
                                                              item.child_max &&
                                                            childTraveler >=
                                                              item.child_min
                                                          ) {
                                                            if (
                                                              childTraveler > 0
                                                            ) {
                                                              if (
                                                                i.specialOffer !=
                                                                "no_offer"
                                                              ) {
                                                                let result = [];

                                                                i.specialOffer.map(
                                                                  (l, j) => {
                                                                    if (
                                                                      l.combineoffer ==
                                                                      "nocombine"
                                                                    ) {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.child_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    } else {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.child_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                );

                                                                const Amount = result
                                                                  .reduce(
                                                                    (a, v) =>
                                                                      a + v * 1,
                                                                    0
                                                                  )
                                                                  .toFixed(2);
                                                                const $UpliftAmountChild =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.child_price;
                                                                const UpliftGroupChild =
                                                                  parseInt(
                                                                    item.child_price
                                                                  ) +
                                                                  $UpliftAmountChild;
                                                                const balanceAmount =
                                                                  UpliftGroupChild -
                                                                  (
                                                                    Math.round(
                                                                      Amount *
                                                                        UpliftGroupChild
                                                                    ) / 100
                                                                  ).toFixed(2);
                                                                childprice = balanceAmount.toFixed(2);
                                                              } else {
                                                                const $UpliftAmountChild =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.child_price;
                                                                const UpliftGroupChild =
                                                                  parseInt(
                                                                    item.child_price
                                                                  ) +
                                                                  $UpliftAmountChild;
                                                                childprice = UpliftGroupChild.toFixed(2);
                                                              }
                                                              childTraveler == 1
                                                                ? (child_P =
                                                                    childTraveler +
                                                                    " Child x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    childprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      childprice *
                                                                      childTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>")
                                                                : (child_P =
                                                                    childTraveler +
                                                                    " Childs x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    childprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      childprice *
                                                                      childTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>");
                                                            }
                                                          }
                                                          let youth_P = "";
                                                          let youthprice = "";
                                                          if (
                                                            youthTraveler <=
                                                              item.youth_max &&
                                                            youthTraveler >=
                                                              item.youth_min
                                                          ) {
                                                            if (
                                                              youthTraveler > 0
                                                            ) {
                                                              if (
                                                                i.specialOffer !=
                                                                "no_offer"
                                                              ) {
                                                                let result = [];

                                                                i.specialOffer.map(
                                                                  (l, j) => {
                                                                    if (
                                                                      l.combineoffer ==
                                                                      "nocombine"
                                                                    ) {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.youth_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    } else {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.youth_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                );

                                                                const Amount = result
                                                                  .reduce(
                                                                    (a, v) =>
                                                                      a + v * 1,
                                                                    0
                                                                  )
                                                                  .toFixed(2);

                                                                const $UpliftAmountYouth =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.youth_price;
                                                                const UpliftGroupYouth =
                                                                  parseInt(
                                                                    item.youth_price
                                                                  ) +
                                                                  $UpliftAmountYouth;
                                                                const balanceAmount =
                                                                  UpliftGroupYouth -
                                                                  (
                                                                    Math.round(
                                                                      Amount *
                                                                        UpliftGroupYouth
                                                                    ) / 100
                                                                  ).toFixed(2);
                                                                youthprice = balanceAmount.toFixed(2);
                                                              } else {
                                                                const $UpliftAmountYouth =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.youth_price;
                                                                const UpliftGroupYouth =
                                                                  parseInt(
                                                                    item.youth_price
                                                                  ) +
                                                                  $UpliftAmountYouth;
                                                                youthprice = UpliftGroupYouth.toFixed(2);
                                                              }
                                                              youthTraveler == 1
                                                                ? (youth_P =
                                                                    youthTraveler +
                                                                    " Youth x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    youthprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      youthprice *
                                                                      youthTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>")
                                                                : (youth_P =
                                                                    youthTraveler +
                                                                    " Youths x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    youthprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      youthprice *
                                                                      youthTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>");
                                                            }
                                                          }
                                                          let senior_P = "";
                                                          let seniorprice = "";
                                                          if (
                                                            seniorTraveler <=
                                                              item.senior_max &&
                                                            seniorTraveler >=
                                                              item.senior_min
                                                          ) {
                                                            if (
                                                              seniorTraveler > 0
                                                            ) {
                                                              if (
                                                                i.specialOffer !=
                                                                "no_offer"
                                                              ) {
                                                                let result = [];

                                                                i.specialOffer.map(
                                                                  (l, j) => {
                                                                    if (
                                                                      l.combineoffer ==
                                                                      "nocombine"
                                                                    ) {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.senior_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    } else {
                                                                      if (
                                                                        l
                                                                          .specialdata
                                                                          .discount_id ==
                                                                        2
                                                                      ) {
                                                                        const disA =
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      } else {
                                                                        const disA =
                                                                          item.senior_price -
                                                                          l
                                                                            .specialdata
                                                                            .rate_amount;
                                                                        result.push(
                                                                          +disA
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                );

                                                                const Amount = result
                                                                  .reduce(
                                                                    (a, v) =>
                                                                      a + v * 1,
                                                                    0
                                                                  )
                                                                  .toFixed(2);
                                                                const $UpliftAmountSenior =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.senior_price;
                                                                const UpliftGroupSenior =
                                                                  parseInt(
                                                                    item.senior_price
                                                                  ) +
                                                                  $UpliftAmountSenior;
                                                                const balanceAmount =
                                                                  UpliftGroupSenior -
                                                                  (
                                                                    Math.round(
                                                                      Amount *
                                                                        UpliftGroupSenior
                                                                    ) / 100
                                                                  ).toFixed(2);
                                                                seniorprice = balanceAmount.toFixed(2);
                                                              } else {
                                                                const $UpliftAmountSenior =
                                                                  (parseInt(
                                                                    i
                                                                      .priceuplift[0]
                                                                  ) /
                                                                    100) *
                                                                  item.senior_price;
                                                                const UpliftGroupSenior =
                                                                  parseInt(
                                                                    item.senior_price
                                                                  ) +
                                                                  $UpliftAmountSenior;
                                                                seniorprice = UpliftGroupSenior.toFixed(2);
                                                              }
                                                              seniorTraveler ==
                                                              1
                                                                ? (senior_P =
                                                                    seniorTraveler +
                                                                    " Senior x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    seniorprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      seniorprice *
                                                                      seniorTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>")
                                                                : (senior_P =
                                                                    seniorTraveler +
                                                                    " Seniors x " +
                                                                    " <img src='images/euro.png' />" +
                                                                    seniorprice +
                                                                    "=" +
                                                                    " <img src='images/euro.png' />" +
                                                                    (
                                                                      seniorprice *
                                                                      seniorTraveler
                                                                    ).toFixed(
                                                                      2
                                                                    ) +
                                                                    "<br/>");
                                                            }
                                                          }

                                                          total = ReactHtmlParser(
                                                            adult_P +
                                                              infant_P +
                                                              child_P +
                                                              youth_P +
                                                              senior_P
                                                          );

                                                          return total;
                                                        }
                                                      }
                                                    }
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              } else {
                                if (i == "no_priceoption") {
                                  return (
                                    <>
                                      <div className="pax-container d-flex container px-0 flex-column flex-lg-row">
                                        <div className="tg-container-left d-flex flex-column align-items-start p-lg-4 px-3 pt-3 pb-4  long-button ">
                                          <div className="tour-option-details w-100">
                                            <div
                                              className="description-wrapper d-flex py-3 ie-overflow-fix"
                                              data-description-preview-length="300"
                                            >
                                              <div
                                                className="tg-description"
                                                style={{
                                                  fontSize: 16,
                                                  fontWeight: 600,
                                                }}
                                              >
                                                <div class="noavailable">
                                                  <b>
                                                    The experience is not
                                                    available on this day.
                                                    Kindly select another day.
                                                  </b>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              }
                            })}
                          </>
                        ) : (
                          <>
                            <div className="pax-container d-flex container px-0 flex-column flex-lg-row">
                              <div className="tg-container-left d-flex flex-column align-items-start p-lg-4 px-3 pt-3 pb-4  long-button ">
                                <div className="tour-option-details w-100">
                                  <div
                                    className="description-wrapper d-flex py-3 ie-overflow-fix"
                                    data-description-preview-length="300"
                                  >
                                    <div className="tg-description">
                                      <div class="noavailable">
                                        The experience is not available on this
                                        day. Kindly select another day.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

              <div className="container"
                  style={{
                    marginTop: 30,
                    maxWidth: "1210px",
                    marginBottom: 30,
                  }}
                >
                  <div className="aboutProp">
                    <div className="aboutProp__desc">
                      <div className="lineHight20 aboutDesc">
                        <h4 className="txtHeading font22 latoBlack blackText textHeader">
                          Overview
                        </h4>
                        <div id="overview">
                          <section class="sectionWrapper">
                            {/* <h3 class="section-title">Overview</h3> */}
                            <div
                              class="description text-pre-wrap"
                              itemprop="description"
                            >
                              {ReactHtmlParser(
                                this.state.tour_description.tour_overview
                              )}
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="container"
                  style={{ maxWidth: "1210px", marginBottom: 30 }}
                >
                  <Collapsible
                    trigger="Itinerary and Activities  "
                    triggerClassName="txtHeading font22 latoBlack blackText textHeader"
                    triggerOpenedClassName="txtHeading font22 latoBlack blackText textHeader"
                    className="accordionCollapsed"
                  >
                    {this.state.tour_itinerary == null ? (
                      <div className="">
                        {/* <div style={{ marginTop: 20 }}></div> */}
                      </div>
                    ) : (
                      <>
                      {this.state.tour_itinerary.singledays_ite &&
                            this.state.tour_itinerary.singledays_ite
                              .single_day_itinerary != "no_itinerary" &&(
                      <div className="DayOneCard">
                        <div style={{ padding: 10 }}>
                          {this.state.tour_itinerary.singledays_ite &&
                            this.state.tour_itinerary.singledays_ite
                              .single_day_itinerary != "no_itinerary" &&
                            this.state.tour_itinerary.singledays_ite.single_day_itinerary[0].map(
                              (i, j) => {
                              
                                if (i.pass_without_id == 1) {
                                  return (
                                    <>
                                      <div
                                        className="sectionWrapper itineraryWrapper"
                                        key={i.day_number}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMapMarkedAlt}
                                            className="mettingPickupCheck"/>
                                          <h3 className="greenText latoBold font14 extraSpacing marginTop5">
                                            {i.itinerary_address}
                                          </h3>
                                        </div>
                                        <div className="description text-pre-wrap">
                                          {i.itinerary_description}
                                        </div>
                                        <h3 className="font14 extraSpacing itineraryDuration">
                                        Pass by Without Stopping
                                      </h3>
                                      </div>
                                      
                                    </>
                                  );
                                } else {
                                  let minu_from = "";
                                  let minu_to = "";
                                  let minu = "";
                                  let stopPlace = "";
                                  if (i.admission_fee_id == 0) {
                                    stopPlace = "Admission fee is included.";
                                  } else if (i.admission_fee_id == 1) {
                                    stopPlace =
                                      "Admission fee is not included.";
                                  } else if (i.admission_fee_id == 2) {
                                    stopPlace = "Admission fee is free.";
                                  }
                                  if (i.flexible_id == 1) {
                                    if (i.pass_minutes_from_non == 0) {
                                      minu_from = "minutes";
                                    } else {
                                      minu_from = "hours";
                                    }

                                    if (i.pass_minutes_to_non == 0) {
                                      minu_to = "minutes";
                                    } else {
                                      minu_to = "hours";
                                    }
                                    let pass_without_multiple =
                                      i.pass_hours_from_non +
                                      " " +
                                      minu_from +
                                      " to " +
                                      i.pass_hours_to_non +
                                      " " +
                                      minu_to;

                                    return (
                                      <>
                                        <div
                                          className="sectionWrapper itineraryWrapper"
                                          key={i.day_number}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMapMarkedAlt}
                                              className="mettingPickupCheck"/>
                                            <h3 className="greenText latoBold font14 extraSpacing marginTop5">
                                              {i.itinerary_address}
                                            </h3>
                                          </div>
                                          <div className="description text-pre-wrap">
                                            {i.itinerary_description}
                                          </div>
                                          {i.pass_hours_to_non != 0 && (
                                          <>
                                            <h3 className=" font14 extraSpacing itineraryDuration">
                                              Duration: {pass_without_multiple}, {stopPlace}
                                            </h3>
                                          </>
                                        )}

                                        {/* <div className="description text-pre-wrap">
                                          {i.otherdetails_non}
                                        </div>
                                        <h3 class="section-title latoBold font14 extraSpacing marginTopBottom5">
                                          {stopPlace}
                                        </h3> */}
                                        </div>
                                        
                                      </>
                                    );
                                  } else {
                                    if (i.pass_minutes == 0) {
                                      minu = "minutes";
                                    } else {
                                      minu = "hours";
                                    }

                                    let pass_without_multiple =
                                      i.pass_hours + " " + minu;

                                    return (
                                      <>
                                        <div
                                          className="sectionWrapper itineraryWrapper"
                                          key={i.day_number}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMapMarkedAlt}
                                              className="mettingPickupCheck"/>
                                            <h3 className="greenText latoBold font14 extraSpacing marginTop5">
                                              {i.itinerary_address}
                                            </h3>
                                          </div>
                                          <div className="description text-pre-wrap">
                                            {i.itinerary_description}
                                          </div>
                                          {i.pass_hours != 0 && (
                                          <>
                                            <h3 className="font14 extraSpacing itineraryDuration">
                                              Duration: {pass_without_multiple}, {stopPlace}
                                            </h3>
                                          </>
                                        )}

                                        {/* <h3 class="section-title latoBold font14 extraSpacing marginTopBottom5">
                                          {stopPlace}
                                        </h3> */}
                                        </div>
                                        
                                      </>
                                    );
                                  }
                                }
                              }
                            )}
                            </div>
                      </div>
                      )}
                          <div>
                            <div>
                          {this.state.tour_itinerary.multipledays &&
                            this.state.tour_itinerary.multipledays
                              .multiple_days != "no_multiple_days" &&
                            this.state.tour_itinerary.multipledays.multiple_days[0].map(
                              (i, j) => {
                                const number = j + 1;
                                return (
                                  <>
                                    <div
                                      class="accordion"
                                      id="accordionExample" style={{marginTop: '10px'}}
                                    >
                                      <div class="">
                                        <div
                                          class="itineraryDayHeader"
                                          id={`heading` + number}
                                        >
                                          <h3 className="latoBold font16 extraSpacing itineraryDayHeader">
                                            Day {j + 1}
                                            </h3>
                                              
                                              <h3 className="txtHeading font16 latoBlack blackText">
                                                {i.title}
                                              </h3>
                                            
                                          
                                        </div>

                                        <div
                                          id={`collapse` + number}
                                          class="collapse show"
                                          aria-labelledby={`"heading` + number}
                                          data-parent="#accordionExample" style={{marginTop: '10px'}}
                                        >
                                          <div class="card-body DayOneCard">
                                            <h3 className="greenText latoBold font14 extraSpacing">
                                              Itinerary
                                            </h3>

                                            {this.state.tour_itinerary
                                              .multipledays_ite &&
                                              this.state.tour_itinerary
                                                .multipledays_ite
                                                .multiple_days_itinerary !=
                                                "no_itinerary" &&
                                              this.state.tour_itinerary.multipledays_ite.multiple_days_itinerary[0].map(
                                                (it, k) => {
                                                  if (
                                                    it.day_number ==
                                                    i.day_number
                                                  ) {
                                                    if (
                                                      it.pass_without_id == 1
                                                    ) {
                                                      return (
                                                        <>
                                                          <div
                                                            className="sectionWrapper itineraryWrapper"
                                                            key={i.day_number}
                                                          >
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                  "row",
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={
                                                                  faMapMarkedAlt
                                                                }
                                                                className="mettingPickupCheck"/>
                                                              <p className="latoBold font14 extraSpacing marginTop5">
                                                                {
                                                                  it.itinerary_address
                                                                }
                                                              </p>
                                                            </div>
                                                            <div className="description text-pre-wrap">
                                                              {
                                                                it.itinerary_description
                                                              }
                                                            </div>
                                                            <h3 className="font14 extraSpacing itineraryDuration">
                                                            Pass by Without Stopping
                                                            </h3>
                                                          </div>
                                                         
                                                        </>
                                                      );
                                                    } else {
                                                      let minu_from = "";
                                                      let minu_to = "";
                                                      let minu = "";
                                                      let stopPlace = "";
                                                      if (
                                                        it.admission_fee_id == 0
                                                      ) {
                                                        stopPlace =
                                                          "Admission fee is included.";
                                                      } else if (
                                                        it.admission_fee_id == 1
                                                      ) {
                                                        stopPlace =
                                                          "Admission fee is not included.";
                                                      } else if (
                                                        it.admission_fee_id == 2
                                                      ) {
                                                        stopPlace =
                                                          "Admission fee is N/A(free).";
                                                      }
                                                      if (it.flexible_id == 1) {
                                                        if (
                                                          it.pass_minutes_from_non ==
                                                          0
                                                        ) {
                                                          minu_from = "minutes";
                                                        } else {
                                                          minu_from = "hours";
                                                        }

                                                        if (
                                                          it.pass_minutes_to_non ==
                                                          0
                                                        ) {
                                                          minu_to = "minutes";
                                                        } else {
                                                          minu_to = "hours";
                                                        }
                                                        let pass_without_multiple =
                                                          it.pass_hours_from_non +
                                                          " " +
                                                          minu_from +
                                                          " to " +
                                                          it.pass_hours_to_non +
                                                          " " +
                                                          minu_to;

                                                        return (
                                                          <>
                                                            <div
                                                              className="sectionWrapper itineraryWrapper"
                                                              key={i.day_number}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                }}
                                                              >
                                                                <FontAwesomeIcon
                                                                  icon={
                                                                    faMapMarkedAlt
                                                                  }
                                                                  className="mettingPickupCheck"
                                                                />
                                                                <p className="latoBold font14 extraSpacing marginTop5">
                                                                  {
                                                                    it.itinerary_address
                                                                  }
                                                                </p>
                                                              </div>
                                                              <div className="description text-pre-wrap">
                                                                {
                                                                  it.itinerary_description
                                                                }
                                                              </div>
                                                              <h3 className="font14 extraSpacing itineraryDuration">
                                                              Duration: {it.pass_hours_to_non !=
                                                              0 && ( pass_without_multiple )} {it.otherdetails_non},  {stopPlace}
                                                            </h3>
                                                            </div>
                                                            {/* {it.pass_hours_to_non !=
                                                              0 && (
                                                              <>
                                                                <h3 className="greenText latoBold font14 extraSpacing">
                                                                  Duration:{" "}
                                                                  {
                                                                    pass_without_multiple
                                                                  }
                                                                </h3>
                                                              </>
                                                            )}

                                                            <div className="description text-pre-wrap">
                                                              {
                                                                it.otherdetails_non
                                                              }
                                                            </div>
                                                            <h3 class="section-title latoBold font14 extraSpacing marginTopBottom5">
                                                              {stopPlace}
                                                            </h3> */}
                                                          </>
                                                        );
                                                      } else {
                                                        if (
                                                          it.pass_minutes == 0
                                                        ) {
                                                          minu = "minutes";
                                                        } else {
                                                          minu = "hours";
                                                        }

                                                        let pass_without_multiple =
                                                          it.pass_hours +
                                                          " " +
                                                          minu;

                                                        return (
                                                          <>
                                                            <div
                                                              className="sectionWrapper itineraryWrapper"
                                                              key={i.day_number}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                }}
                                                              >
                                                                <FontAwesomeIcon
                                                                  icon={
                                                                    faMapMarkedAlt
                                                                  }
                                                                  className="mettingPickupCheck"
                                                                />
                                                                <p className="latoBold font14 extraSpacing marginTop5">
                                                                  {
                                                                    it.itinerary_address
                                                                  }
                                                                </p>
                                                                </div>
                                                                <div className="description text-pre-wrap">
                                                                {
                                                                  it.itinerary_description
                                                                }
                                                              </div>
                                                                <h3 className="font14 extraSpacing itineraryDuration">
                                                              Duration: {it.pass_hours !=
                                                              0 && ( pass_without_multiple )},  {stopPlace}
                                                            </h3>
                                                             
                                                              {/* <div className="description text-pre-wrap">
                                                                {
                                                                  it.itinerary_description
                                                                }
                                                              </div> */}
                                                            </div>
                                                            {/* {it.pass_hours !=
                                                              0 && (
                                                              <>
                                                                <h3 className="greenText latoBold font14 extraSpacing">
                                                                  Duration:{" "}
                                                                  {
                                                                    pass_without_multiple
                                                                  }
                                                                </h3>
                                                              </>
                                                            )}

                                                            <h3 class="section-title latoBold font14 extraSpacing marginTopBottom5">
                                                              {stopPlace}
                                                            </h3> */}
                                                          </>
                                                        );
                                                      }
                                                    }
                                                  }
                                                }
                                              )}

                                            <h3 className="greenText latoBold font14 extraSpacing">
                                              Accommodation
                                            </h3>
                                            {this.state.tour_itinerary
                                              .multipledays_accomm &&
                                              this.state.tour_itinerary
                                                .multipledays_accomm
                                                .multiple_days_accommodation !=
                                                "no_multiple_days_accommodation" &&
                                              this.state.tour_itinerary.multipledays_accomm.multiple_days_accommodation[0].map(
                                                (acco, k) => {
                                                  if (
                                                    acco.day_number ==
                                                    i.day_number
                                                  ) {
                                                    return (
                                                      <>
                                                        <div className="sectionWrapper itineraryWrapper">
                                                          <div className="description text-pre-wrap">
                                                            {
                                                              acco.accommodation_description
                                                            }
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                }
                                              )}

                                            <h3 className="greenText latoBold font14 extraSpacing">
                                            Food & Drinks
                                            </h3>

                                            {this.state.tour_itinerary
                                              .multipledays_fooddrink &&
                                              this.state.tour_itinerary
                                                .multipledays_fooddrink
                                                .multiple_days_food_drinks !=
                                                "no_itinerary" &&
                                              this.state.tour_itinerary.multipledays_fooddrink.multiple_days_food_drinks[0].map(
                                                (food, k) => {
                                                  if (
                                                    food.day_number ==
                                                    i.day_number
                                                  ) {
                                                    if (
                                                      food.food_type_id == 1
                                                    ) {
                                                    }
                                                    let Result = "";
                                                    if (
                                                      food.food_type_id == 1
                                                    ) {
                                                      Result = "Breakfast";
                                                    } else if (
                                                      food.food_type_id == 2
                                                    ) {
                                                      Result = "Lunch";
                                                    } else if (
                                                      food.food_type_id == 3
                                                    ) {
                                                      Result = "Dinner";
                                                    }

                                                    return (
                                                      <>
                                                        <div class="whyShouldIDoItem">
                                                          <FontAwesomeIcon
                                                            icon={faCheckCircle}
                                                            className="mettingPickupCheck"
                                                          />
                                                          <div class="whyShouldIDoItemText">
                                                            {" "}
                                                            {Result}.{" "}
                                                            {food.food_description && (
                                                              <>
                                                                (
                                                                {
                                                                  food.food_description
                                                                }
                                                                )
                                                              </>
                                                            )}{" "}
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                }
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                            </div>
                      </div>
                      </>
                    )}
                  </Collapsible>
                </div>

                <div
                  className="container"
                  style={{ maxWidth: "1210px", marginBottom: 30 }}
                >
                  <Collapsible
                    trigger="Inclusions and Exclusions"
                    triggerClassName="txtHeading font22 latoBlack blackText textHeader"
                    triggerOpenedClassName="txtHeading font22 latoBlack blackText textHeader"
                    className="accordionCollapsed"
                  >
                    <section class="sectionWrapper">
                      <h3 class="section-title"></h3>
                      <div class="whyShouldIDoList">
                        {this.state.tour_inclusion != null && (
                          <>
                            {this.state.tour_inclusion.map((i, j) => {
                              if (i.category_name != null) {
                                return (
                                  <div class="whyShouldIDoItem">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="inclusionCheck beniftIcon"
                                    />
                                    <div class="whyShouldIDoItemText">
                                      {" "}
                                      {i.category_name}.{" "}
                                      {i.tour_inclusion_description && (
                                        <>({i.tour_inclusion_description})</>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </>
                        )}
                      </div>
                    </section>

                    <section class="sectionWrapper">
                      <h3 class="section-title"></h3>
                      <div class="whyShouldIDoList">
                        {this.state.tour_exclusion != null && (
                          <>
                            {this.state.tour_exclusion.map((i, j) => {
                              return (
                                <div class="whyShouldIDoItem">
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="exclusionCheck beniftIcon"
                                  />

                                  <div class="whyShouldIDoItemText">
                                    {" "}
                                    {i.category_name != null &&
                                      i.category_name.trim()}
                                    .{" "}
                                    {i.tour_exclusion_description && (
                                      <>({i.tour_exclusion_description})</>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </section>
                  </Collapsible>
                </div>
                <div
                  className="container"
                  style={{ maxWidth: "1210px", marginBottom: 30 }}
                >
                  <Collapsible
                    trigger="Meeting Points"
                    triggerClassName="txtHeading font22 latoBlack blackText textHeader"
                    triggerOpenedClassName="txtHeading font22 latoBlack blackText textHeader"
                    className="accordionCollapsed"
                  >
                    {this.state.tour_meetingpoint.meeting_point !=
                    "no_meetingPoint" ? (
                      <div className="">
                        <div className="meetingPint">
                          <h2 className="txtHeading font16 latoBlack blackText">
                          We pick up all travelers
                          </h2>
                          <h3 className="greenText latoBold font14 extraSpacing">
                            Pickup Point
                          </h3>
                          {this.state.tour_meetingpoint.meeting_point &&
                            this.state.tour_meetingpoint.meeting_point !=
                              "no_multiple_points" &&
                            this.state.tour_meetingpoint.meeting_point[0].map(
                              (i, j) => {
                                return (
                                  <>
                                    <div className="mettingPointListing">
                                      <FontAwesomeIcon
                                        icon={faMapMarkedAlt}
                                        className="mettingPickupCheck"
                                      />
                                      <p className="mettingPickupAddress">
                                        {i.start_point_address}
                                      </p>
                                    </div>

                                    <div className="sectionWrapper">
                                      <div className="description text-pre-wrap">
                                        {i.start_point_description != ""
                                          ? i.start_point_description
                                          : ""}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>
                        {/* <h3 className="font-weight-bold text-bodyNew">
                      Departure Time
                    </h3> */}
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {/* {this.state.tour_price.timing.map((i, j) => (
                          <p style={{ marginRight: 10 }}>{i.from_time.trim()}{i.to_time != null && "-" + i.to_time.trim()}</p>
                      ))} */}
                        </div>
                        <h3 className="greenText latoBold font14 extraSpacing">
                          Return Details
                        </h3>
                        {this.state.tour_meetingpoint.meeting_point &&
                          this.state.tour_meetingpoint.meeting_point !=
                            "no_multiple_points" &&
                          this.state.tour_meetingpoint.meeting_point[0].map(
                            (i, j) => {
                              return (
                                <>
                                  <div className="mettingPointListing">
                                    <FontAwesomeIcon
                                      icon={faMapMarkedAlt}
                                      className="mettingPickupCheck"/>
                                    <p className="mettingPickupAddress">
                                      {i.end_point_address != ""
                                        ? i.end_point_address
                                        : "Returns to original departure point"}
                                    </p>
                                  </div>

                                  <div className="sectionWrapper">
                                    <div className="description text-pre-wrap">
                                      {i.end_point_description != ""
                                        ? i.end_point_description
                                        : ""}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    ) : (
                      <div className="meetingPint">
                        <h2 className="txtHeading font16 latoBlack blackText">
                        We meet all travelers at a starting point
                          </h2>
                        <h3 className="greenText latoBold font14 extraSpacing">
                          Pickup Point
                        </h3>
                        {/* Traveler pickup is offered */}
                        <div style={{ marginTop: 20 }}>
                          {this.state.tour_meetingpoint.pickup_location !=
                            "no_pickupLocation" &&
                            this.state.tour_meetingpoint.pickup_location.map(
                              (i, j) => {
                                return (
                                  <>
                                    {i.map((res, index) => {
                                      let Result = "";
                                      if (res.pickup_from_id == 1) {
                                        Result = "Hotels";
                                      } else if (res.pickup_from_id == 2) {
                                        Result = "Ports";
                                      } else if (res.pickup_from_id == 3) {
                                        Result = "Airports";
                                      }

                                      return (
                                        <>
                                          {/* <h3 className="font-weight-bold text-bodyNew">
                                            {Result}
                                          </h3> */}
                                          <div className="mettingPointListing">
                                            <FontAwesomeIcon
                                              icon={res.pickup_from_id == 1 ?faHotel:res.pickup_from_id == 2 ? faShip :res.pickup_from_id == 3 ?faPlane: faMapMarkedAlt}
                                              className="mettingPickupCheck"/>
                                            <p className="mettingPickupAddress">
                                              {res.pickup_address}
                                            </p>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                            )}
                        </div>
                        
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {this.state.tour_price != null &&
                            this.state.tour_price.timing !=
                              "coordinated_times" &&
                            this.state.tour_price.timing.map((i, j) => (
                              <p style={{ marginRight: 10 }}>
                                {/* {i.from_time.trim()}
                            {i.to_time != null && "-" + i.to_time.trim()} */}
                              </p>
                            ))}
                        </div>
                        <p>
                          Pickup commence approximately{" "}
                          {this.state.tour_meetingpoint.meeting_point ==
                            "no_meetingPoint" &&
                            this.state.tour_meetingpoint.pickup_details ==
                              "no_pickup_details" && <>---</>}
                          {(this.state.tour_meetingpoint.meeting_point !=
                            "no_meetingPoint" &&
                            this.state.tour_meetingpoint
                              .pickup_location_details[0].pickup_window_time) ||
                            ""}{" "}
                          {this.state.tour_meetingpoint.pickup_details !=
                            "no_pickup_details" &&
                            this.state.tour_meetingpoint.pickup_details.map(
                              (i, j) => {
                                return (
                                  <>
                                    {i.map((res, index) => {
                                      return <>{res.pickup_window_time}</>;
                                    })}
                                  </>
                                );
                              }
                            )}
                          &nbsp; minutes prior to the departure time.
                        </p>
                        {this.state.tour_meetingpoint.pickup_details !=
                          "no_pickup_details" &&
                          this.state.tour_meetingpoint.pickup_details.map(
                            (i, j) => {
                              return (
                                <>
                                  {i.map((res, index) => {
                                    return (
                                      <>
                                        <h3 className="greenText latoBold font14 extraSpacing">
                                          Pickup details
                                        </h3>
                                        <p> {res.pickup_description}</p>
                                      </>
                                    );
                                  })}
                                </>
                              );
                            }
                          )}
                      </div>
                    )}
                  </Collapsible>
                </div>

                <div
                  className="container"
                  style={{ maxWidth: "1210px", marginBottom: 30 }}
                >
                  <Collapsible
                    trigger="Policies"
                    triggerClassName="txtHeading font22 latoBlack blackText textHeader"
                    triggerOpenedClassName="txtHeading font22 latoBlack blackText textHeader"
                    className="accordionCollapsed"
                  >
                    {this.state.tour_cancellation_policy != null &&
                      this.state.tour_cancellation_policy.cancellation_policy.map(
                        (item, index) => {
                          if (item[0].cancellation_penalty_id == 1) {
                            return (
                              <div>
                                <div className="latoRegular font16 appendBottom15">
                                  <b>
                                    {getDateModal(item)}
                                    ‬&#x202C;
                                  </b>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <div className="latoRegular font16 appendBottom15">
                                  <b>
                                    The cancellation charges will be applicable
                                    as follows
                                  </b>
                                </div>
                                <div className="cncpolicyTbl">
                                  <div className="cncpolicyTbl__head">
                                    <div className="cncpolicyTbl__col">
                                      Days Before Arrivals{" "}
                                    </div>
                                    <div className="cncpolicyTbl__col">
                                      Charges{" "}
                                    </div>
                                  </div>
                                  {CancellationModal(item)}
                                </div>
                              </>
                            );
                          }
                        }
                      )}

                    {this.state.tour_cancellation_policy != null &&
                      this.state.tour_cancellation_policy.cancellation_policy.map(
                        (item, index) => {
                          if (item[0].policy_text != null) {
                            return (
                              <>
                                {" "}
                                <section class="sectionWrapper">
                                  <h3 class="section-title">More Details</h3>
                                  <div
                                    class="description text-pre-wrap"
                                    itemprop="description"
                                  >
                                    {ReactHtmlParser(item[0].policy_text)}
                                  </div>
                                </section>
                              </>
                            );
                          }
                        }
                      )}
                  </Collapsible>
                </div>

                <div
                  className="container"
                  style={{ maxWidth: "1210px", marginBottom: 30 }}
                >
                  <Collapsible
                    trigger="Additional Info"
                    triggerClassName="txtHeading font22 latoBlack blackText textHeader"
                    triggerOpenedClassName="txtHeading font22 latoBlack blackText textHeader"
                    className="accordionCollapsed"
                  >
                    <section class="sectionWrapper" style={{ padding: 0 }}>
                      <div
                        class="description text-pre-wrap"
                        itemprop="description"
                      >
                        {ReactHtmlParser(
                          this.state.tour_description.tour_additional_info
                        )}
                      </div>
                    </section>
                  </Collapsible>
                </div>

                {/* <section
              id="Reviews"
              className="page-section"
              style={{ marginTop: 50 }}
            >
              <div className="spaceBetween appendBottom15">
                <h2 className="txtHeading font22 latoBlack blackText textHeader">
                  Verified Ratings &amp; Reviews for{" "}
                  {this.state.tour_data.tour_name}
                </h2>
              </div>
              <div className="TAReviewTop">
                <div className="TAReviewLeft">
                  <div className="ratingBox greenBgTA">
                    <div className="ratingBoxTop whiteText">
                      <span>4.5</span>
                      <span className="font12">2787 REVIEWS</span>
                    </div>
                    <div className="ratingBoxBottom">
                      <span className="sprite taratingIconNew"></span>
                      <span className="ratingNew">
                        <Ratings
                          rating={4.5}
                          widgetDimensions="14px"
                          widgetSpacings="0px"
                        >
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                        </Ratings>
                      </span>
                    </div>
                  </div>
                  <div className="flexOne">
                    <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                      <span className="latoBold font11 capText">EXCELLENT</span>
                      <span className="spacing"></span>
                      <span className="font10">2218 REVIEWS</span>
                    </p>
                    <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                      <span className="latoBold font11 capText">VERY GOOD</span>
                      <span className="spacing"></span>
                      <span className="font10">388 REVIEWS</span>
                    </p>
                    <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                      <span className="latoBold font11 capText">AVERAGE</span>
                      <span className="spacing"></span>
                      <span className="font10">98 REVIEWS</span>
                    </p>
                    <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                      <span className="latoBold font11 capText">POOR</span>
                      <span className="spacing"></span>
                      <span className="font10">55 REVIEWS</span>
                    </p>
                    <p className="hdmakeFlex hrtlCenter spaceBetween appendBottom10">
                      <span className="latoBold font11 capText">BAD</span>
                      <span className="spacing"></span>
                      <span className="font10">28 REVIEWS</span>
                    </p>
                  </div>
                </div>
                <div className="TAReviewRight">
                  <ul className="font12">
                    <li id="categoryItem_0">
                      <span>
                        <Ratings
                          rating={5}
                          widgetDimensions="14px"
                          widgetSpacings="0px"
                        >
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                        </Ratings>
                      </span>
                      <span className="ratingName">LOCATION</span>
                    </li>
                    <li id="categoryItem_1">
                      <span>
                        <Ratings
                          rating={4.5}
                          widgetDimensions="14px"
                          widgetSpacings="0px"
                        >
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                        </Ratings>
                      </span>
                      <span className="ratingName">SLEEP</span>
                    </li>

                    <li id="categoryItem_2">
                      <span>
                        <Ratings
                          rating={5}
                          widgetDimensions="14px"
                          widgetSpacings="0px"
                        >
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                        </Ratings>
                      </span>
                      <span className="ratingName">ROOM</span>
                    </li>
                    <li id="categoryItem_3">
                      <span>
                        <Ratings
                          rating={4}
                          widgetDimensions="14px"
                          widgetSpacings="0px"
                        >
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                        </Ratings>
                      </span>
                      <span className="ratingName">SERVICE</span>
                    </li>
                    <li id="categoryItem_4">
                      <span>
                        <Ratings
                          rating={5}
                          widgetDimensions="14px"
                          widgetSpacings="0px"
                        >
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                          <Ratings.Widget widgetRatedColor="#deba56" />
                        </Ratings>
                      </span>
                      <span className="ratingName">VALUE FOR MONEY</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="TAReviewRow" id="ta_reviewItem_0">
                <h4 className="latoBold font18 yellowText">
                  Idyllic Honeymoon
                  <span style={{ marginLeft: 10 }}>
                    <Ratings
                      rating={5}
                      widgetDimensions="14px"
                      widgetSpacings="0px"
                    >
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                    </Ratings>
                  </span>
                </h4>
                <p className="grayText appendBottom10">
                  Alexhotel6, Apr 03, 2021
                </p>
                <p className="font16 lineHight22">
                  We spent our Honeymoon week at the exceptional Lemuria Hotel.
                  First of all the check-in experience made by Eva (Guest
                  Relation Manager) and her team is the perfect definition of an
                  experience. We were speechless immediately after our first
                  minutes at the Resort. Everything was noted, repeated to make
                  sure that our schedule of the week was correct. They offered
                  us immediately seated a welcome drink and we were escorted to
                  our amazing Senior Suite with a lof of gifts waiting for us in
                  the room and the bed. It was the most incredible check-in
                  experience I had in my luxury travel life. Discovering the
                  resort with its fabulous restaurant locations, SPA, Golf
                  course is an evasion inside your trip. The hotel has 3
                  exceptional beaches and Lindsay the beach boy from Day 1 took
                  care of us for the entire week - thanks to him for his
                  attention and service. You need to meet Jerome - Head
                  Sommelier - and his great team and discover the wine tasting
                  experience in the huge private wine cellar. We had the chance
                  to be pampered by Eva and her team with daily special
                  attention and that is very unique. Special thanks as well to
                  Mita - waitress at the Nest Restaurant - for her service and
                  hospitality during our private sunset diner at On The Rocks
                  (Experience to book in advance as well... I would like to
                  thank you Mr Le Gac - General Manager - and his entire team
                  for this dream we will never forget. By far the most beautiful
                  hotel of the Seychelles. A place to visit with no doubt for
                  its luxury accomodation, perfect loction on the beach and for
                  its personallized service. The Lemuria is the perfect reflect
                  of LHW reputation. Thank you and congratulations for your
                  incredible work.
                </p>
              </div>

              <div className="TAReviewRow" id="ta_reviewItem_2">
                <h4 className="latoBold font18 yellowText">
                  The wine dinner was absolutely the highlight of our stay!
                  <span style={{ marginLeft: 10 }}>
                    <Ratings
                      rating={5}
                      widgetDimensions="14px"
                      widgetSpacings="0px"
                    >
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                    </Ratings>
                  </span>
                </h4>
                <p className="grayText appendBottom10">
                  dimamah, COUPLES Apr 01, 2021
                </p>

                <p className="font16 lineHight22">
                  You can read a lot about the hotel in other reviews, I'll
                  focus specifically on the wine dinner in the constance wine
                  cellar. The dinner is absolutely a must! You get a 5 course
                  meal, the food quality is simply above and beyond, it was
                  easily the best meal in seychelles, and could probably be
                  served in any top quality restaurant in France. But that was
                  just the beginning , the real start of the night was our
                  sommelier Jerome, he is very, very, professional and did an
                  amazing job pairing top notch wines to every dish. We drank 5
                  extremely generous glasses of the finest french wines. Even
                  thought the dinner isn't cheap, when you look at the value for
                  money, it's really worth it. Now back to Jerome, this guy
                  really made the dinner, he is sooo fun, just hearing him
                  explain about the food and wine makes it much tastier.Do not
                  miss this!
                </p>
              </div>

              <div className="TAReviewRow" id="ta_reviewItem_3">
                <h4 className="latoBold font18 yellowText">
                  Perfect wedding destination
                  <span style={{ marginLeft: 10 }}>
                    <Ratings
                      rating={5}
                      widgetDimensions="14px"
                      widgetSpacings="0px"
                    >
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                    </Ratings>
                  </span>
                </h4>
                <p className="grayText appendBottom10">
                  Broussova, Mar 30, 2021
                </p>
                <p className="font16 lineHight22">
                  It is a beautiful hotel set on stunning grounds with world
                  className golf course. We were lucky that Kheshma has answered
                  our query regarding wedding venues at the hotel and thanks to
                  her she made our choice very easy. She helped us organise our
                  perfect wedding day at Constance Lemuria. The whole team made
                  our stay and wedding day so special, memorable and perfect in
                  every single way, we couldn't be more thankful. We had private
                  candlelit dinners on the beach, breakfast on the rocks, sunset
                  drinks and rehearsal evening on the edge of the mountain, all
                  organised by the team as special treats. The team was
                  thoughtful, careing and very attentive. We will definitely
                  come back to visit again.Oh and do enquire about helicopter
                  transfers to/from the resort!
                </p>
              </div>

              <div className="TAReviewRow" id="ta_reviewItem_4">
                <h4 className="latoBold font18 yellowText">
                  Chefs' Teasing and Tantalizing
                  <span style={{ marginLeft: 10 }}>
                    <Ratings
                      rating={5}
                      widgetDimensions="14px"
                      widgetSpacings="0px"
                    >
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                      <Ratings.Widget widgetRatedColor="#deba56" />
                    </Ratings>
                  </span>
                </h4>
                <p className="grayText appendBottom10">
                  sofarsogood94, Mar 27, 2021
                </p>
                <p className="font16 lineHight22">
                  We have enjoyed many a fine dinner over the years at Lemuria's
                  Diva Restaurant, but we had not visited for quite some time
                  due to Covid restrictions. With the Seychelles reopening to
                  the world, we jumped at the first chance we got, although I do
                  admit that I had some hesitation - would the standards still
                  be what they used to be or would the team have grown rusty? We
                  were pleasantly surprised. There was a lovely ambience in the
                  restaurant with a good number of other clients, mainly repeat
                  guests, who had also flocked back at the first opportunity
                  they got. Service was great - Emma took great care of us as
                  usual. We felt safe as excellent protocols seemed to be in
                  place. What blew us away though, and prompted this review and
                  the heading, was the food. Now, I expect excellent food in a
                  top establishment that charges accordingly, but I did not
                  expect just how much fun the kitchen team seemed to be having
                  in creating and presenting dishes that were there to tease and
                  tantalise. So much energy and creativity was put into the
                  dishes we ordered. I am a little reluctant to mention the
                  treats they served, as I fear it may detract from the surprise
                  of those who follow, but three items stood out: an
                  amuse-bouche of caviar ice-cream served in a mini-cone; the
                  presentation of the scallops dish we ordered, and the sorbet
                  with popping candy. Each one a delight to the eye and mouth.
                  The truffle risotto my wife ordered was one of the best she
                  has ever had. This was all "washed-down" with a bottle of fine
                  champagne - an Egly-Ouriet - which is the first time I have
                  tried one of these. A lovely evening, made all the more
                  special for us because we have not been able to enjoy outings
                  like this as often as we would wish to do in these strange
                  times, but also because of the appreciation we felt for the
                  huge amount of effort and shear zest the team put into the
                  dinner, not just for us, but for all the tables around us too.
                  There was no sight of any rusty team members nor of disrupted
                  supply chains. Hats off Lemuria. We will be back.
                </p>
              </div>
            </section> */}
              </>
            )}
          </>
        )}

<TawkToWidget />
      </div>
    );
  }
}


export default ActivityDetails;
