import React, { useState, useEffect } from "react";
import "./../TourOfferListing.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarWeek,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

//import { BrowserRouter, Link, Switch, Route } from "react-router-dom";
import { Base64 } from "js-base64";
//import { decode } from "html-entities";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactHtmlParser from "react-html-parser";
import ReactTooltip from "react-tooltip";
import Skeleton from "react-loading-skeleton";

function TransfersOfferListingComponent(props) {
  const [ImageChanger, setImageChanger] = useState();
  const [TourData, setTourData] = useState(props.tour_data);
  const [gallery, setGallery] = useState(props.tour_gallery);
  const [tourDescription, setTourDescription] = useState(
    props.tour_description
  );
  const [Price, setPrice] = useState(props.tour_price);
  const [dateSelected, setDateSelected] = useState(props.dateSelected);
  const [day, setDay] = useState(props.tour_day);
  const [tourtime, setTourtime] = useState(props.tour_time);
  const [exclusion, setExclusion] = useState(props.tour_exclusion);
  const [specialOffer, setSpecialOffer] = useState(props.specialOffer);
  // const [daytime, setDaytime] = useState({});
  // const [newdaytime, setNewDaytime] = useState([{}]);
  const [forMeal, setForMeal] = useState(props.forMeal);
  const [tourpricedetails, setTourpricedetails] = useState(
    props.tour_price_details
  );
  const [tourpriceschedule, setTourpriceschedule] = useState(
    props.tour_price_schedule
  );
  const [tourpriceschedulenew, settourpriceschedulenew] = useState(
    props.tour_price_schedule_new
  );

  const onMouseOutFun = (image) => {
    setImageChanger(image);
  };
  const onMouseOverFun = (image) => {
    setImageChanger(image);
  };

  const getAmount = (data) => {
    const data12 = Object.entries(forMeal);
    const rateId = data12.filter(([key, value]) => value == Price);

    const Offer1 = data.filter(
      (item, key) =>
        item != "no_offer" && item.tourSpecial.rate_plan_id == rateId[0][0]
    );

    if (Offer1.length > 0) {
      let result = [];

      Offer1.map((i, j) => {
        if (i.combineoffer == "nocombine") {
          if (i.specialdata.discount_id == 2) {
            const disA = i.specialdata.rate_amount;
            result.push(+disA);
          } else {
            const disA = Price - i.specialdata.rate_amount;
            result.push(+disA);
          }
        } else {
          if (i.specialdata.discount_id == 2) {
            const disA = i.specialdata.rate_amount;
            result.push(+disA);
          } else {
            const disA = Price - i.specialdata.rate_amount;
            result.push(+disA);
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount =
        Price - (Math.round(Amount * Price) / 100).toFixed(2);
      return (
        <>
          <p>
            <span className="font-weight-bold text-lowercase from-price-text">
              From{" "}
            </span>
          </p>
          <div className="containerListing">
            <p
              className="grayText font16 lineThrough appendBottom5"
              id="hlistpg_hotel_cut_price"
            >
              <span>€</span>
              {Price.toFixed(2)}
            </p>

            <p
              className="latoBlack font16 blackText appendBottom5 appendLeft5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {balanceAmount.toFixed(2)}
            </p>
          </div>
          <p class="hrtlCenter appendBottom10 directionRtl">
            <span class="redText font12">
              <span>Save </span>
              <span class="latoBold" id="hlistpg_hotel_saved_price">
                <span>€</span>
                {(Math.round(Amount * Price) / 100).toFixed(2)}
              </span>
            </span>
            <span class="spriteNew discountIcon relative appendLeft5">
              <span
                class="latoBold font11 whiteText discountText"
                id="hlistpg_hotel_saved_price_percentage"
                style={{
                  lineHeight: "15px",
                  paddingRight: "5px",
                }}
              >
                {parseInt(Amount)}%
              </span>
            </span>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            <span className="font-weight-bold text-lowercase from-price-text">
              From{" "}
            </span>
          </p>
          <p
            className="latoBlack font16 blackText appendBottom5"
            id="hlistpg_hotel_shown_price"
          >
            <span>€</span>
            {Price}
          </p>
        </>
      );
    }
  };

  return (
    <>
      <div className="Container-Tile">
        <a
          href={
            "/transferDetails?tourid=" +
            Base64.btoa(TourData.id) +
            "&name=" +
            encodeURIComponent(TourData.tour_name) +
            "&location=" +
            encodeURIComponent(TourData.tour_location) +
            "&dateselected=" +
            encodeURIComponent(dateSelected)
          }
          target="_blank"
        >
          <>
            <div className="Image-Section">
              {gallery != null ? (
                gallery
                  .slice(0, 1)
                  .map((item, key) => (
                    <LazyLoadImage
                      src={ImageChanger == null ? item : ImageChanger}
                      className="gallery__img"
                      placeholderSrc={
                        process.env.PUBLIC_URL + "images/placeholder.png"
                      }
                      key={key}
                    />
                  ))
              ) : (
                <LazyLoadImage src={"images/placeholder.png"} />
              )}
              {/* <div className="SmallImage">
                {gallery != null ?

                  (gallery.slice(0, 4).map((item, key) => (

                    <LazyLoadImage className="imgThumb" src={item != null ? item : "images/placeholder.png"}
                      onMouseOver={e => onMouseOverFun(e.currentTarget.src)}
                      onMouseOut={e => onMouseOutFun(e.currentTarget.src)} key={key} />

                  ))) : (<></>)

                }
              </div> */}
            </div>
            <div className="Text-Section">
              <div className="Text-Contents">
                <div className="Text-Ratings">
                  <p
                    className="latoBlack font16 blackText appendBottom12"
                    style={{ marginRight: 10 }}
                  >
                    <span>{TourData.tour_name.substring(0, 80)}</span>
                  </p>
                </div>

                <div className="Small-Text">
                  <p className="address" style={{ color: "gray" }}>
                    {TourData.tour_region_name != null ? (
                      <>
                        {TourData.tour_location}, {TourData.tour_region_name}
                      </>
                    ) : (
                      <>{TourData.tour_location}</>
                    )}
                  </p>
                  {tourDescription?.tour_description != null ? (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#555",
                        marginTop: 8,
                        textAlign: "justify",
                        marginRight: 15,
                      }}
                    >
                      {tourDescription?.tour_description.substring(0, 100)}{" "}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#555",
                        marginTop: 8,
                        textAlign: "justify",
                        marginRight: 15,
                      }}
                    >
                      {ReactHtmlParser(
                        `${tourDescription?.tour_overview.substring(0, 100)}...`
                      )}
                    </p>
                  )}
                </div>
                <div className="DayTime">
                  {Object.values(tourpriceschedulenew.schedule).length > 1 ? (
                    <p
                      className="dayText"
                      data-tip
                      data-for={TourData.id}
                      style={{ marginTop: 20 }}
                    >
                      View all schedules{" "}
                    </p>
                  ) : (
                    <>
                      {" "}
                      <p className="dayText" style={{ marginTop: 10 }}>
                        {" "}
                        <FontAwesomeIcon
                          icon={faCalendarWeek}
                          style={{
                            fontSize: "15px",
                            color: "#4f9fcd",
                            marginRight: "10px",
                          }}
                        />{" "}
                        {day}{" "}
                      </p>
                      <p className="dayTime">
                        <FontAwesomeIcon
                          icon={faClock}
                          style={{
                            fontSize: "15px",
                            color: "#4f9fcd",
                            marginRight: "10px",
                          }}
                        />
                        {tourtime != null &&
                          tourtime
                            .map((tourtimevalue, index) => (
                              <>
                                {tourtimevalue.to_time != null
                                  ? tourtimevalue.from_time +
                                    "-" +
                                    tourtimevalue.to_time
                                  : tourtimevalue.from_time}
                              </>
                            ))
                            .reduce((prev, curr) => [prev, ", ", curr])}
                      </p>
                    </>
                  )}

                  <div className="customTooltip">
                    <ReactTooltip
                      place="top"
                      type="light"
                      id={TourData.id}
                      style={{
                        width: "200px",
                        height: "100%",
                        color: " #fff",
                        background: "#0074B7",
                      }}
                    >
                      {Object.entries(tourpriceschedulenew.schedule) == null ? (
                        <div className="tour-grade-loading-wrapper container">
                          <div className="loading-wrapper mb-3">
                            <div className="loading-message px-lg-3 py-3">
                              Loading available options...
                            </div>
                            <Skeleton
                              style={{
                                width: "95%",
                                height: 20,
                                marginBottom: 10,
                                marginTop: 10,
                              }}
                            />
                            <Skeleton
                              style={{
                                width: "90%",
                                height: 20,
                                marginBottom: 10,
                              }}
                            />
                            <Skeleton
                              style={{
                                width: "80%",
                                height: 20,
                                marginBottom: 10,
                              }}
                            />
                            <Skeleton
                              style={{
                                width: "70%",
                                height: 20,
                                marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          {Object.values(tourpriceschedulenew.schedule).map(
                            (i, j) => {
                              return (
                                <>
                                  <div className="reactpoopup">
                                    <div className="px-0">
                                      <label
                                        for="rb0"
                                        className="mb-0 w-100 enabled"
                                      >
                                        <div
                                          className="tour-grade-title h3 mb-0 font-weight-medium tg-title"
                                          style={{ fontSize: "20px" }}
                                        >
                                          {i.option_name}
                                        </div>
                                      </label>
                                    </div>
                                    {Object.values(
                                      tourpriceschedulenew.schedule_day
                                    ).map((day, k) => {
                                      if (k == j) {
                                        return (
                                          <>
                                            <p
                                              className="dayText"
                                              style={{ marginTop: 5 }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faCalendarWeek}
                                                style={{
                                                  fontSize: "15px",
                                                  color: "#4f9fcd",
                                                  marginRight: "10px",
                                                }}
                                              />{" "}
                                              {day == "undefined" ? (
                                                <>Cordinate Date</>
                                              ) : (
                                                <>{day}</>
                                              )}{" "}
                                            </p>
                                          </>
                                        );
                                      }
                                    })}
                                    {Object.values(
                                      tourpriceschedulenew.schedule_time
                                    ).map((time, m) => {
                                      if (m == j) {
                                        return (
                                          <>
                                            <p
                                              className="dayText"
                                              style={{ marginTop: 5 }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faClock}
                                                style={{
                                                  fontSize: "15px",
                                                  color: "#4f9fcd",
                                                  marginRight: "10px",
                                                }}
                                              />
                                              {time == "undefined" ? (
                                                <>Cordinate Time</>
                                              ) : (
                                                <>
                                                  {time != "undefined" &&
                                                    time
                                                      .map(
                                                        (
                                                          tourtimevalue,
                                                          index
                                                        ) => (
                                                          <>
                                                            {tourtimevalue.to_time !=
                                                            null
                                                              ? tourtimevalue.from_time +
                                                                "-" +
                                                                tourtimevalue.to_time
                                                              : tourtimevalue.from_time}
                                                          </>
                                                        )
                                                      )
                                                      .reduce((prev, curr) => [
                                                        prev,
                                                        ", ",
                                                        curr,
                                                      ])}
                                                </>
                                              )}
                                            </p>
                                          </>
                                        );
                                      }
                                    })}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </>
                      )}
                    </ReactTooltip>
                  </div>
                </div>
              </div>

              <div className="priceDetails textRight">
                <div className="priceDetailsTop">
                  <p className="font12 latoBold appendBottom3 darkText">
                    included in this price
                  </p>
                  <ul className="includes">
                    <li className="greenText">
                      <span className="includeIcon">
                        <span className="spriteNew singleGreenTickIcon"></span>
                      </span>
                      {exclusion == null ? (
                        <span className="includes__text"></span>
                      ) : (
                        <span className="includes__text">
                          {exclusion != null &&
                            exclusion
                              .slice(0, 3)
                              .map((tour_exclusion, index) => (
                                <>{tour_exclusion.category_name}</>
                              ))
                              .reduce((prev, curr) => [prev, ", ", curr])}
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    className="padding20 column"
                    style={{ marginTop: "0px" }}
                  >
                    {getAmount(specialOffer)}

                    <p
                      className="blackText appendBottom5"
                      style={{ fontSize: 10 }}
                    >
                      {tourpricedetails != null &&
                        tourpricedetails
                          .slice(0, 1)
                          .map((tour_price_category, index) => (
                            <>
                              {tour_price_category.price_type_id == 1 ? (
                                <>Per Person</>
                              ) : (
                                <>
                                  Per Group (up to{" "}
                                  {tour_price_category.group_max})
                                </>
                              )}{" "}
                              {tour_price_category.flexible_id == 1 ? (
                                <>or Flexible rates</>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </a>
      </div>
      {specialOffer == "no_offer" ? (
        <></>
      ) : (
        <div class="title__placeHolder font12 pc__bottomBox">
          <div class="persuasion ">
            <div class="persuasion__item pc__cashbackDeal">
              <span class="sprite infoIconGreen"></span>
              <span>
                {specialOffer != "no_offer" &&
                  specialOffer.map((tour_specialOffer, index) => (
                    <>{tour_specialOffer.name}</>
                  ))}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TransfersOfferListingComponent;
