import React from "react";
import { Base64 } from "js-base64";
import moment from "moment";
import ALL_API_CONST from "./UrlAPI/index.jsx";
import { headerData } from "./UrlAPI/headerAuth";

export async function zonelistAccoData() {
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.zonelistAccoData,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function ActivityZoneData() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.ActivityZoneData,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function PackageZoneData() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.PackageZoneData,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TransferZoneData() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferZoneData,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TransferSourceZoneData() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferSourceZoneData,

      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TransferDestinationZoneData() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferDestinationZoneData,

      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TransferAirportType() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferAirportType,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TransferFerryType() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferFerryType,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TransferCityType() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferCityType,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

//Zone List Airport to City

export async function TransferToAirportCityType(airporttocity) {
  let input = {
    airporttocity: airporttocity,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferToAirportCityType,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

//Zone List City To Airport
export async function TransferCityToAirportType(citytoairport) {
  let input = {
    citytoairport: citytoairport,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferCityToAirportType,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

//Zone List City To City
export async function TransferCityToCityType(citytocity) {
  let input = {
    citytocity: citytocity,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferCityToCityType,

      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function LiveAboardZoneData() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.LiveAboardZoneData,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getTourActivityList(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourActivityList,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTourPackageList(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourPackagelist,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTourTransferList(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourTransferlist,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTourLiveAboardList(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourLiveAboardList,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export function renderMovieTitle(state, val) {
  return state.name.toLowerCase().indexOf(val.toLowerCase()) !== -1;
}

export async function HotelListData(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.HotelListData,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 0) {
      return result.data;
    } else {
      return "nohotel";
    }
  } catch (error) {
    throw error;
  }
}

export async function getHSHotellist(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.getHSHotellist,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 0) {
      return result.data;
    } else {
      return "nohotel";
    }
  } catch (error) {
    throw error;
  }
}

export async function HotelListDataById(data, query) {
  let input = {
    property_id: Base64.atob(data),
    querySearch: query,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //console.log(formBody);
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.HotelListDataById,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();
    if (result.data.length > 0) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function HotelBookingRules(data, query, ratePlanCode, cart) {
  let input = {
    property_id: Base64.atob(data),
    querySearch: query,
    ratePlanCode: ratePlanCode,
    cart: cart,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.HotelBookingRules,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 0) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function TourRules(
  tourid,
  numberTravelerNew,
  ratePlanId,
  number,
  dateSelected,
  tour_operator_id = null
) {
  let input = {
    tour_id: Base64.atob(tourid),
    querySearch: numberTravelerNew,
    ratePlanCode: Base64.atob(ratePlanId),
    number: Base64.atob(number),
    dateSelected: dateSelected,
    tour_operator_id: tour_operator_id,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TourRules,

      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function HotelConfirmBooking(
  data,
  guest,
  GuestCont,
  EmailId,
  MobileNumber,
  MobileCode,
  StartDate,
  Enddate,
  customer_id,
  TransactionUUID
) {
  let input = {
    data: data,
    GuestDetails: guest,
    GuestCombination: GuestCont,
    GuestEmail: EmailId,
    GuestMobileNumber: MobileNumber,
    GuestCountryCode: MobileCode,
    startDate: StartDate,
    endDate: Enddate,
    customerId: customer_id,
    transaction_uuid: TransactionUUID,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.HotelConfirmBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function setCustomerLogin(
  first_name,
  last_name,
  email,
  profile_image,
  birthday,
  gender,
  login_source,
  mobile_number,
  country,
  country_code,
  currency,
  ip_address,
  password,
  verified
) {
  let input = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    profile_image: profile_image,
    birthday: birthday,
    gender: gender,
    login_source: login_source,
    mobile_number: mobile_number,
    country: country,
    country_code: country_code,
    currency: currency,
    ip_address: ip_address,
    password: password,
    verified: verified,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setCustomerLogin,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function setCustomerAgentLogin(
  first_name,
  last_name,
  email,
  profile_image,
  birthday,
  gender,
  login_source,
  mobile_number,
  country,
  country_code,
  currency,
  ip_address,
  password,
  verified,
  agent_name = null,
  corporate_name = null,
  tax_registration = null,
  address = null,
  city = null,
  island = null,
  agent_country = null,
  zip_code = null,
  agent_image = null
) {
  let input = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    profile_image: profile_image,
    birthday: birthday,
    gender: gender,
    login_source: login_source,
    mobile_number: mobile_number,
    country: country,
    country_code: country_code,
    currency: currency,
    ip_address: ip_address,
    password: password,
    verified: verified,
    agent_name: agent_name,
    corporate_name: corporate_name,
    tax_registration: tax_registration,
    address: address,
    city: city,
    island: island,
    agent_country: agent_country,
    zip_code: zip_code,
    agent_image: agent_image,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setCustomerAgentLogin,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function setAgentSignup(
  first_name,
  last_name,
  email,
  mobile_number,
  country,
  country_code,
  currency,
  ip_address,
  password
) {
  let input = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    mobile_number: mobile_number,
    country: country,
    country_code: country_code,
    currency: currency,
    ip_address: ip_address,
    password: password,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setAgentSignup,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function setAgentUpdatePassword(email, password) {
  let input = {
    email: email,
    password: password,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setAgentUpdatePassword,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function setLoginConfirmationAgent(email) {
  let input = {
    email: email,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setLoginConfirmationAgent,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function setCustomerAgentLoginProfile(agent_image = null, email) {
  let input = {
    agent_image: agent_image,
    email: email,
  };

  // making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setCustomerAgentLoginProfile,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function registrationagentemailcheck(email) {
  let input = {
    email: email,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.registrationagentemailcheck,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function getCustomerLogin(email) {
  let input = {
    email: email,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.getCustomerLogin,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getGeoInfo() {
  try {
    let question = await fetch("https://ipapi.co/json/", {
      method: "GET", //Request Type
    });
    let result = await question.json();

    return result;
  } catch (error) {
    throw error;
  }
}

export async function getUpcomingBooking(customer_id, booking_status) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getUpcomingBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noUpcoming";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getCancelledBooking(customer_id, booking_status) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getCancelledBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noCancelled";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getCompletedBooking(customer_id, booking_status) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getCompletedBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noCompleted";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function CancelBookingConfirm(Locator, PercentagePrice) {
  let input = {
    locator: Locator,
    cancellation_percentage: PercentagePrice,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.CancelBookingConfirm,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

//HS Cancellation

export async function CancelBookingConfirmHs(hsbooking_id) {
  let input = {
    hsbooking_id: hsbooking_id,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.CancelBookingConfirmHs,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function CancelBookingConfirmHl(hsbooking_id) {
  let input = {
    bookingid: hsbooking_id,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.CancelBookingConfirmHl,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function LoginConfirmation(email, password) {
  let input = {
    email: email,
    password: password,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.LoginConfirmation,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function LoginAgentConfirmation(email, password) {
  let input = {
    email: email,
    password: password,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.LoginAgentConfirmation,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function ConfirmationForgot(email, password) {
  let input = {
    email: email,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.ConfirmationForgot,

      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function ActivityDetailsData(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.ActivityDetailsData,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function CheckAvailabilityData(
  numberTraveler,
  checkDate,
  tour_id,
  price_type,
  transfertype
) {
  let input = {
    numberTraveler: numberTraveler,
    checkDate: checkDate,
    tour_id: tour_id,
    price_type: price_type,
    transfertype: transfertype,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //console.log(formBody);
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.CheckAvailabilityData,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );

    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function HomePageEventData() {
  try {
    let question = await fetch(
      "https://api.parrapi.com/api/atlas/products?key=853990929f594a01b5632e55514c96b4&cats=ATTRACTION,EVENT",
      {
        method: "GET", //Request Type
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHome() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHome,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeListing() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeListing,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

//By Ranjeet
export async function getSpecialOffersForFilterHome() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForFilterHome,
      {
        method: "POST", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOfferEjuniferListing() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOfferEjuniferListing,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesFilters() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesFilters,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesAttractionFilters() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesAttractionFilters,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesPackageFilters() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesPackageFilters,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesTransferFilters() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesTransferFilters,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesLiveAboardFilters() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesLiveAboardFilters,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function PackageDetailsData(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.PackageDetailsData,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function TransferDetailsData(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.TransferDetailsData,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function LiveAboardDetailsData(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.LiveAboardDetailsData,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiences() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiences,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOfferEjunifer() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOfferEjunifer,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function getSpecialOffersForHomeExperiencesAttraction() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesAttraction,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesPackage() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesPackage,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesTransfer() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesTransfer,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getSpecialOffersForHomeExperiencesLiveAboard() {
  try {
    let question = await fetch(
      ALL_API_CONST.offer_data.getSpecialOffersForHomeExperiencesLiveAboard,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getHotellistFilters(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.getHotellistFilters,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getHSHotellistFilters(data) {
  try {
    let question = await fetch("tour/getHSHotellistFilters", {
      method: "POST", //Request Type
      body: data,
      headers: {
        //Header Defination
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: "Basic YWRtaW46MTIzNA==",
        "x-api-key": "neeraj@123",
      },
    });
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTourFilter(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourFilter,

      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTourFilterTransfer(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourFilterTransfer,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTourFilterActivity(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourFilterActivity,

      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function getTourFilterPackage(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getTourFilterPackage,
      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function TourConfirmBooking(
  data,
  guest,
  GuestCont,
  EmailId,
  MobileNumber,
  MobileCode,
  StartDate,
  TourD,
  customer_id,
  TotalTourPrice,
  TransactionUUID,
  specialrequrement = null,
  returnrequrement = null,
  pickupLocation = null, 
  dropLocation = null
) {
  let input = {
    data: data,
    GuestDetails: guest,
    GuestCombination: GuestCont,
    GuestEmail: EmailId,
    GuestMobileNumber: MobileNumber,
    GuestCountryCode: MobileCode,
    startDate: StartDate,
    tourDetails: TourD,
    customerId: customer_id,
    totalPrice: TotalTourPrice,
    transaction_uuid: TransactionUUID,
    specialrequrement: specialrequrement,
    returnrequrement: returnrequrement,
    pickup:pickupLocation, 
    drop:dropLocation
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.TourConfirmBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getAgentCommissionDetails(customer_id) {
  let input = {
    customer_id: customer_id,
  };
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getAgentCommissionDetails,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getAgentAdditionChargesData(tour_operator_id) {
  let input = {
    tour_operator_id: tour_operator_id,
  };
  const formBody = JSON.stringify(input);
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getAgentAdditionChargesData,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function CartConfirmBooking(
  data,
  Guest,
  GuestCont,
  EmailId,
  MobileNumber,
  MobileCode,
  customer_id,
  TotalPrice,
  CommissionAmount,
  TransactionUUID,
  StartDate,
  Enddate
) {
  let input = {
    data: data,
    guest: Guest,
    GuestCombination: GuestCont,
    GuestEmail: EmailId,
    GuestMobileNumber: MobileNumber,
    GuestCountryCode: MobileCode,
    customerId: customer_id,
    totalPrice: TotalPrice,
    commionamount: CommissionAmount,
    transaction_uuid: TransactionUUID,
    startDate: StartDate,
    endDate: Enddate,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.CartConfirmBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}


export async function getEventandActivity() {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getEventandActivity,
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );
    let result = await question.json();

    if (result.data.length > 1) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

//CyberSource Data

export async function CybersourecPaymentGetway(
  bill_to_forename,
  bill_to_surname,
  bill_to_email,
  access_key,
  profile_id,
  transaction_uuid,
  locale,
  transaction_type,
  reference_number,
  amount,
  currency
) {
  let input = {
    bill_to_forename: bill_to_forename,
    bill_to_surname: bill_to_surname,
    bill_to_email: bill_to_email,
    access_key: access_key,
    profile_id: profile_id,
    signed_field_names:
      "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_to_forename,bill_to_surname,bill_to_email",
    unsigned_field_names: "",
    transaction_uuid: transaction_uuid,
    locale: locale,
    transaction_type: transaction_type,
    reference_number: reference_number,
    amount: amount,
    currency: currency,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  var url = "https://api.holidays-seychelles.com/api/Cybersource";
  var method = "POST";
  var postData = "Some data";
  var shouldBeAsync = true;

  var request = new XMLHttpRequest();
  request.onload = function () {
    var status = request.status; // HTTP response status, e.g., 200 for "200 OK"
    var data = request.responseText; // Returned data, e.g., an HTML document.
  };

  request.open(method, url, shouldBeAsync);

  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  request.setRequestHeader("Access-Control-Allow-Origin", "*");
  request.setRequestHeader("Access-Control-Allow-Headers", "X-Requested-With");
  request.send(formBody);
  //
  // try {
  //     let question = await fetch(
  //         "Cybersource",
  //         {
  //             method: "POST", //Request Type
  //             body: formBody,
  //             headers: {
  //                 //Header Defination
  //                 "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  //                 Authorization: "Basic YWRtaW46MTIzNA==",
  //                 "x-api-key": "neeraj@123",
  //             },
  //         }
  //     );
  //     let result = await question.json();

  //     return result;

  // } catch (error) {
  //     throw error;
  // }
}

export async function getExprerianceUpcomingBooking(
  customer_id,
  booking_status
) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getExprerianceUpcomingBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noUpcoming";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getExprerianceCancelledBooking(
  customer_id,
  booking_status
) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getExprerianceCancelledBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noCancelled";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getExprerianceCompletedBooking(
  customer_id,
  booking_status
) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getExprerianceCompletedBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noCompleted";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

//HS Cancellation

export async function CancelBookingConfirmHsTour(hstourbooking_id) {
  let input = {
    hstourbooking_id: hstourbooking_id,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.CancelBookingConfirmHsTour,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function UpdatePasswordReset(email, password) {
  let input = {
    email: email,
    password: password,
  };
  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.UpdatePasswordReset,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getExprerianceAgentBooking(customer_id, booking_status) {
  let input = {
    customer_id: customer_id,
    booking_status: booking_status,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getExprerianceAgentBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noAgent";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function ConfirmationAgentForgot(email) {
  let input = {
    email: email,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  //
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.ConfirmationAgentForgot,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

//Accomadation Booking ID Ejunfer Data

export async function getAccomadationBookingIdEJuniferData(
  property_id,
  data,
  ratePlanCode
) {
  let input = {
    property_id: property_id,
    querySearch: data,
    ratePlanCode: ratePlanCode,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.hotel_data.getAccomadationBookingIdEJuniferData,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    console.log(question);
    let result = await question.json();
    console.log(result);
    if (result.data.length > 0) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function setCustomerAgentLoginProfileNew(formdata) {
  try {
    let question = await fetch(
      ALL_API_CONST.login_data.setCustomerAgentLoginProfileNew,
      {
        method: "POST", //Request Type
        body: formdata,
        headers: {
          //Header Defination
          "Content-Type": "multipart/form-data",
          Authorization: "Basic YWRtaW46MTIzNA==",
          "x-api-key": "neeraj@123",
        },
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

//Get Booking Confirmation Show

export async function getAccomodationShowDataBooking(transaction_uuid) {
  let input = {
    transaction_uuid: transaction_uuid,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getAccomodationShowDataBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noUpcoming";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getExprerianceShowDataBooking(transaction_uuid) {
  let input = {
    transaction_uuid: transaction_uuid,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getExprerianceShowDataBooking,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noUpcoming";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getExprerianceAgentBookingShowingPagination() {
  try {
    let question = await fetch(
      "tour/getExprerianceAgentBookingShowingPagination?customer_id=63&page=0",
      {
        method: "GET", //Request Type
        headers: headerData,
      }
    );

    let result = await question.json();

    if (Array.isArray(result.data) && !result.data.length) {
      return "noAgent";
    } else {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function checkAvailabilityCheckoutData(data) {
  let input = {
    data: data,
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.checkAvailabilityCheckoutData,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getalltransport(data) {
  try {
    let question = await fetch(
      ALL_API_CONST.tour_data.getalltransport,

      {
        method: "POST", //Request Type
        body: data,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getwishlistCartData(
  customer_id
) {
  let input = {
    customer_id: customer_id
  };

  //making data to send on server
  const formBody = JSON.stringify(input);
  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.getwishlistCartData,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function WishlistDelete(
  wishlist_id
) {
  let input = {
    wishlist_id: wishlist_id
  };

  //making data to send on server
  const formBody = JSON.stringify(input);

  try {
    let question = await fetch(
      ALL_API_CONST.booking_data.WishlistDelete,
      {
        method: "POST", //Request Type
        body: formBody,
        headers: headerData,
      }
    );
    let result = await question.json();

    return result.data;
  } catch (error) {
    throw error;
  }
}
export const CyberSource  = ALL_API_CONST.cybersource;
export const Tripurl  = ALL_API_CONST.tripurl;
export const SupplierUrl  = ALL_API_CONST.supplier;
